// App.js
import React, {Component} from 'react';
import {Link } from "react-router-dom";
import logo from './title1.png';

import { Icon } from '@iconify/react';
import './css/guide.css';
import {Basics, DeployContract, MintToken, MintNFT, ExchangeCoin, Staking, RewardNFT, Escrow, Voting, Ownership} from './guidesmartcontract.js';
import {Main, Payment, BlockchainOverview, SmartContractOverview, DAppOverview, MintingOverview, ExchangeOverview, StakingOverview, LotteryOverview, AirdropOverview, VotingOverview} from './guidebasics.js';
import {DappProcess, Dappbuilderfunctions, Dappbuilderactions, Dappbuilderexamples, DeployDapp, Dappvideos} from './guidedapp.js';
import {Containers, Types} from './guidecomponents.js';



class Guide extends Component {
	constructor() {
    super();
    this.state = {
		menu: false,
		showtab:0
    };
  }
	componentWillMount() {
	  if (this.props.tabnum) {
			this.setState({ showtab: this.props.tabnum});
		} 
	  
  }
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.tabnum !== this.state.showtab) {
			this.setState({ showtab: nextProps.tabnum });
		}
	}
	
	componentDidChange() {
		console.log('eh')
	  if (this.props.tabnum) {
			this.setState({ showtab: this.props.tabnum});
		} 
	  
  }
	
	toggleMenu= () => {
		this.setState({ menu: !this.state.menu })
	  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		function clickHome(){
			window.location.assign("https://www.cues.sg/");
		}
		const show = (this.state.menu) ? "active" : "" ;
		var showitems = [<Main/>, <Payment/>, <BlockchainOverview/>, <SmartContractOverview/>, <DAppOverview/>, <MintingOverview/>, <StakingOverview/>, <ExchangeOverview/>, <LotteryOverview/>, <AirdropOverview/>,<VotingOverview/>, <Basics/>, <DeployContract/>, <MintToken/>, <MintNFT/>, <ExchangeCoin/>, <Staking/>, <RewardNFT/>, <Escrow/>, <Voting/>, <Ownership/>, <DappProcess/>, <Dappbuilderfunctions/>, <Dappbuilderactions/>, <Dappbuilderexamples/>, <DeployDapp/>, <Dappvideos/>,<Types/>, <Containers/>];
		//<Navbar/>
		var showContent = showitems[this.state.showtab];
		var smartContractList = ["About Us","Adding Credits","What is Blockchain", "What is a Smart Contract", "What is a DApp", "Why have a Minting Contract","Why have a Staking Contract", "Why have an Exchange Contract", "Why have a Lottery/Jackpot Contract", "Why have an Airdrop Contract", "Why have a Voting Contract", "Basic Form Notes", "How to Deploy Contract","How to Mint a Coin", "How to Mint a NFT", "How to Create a Swap Contract", "How to Create a Staking Contract", "How to Reward NFT Holders", "How to Create an Escrow Contract", "How to Create a Voting Contract", "How to Split Ownership of NFT", "DApp Process", "DApp Builder-Functions Overview", "DApp Builder-How to Use Actions", "DApp Builder-Examples of Actions", "How to Deploy DApp", "Commonly Asked Questions (Videos)","Types", "Containers and Rows"];
		//"Nav Bars and Footers"
		var main = [];
		var learn = [];
		var smartContract = [];
		var dapp = [];
		var dappcomponents = [];
		
		for (var i = 0; i < smartContractList.length; i++) {
			let style = ""
			if (i===this.state.showtab) {
				style = "sidemenuListActive"
			}
			var linkname = '/guide/'+smartContractList[i].replace(/\s+/g, '-').toLowerCase();
			
			if (i<2) {
				main.push(<Link to={linkname}><li id = {i} className = {style}>{smartContractList[i]}</li></Link>)
					
				
			} else if (i >= 2 &&  i<11) {
					//<li id = {i} onClick={(e) => this.showThisTab(e)} className = {style}>{smartContractList[i-1]}</li>
					learn.push(<Link to={linkname}><li id = {i} className = {style}>{smartContractList[i]}</li></Link>)

			} else if (i >= 11 && i <= 20) {
				smartContract.push(<Link to={linkname}><li id = {i} className = {style}>{smartContractList[i]}</li></Link>)

			} else if (i >= 21 && i <= 25) {
				dapp.push(<Link to={linkname}><li id = {i} className = {style}>{smartContractList[i]}</li></Link>)
			} else {
				dappcomponents.push(<Link to={linkname}><li id = {i} className = {style}>{smartContractList[i]}</li></Link>)
			}
			
		}
		

    return (
		<div class="wrapper">
        	<nav className={"sidebar " + show}>
				<br/>
				<section className="sidemenuList">
					<h3><Icon width = "20" icon="gg:smile-mouth-open" /> About Cues</h3>
					<ul>
						{main}
					</ul>
				</section>
				<section className="sidemenuList">
					<h3><Icon width = "20" icon="eos-icons:machine-learning-outlined" /> Learn </h3>
					<ul>
						{learn}
					</ul>
				</section>
				<section className="sidemenuList">
					<h3><Icon width = "20" icon="fluent:form-20-regular" /> Smart Contracts</h3>
					<ul>
						{smartContract}
					</ul>
				</section>

				<section className="sidemenuList">
					<h3><Icon width = "20" icon="icon-park-outline:web-page" /> DApps</h3>

					<ul>
						{dapp}
					</ul>
				</section>
		
				<section className="sidemenuList">
					<h3><Icon width = "20" icon="bi:menu-button-wide" /> DApp Components</h3>
					<ul>
						{dappcomponents}
					</ul>
				</section>
		
				<ul className="list-unstyled CTAs">
					<li>
						<a onClick={clickHome} className="article">Back to Main Page</a>
					</li>
				</ul>
			</nav>

        <div id="content">

            <nav className="navbar faqNavbar">
                <div className="container-fluid">
				
                    <div onClick={ this.toggleMenu } className="guidemenu mr-auto">
                        <Icon width = "30" icon="ic:round-menu-book" />
						
                    </div>
					<span className="logo-image mr-auto" onClick={clickHome}><img src={logo} alt="Cues Logo"></img></span>
                    
                </div>
            </nav>
			{showContent}

            
        </div>
    </div>
		

		
		
	 
			
  );
}}
  
    

export {Guide};
