// App.js
import React, {Component} from 'react';

import { Icon } from '@iconify/react';
import './css/guide.css';
import {PhotoZoom} from './modal.js';

import dappoverview1 from './img/guide/dapp/00dappoverview1.png';
import dappoverview2 from './img/guide/dapp/00dappoverview2.png';
import dappoverview3 from './img/guide/dapp/00dappoverview3.png';
import dappoverview4 from './img/guide/dapp/00dappoverview4.png';
import dappoverview5 from './img/guide/dapp/00dappoverview5.png';
import dappoverview6 from './img/guide/dapp/00dappoverview6.png';
import dappoverview7 from './img/guide/dapp/00dappoverview7.png';

import basicfn1 from './img/guide/dapp/01basicfn1.png';
import basicfn2 from './img/guide/dapp/01basicfn2.png';
import basicfn3 from './img/guide/dapp/01basicfn3.png';
import basicfn4 from './img/guide/dapp/01basicfn4.png';
import basicfn5 from './img/guide/dapp/01basicfn5.png';

import deploy1 from './img/guide/dapp/02deploydapp1.png';
import deploy2 from './img/guide/dapp/02deploydapp2.png';
import deploy3 from './img/guide/dapp/02deploydapp3.png';
import deploy4 from './img/guide/dapp/02deploydapp4.png';

import action1 from './img/guide/dapp/03action1.png';
import action2 from './img/guide/dapp/03action2.png';

import example1 from './img/guide/dapp/04example1.png';
import example2 from './img/guide/dapp/04example2.png';
import example3 from './img/guide/dapp/04example3.png';
import example4 from './img/guide/dapp/04example4.png';
import example5 from './img/guide/dapp/04example5.png';
import example6 from './img/guide/dapp/04example6.png';
import example7 from './img/guide/dapp/04example7.png';
import example8 from './img/guide/dapp/04example8.png';
import example9 from './img/guide/dapp/04example9.png';
import example10 from './img/guide/dapp/04example10.png';
import example11 from './img/guide/dapp/04example11.png';


function clickDapp(){
	window.location.assign("https://www.cues.sg/dapps");
}


class DappProcess extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Quick Overview of DApp Process</h2>
			<br/>
			<br/>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Creating DApp' className="embed-responsive-item" src = {"https://www.youtube.com/embed/jKJ6xszobVo"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<br/>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview1} className="galleryLargerImg" alt = "Cues Start Page for DApp Choose Blockchain" />
			<p> <b>1. Choose blockchain</b>: This is the page you will see when you are logged in. Click on the blockchain that your smart contract is deployed on/going to be deployed on. </p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview2} className="galleryImg" alt = "Cues Start Page for DApp Add Contract" />
			<p> <b>2. Add contract</b>: To add an already deployed contract, click <i>Load Existing Contract</i> to add the contract address. You may add multiple contracts. If you do not have a smart contract, click <i>Create New Contract</i> to go to our smart contracts generator to create one. Otherwise, click <i>Next</i> if you wish to design your dApp without having a smart contract.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview3} className="galleryLargerImg" alt = "Cues Start Page for DApp Choose Template" />
			<p> <b>3. Choose template</b>: Our AI system recommends templates based on the contracts that you have uploaded. Choose from our available templates or start with a blank screen. Use the filter button to find templates that best suit your needs. Click on any template to preview the template before making your selection. You will then be brought to our dApp Builder where you can customize your dApp with our dApp builder</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview4} className="galleryImg" alt = "Cues Dapp Builder" />
			<p> <b>4. DApp Builder</b>: Customize your dApp with our dApp builder. Add elements based on your needs. Here, you can add actions based on your smart contract (e.g. Mint, Add to Whitelist). The smart contract does not need to be generated from our smart contract generator, although doing so increases the range of templates that you have access to. However, it has needs to be deployed and published. For more information about the functions, please click on the other dApp articles. </p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview5} className="galleryImg" alt = "Cues Dapp Builder Launch Website" />
			<p> <b>5. Launch website</b>: Choose the appropriate plan to launch your dApp. You may also download the code and deploy the website on your own server. <br/> Go to the <i>How to Deploy DApp</i> guide if you have any questions about the deployment of your dApp.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview6} className="galleryLargerImg" alt = "Cues Dapp Builder Launch Website" />
			<p> <b>6. Finished</b>: Your dApp is ready! We also provide simple statistics and a database view for you to optimize your website. See https://www.cues.sg/dapps/example as an example of a launched dApp.</p>
				
			<p><b>7a. Accessing previous contracts</b>: Once logged in, you can click <i>See Previous dApps</i> to see previous work. If you are in the midst of creating a dApp, you can click the <i>Home</i> button to see previous dApps.
			<br/>
			Everything is auto-saved, including unfinished contracts, so don't worry about losing your progress!</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview7} className="galleryImg" alt = "Cues Dapp Builder Previous DApps" />
			<p><b>7b. Accessing previous contracts</b>: Here, you can view the details of your dApps, whether unfinished or launched. You can also duplicate previous dApps and edit them to launch a different dApp, or delete unfinished dApps that you do not wish to continue. Click the edit button of a dApp to update the dApp, access the statistics or add more time to the website.</p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}

class Dappbuilderfunctions extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">DApp Builder: Introduction to DApp Functions</h2>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basicfn1} className="galleryLargerImg" alt = "Cues DApp Blank Template" />
			<p><b>1. Starting Out</b>: Here is a blank template. At the start, there is a simple tutorial you can follow to get a quick understanding of how to add elements to your website. <br/>We will be going through the various features that we have in order for you to be able to fully maximize our website. </p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basicfn2} className="galleryImg row mx-auto" alt = "Cues DApp Hints Hover" />
			<p><b>2. Hints</b>: Hover on any of the icons to know what they do. </p>

			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basicfn3} className="galleryImg" alt = "Cues DApp Header" />
			<p><b>3. Main Header Functions</b></p>
			<ul className="guideList">
				<li><Icon width="20" className = "guideListIcon" icon="bi:pencil" />: Edit program name.</li>
				<li><Icon width="20" className = "guideListIcon" icon="fa:laptop"/>: Screen sizes. Toggle between the different screen size icons to see how your dApp will look on them. You may also add custom values for styles depending on the screen size you are working on. </li>
				<li><Icon width="20" className = "guideListIcon" icon="gg:undo"/>: Use the undo and redo signs when needed. </li>
				<li><Icon width="20" className = "guideListIcon" icon="codicon:feedback"/>: Send us any feedback if anything is not working, if you are unsure about something or if you want a new function or element for your dApp. </li>
				<li><Icon width="20" className = "guideListIcon" icon="ph:coin" />: Top up credits. This will be used only if you launch a smart contract or dApp with us. Trying is free.</li>
				<li><Icon width="20" className = "guideListIcon" icon="ant-design:share-alt-outlined" />: Share project with other collaborators. They will be able to edit the project with you.</li>
				<li><Icon width="20" className = "guideListIcon" icon="eva:eye-outline" />: Preview. See how your dApp will look like in different screen sizes and interact with it.</li>
				<li><Icon width="20" className = "guideListIcon" icon="bx:rocket" />: Launch project once you are done.</li>
			</ul>

			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basicfn4} className="galleryLargerImg row mx-auto" alt = "Cues DApp Side Menu" />
			<p><b>4. Side Menu Functions</b></p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>Overall</b></span>: Set the overall styles of your dApp here. You can also add actions that execute once users start the dApp, e.g. pop up to inform users of discounts.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Navigator</b></span>: See the overall structure of your dApp. Click to edit the element, or drag and drop them to another part of your dApp.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Pages</b></span>: See all the pages of your dApp. You can add new pages, duplicate current pages or delete them here. If there are more than one page, you can set which page will be the starting page of the dApp</li>
				<li className = "guideListItem"><span className = "textlist"><b>Pop Ups</b></span>: See all the pop ups. You can add new pop ups, duplicate current pop ups or delete them here. Show pop ups in your dApp by selecting the "Open a Pop Up Window" action via the <i>On Click</i> action icon in elements such as the button.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Elements</b></span>: Add elements from here. The elements shown will differ depending on the current element you are working on. Click on the gray area around the dApp design screen to add Nav Bar and Footer. Click on the element to add it relative to other elements in the panel, and drag the element into the panel to add it in a fixed position in the panel.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Definitions</b></span>: Add variables here. The initial value can only be a constant and not a value of an input as these are variables that are created from the start. You may then change these variables through <span className = "bolditalicguide">On Click</span> or <span className = "bolditalicguide">On Change</span> actions.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Contracts</b></span>: Load or add your contracts here. Contracts can be called as actions (e.g. <span className = "bolditalicguide">On Click</span> or <span className = "bolditalicguide">On Change</span>), or as components when you specify a formula.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Colors</b></span>: We have some color palettes that you might want to consider using. Click on the color palettes to access them later in when editing the styles of the elements.</li>
			</ul>

			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basicfn5} className="galleryImg row mx-auto" alt = "Cues DApp Element Functions Menu" />
			<p><b>5. Element Header Functions</b></p>
			<p>Click the underlined text to change the name of the element. This would be helpful in identifying the element you want the value from or you want changed when adding actions/functions to your dApp.<br/> Here is a list of element functions that you will encounter while editing the elements. </p>
			<ul className="guideList">
				
				<li><Icon width="20" className = "guideListIcon" icon="clarity:ruler-pencil-line" /><span className = "textlist"><b>Style</b></span>: Specify styles here. The options listed here will differ depending on the element, where the element is placed in and what screen size you are currently working on. For example, the option to have custom styles will appear when you are working on screen sizes that are bigger than extra small devices.</li>
				<li><Icon width="20" className = "guideListIcon" icon="radix-icons:dimensions" /><span className = "textlist"><b>Dimensions</b></span>: Specify height and width here.</li>
				<li><Icon width="20" className = "guideListIcon" icon="fluent:color-background-20-filled" /><span className = "textlist"><b>Background</b></span>: Change background colors. For panels, there will be 2 additional options where you could have a liner gradient background or an image as the background.</li>
				<li><Icon width="20" className = "guideListIcon" icon="bi:border-outer" /><span className = "textlist"><b>Border</b></span>: Style borders here. This includes border width, border radius, border style and border color.</li>
				<li><Icon width="20" className = "guideListIcon" icon="bx:text" /><span className = "textlist"><b>Label</b></span>: Change text label here. This option will appear for buttons and rich text where it is not possible for the text to be changed on the design screen. The label can be static or dynamic. For example, if you want the button label to change depending on whether a user is logged in, choose the <i>Dynamic</i> option and use <i>If Then Else</i> to list out what you want the text to be in the different scenarios. We will be coming up with a detailed actions guide so that you understand how to use them.</li>
				<li><Icon width="20" className = "guideListIcon" icon="arcticons:lawnicons" /><span className = "textlist"><b>Icon</b></span>: Choose your icons here from a list of open source icons. You can also change the icon color, icon height or rotate the icon.</li>
				<li><Icon width="20" className = "guideListIcon" icon="bx:font-family" /><span className = "textlist"><b>Font</b></span>: Choose font family here. This is if you want the element to have a different font family from the overall font family that you have set under overall styles.</li>
				<li><Icon width="20" className = "guideListIcon" icon="eva:color-picker-fill" /><span className = "textlist"><b>Text Color</b></span>: Change text color. For elements like tabs, please choose the text colors for both scenarios when a tab is selected and when a tab is not selected.</li>
				<br/>
				
				<li><Icon width="20" className = "guideListIcon" icon="carbon:folder-parent" /><span className = "textlist"><b>Amend Parent</b></span>: Go back to the parent element to edit it.</li>
				<li><Icon width="20" className = "guideListIcon" icon="akar-icons:copy" /><span className = "textlist"><b>Duplicate</b></span>: Duplicate the element that you have selected.</li>
				<li><Icon width="20" className = "guideListIcon" icon="gg:trash" /><span className = "textlist"><b>Delete</b></span>: Delete the element that you currently selected.</li>
				<li><Icon width="20" className = "guideListIcon" icon="akar-icons:arrow-right-thick" /><span className = "textlist"><b>Shift Right</b></span>: Shift the position of your element to the right (within its parent element).</li>
				<li><Icon width="20" className = "guideListIcon" icon="akar-icons:arrow-left-thick" /><span className = "textlist"><b>Shift Left</b></span>: Shift the position of your element to the left (within its parent element).</li>
				<li><Icon width="20" className = "guideListIcon" icon="bi:arrow-repeat" /><span className = "textlist"><b>Common</b></span>: Save style here. After styling the element, you can save the style with a name of your choice and use the style again when styling another element of the same type.</li>
				<br/>
					
				<li><Icon width="20" className = "guideListIcon" icon="icon-park-outline:click" /><span className = "textlist"><b>On Click</b></span>: Add actions here. Actions include both smart contract and non-smart contract functions. Refer to the <span className = "bolditalicguide">DApp Builder-How to Use Actions</span> article to understand how to use actions.</li>
				<li><Icon width="20" className = "guideListIcon" icon="material-symbols:change-circle-outline" /><span className = "textlist"><b>On Change</b></span>: This option will appear for inputs, where you decide what will happen every time the user inputs or edit their answer. For example, if you want to sell your cryptocurrency, OWNCOIN, in exchange for Ethereum (ETH), you might have two inputs for OWNCOIN and ETH where ETH amount changes when the user inputs how much OWNCOIN they want and vice versa.</li>
				<li><Icon width="20" className = "guideListIcon" icon="material-symbols:change-circle-outline" /><span className = "textlist"><b>Modifiers</b></span>: This option helps you see if the current element is being modified by actions from other elements.</li>
				<li><Icon width="20" className = "guideListIcon" icon="bi:clipboard-data" /><span className = "textlist"><b>Data</b></span>: This option is for charts. This is where you add the data source that will appear on your charts.</li>
				<br/>
			</ul>

			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>

			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}


class Dappbuilderactions extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">DApp Builder: Using DApp Functions</h2>
			<p>A dApp is an application that combines a smart contract and a front end user interface. Without actions, it is just a normal web app.</p>
			<p>In this section, we will be going through the actions to help you better understand how to utilize them. The list of actions here is not a comprehensive list. These actions are accessible through the <span className = "bolditalicguide">On Click</span> and <span className = "bolditalicguide">On Change</span> in the element header bar and <span className = "bolditalicguide">Actions On Start</span> in the <b>Overall</b> tab in the side menu.</p>
			<p>For the smart contract actions, those listed are the possible functions in the contracts produced by our no-code smart contract generator. We also accept smart contracts that are not generated by our platform. However, their inputs and conditions might differ from ours. To better help our users, our dApp Builder will show the comments within the code.</p>
		
			<h3 className = "guideSmallerTitle">Main Actions</h3>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>Connect Wallet</b></span>: Allows website users to sign in using their wallets in order to execute transactions. Wallets include MetaMask, WalletConnect and Fortmatic. </li>
				<li className = "guideListItem"><span className = "textlist"><b>Placeholder Action</b></span>: This is used during the design process when you would want to replace this action with another action in the future. There will be a constant reminder in the element header bar until you replace it with another action. For example, you might want to design your dApp before having a smart contract and would like to insert your smart contract functions later.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Pop Up Alert</b></span>: A simple pop up containing a text message. The input is a text, where it could be a static message like "Hello" or a dynamic message that depends on whether the website user has signed in on his/her wallet.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={action1} className="galleryImg row mx-auto" alt = "Cues DApp Pop Up" />
				<li className = "guideListItem"><span className = "textlist"><b>Open Pop Up</b></span>: A pop up that the user can design via the <b>Pop Ups</b> tab in the side menu. The design process is the same as designing a panel, except with an addition of a close button. The input is the pop up name, which is <span className = "bolditalicguide">5e6</span> in the example above.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Prompt User for Input</b></span>: A simple pop up asking a question the user will answer. The inputs are the question text and a default answer, which can be set to blank. This input can then be accessed through adding another action after it. </li>
				<li className = "guideListItem"><span className = "textlist"><b>Ask User for Confirmation</b></span>: A simple pop up where a user will click <span className = "bolditalicguide">OK</span> or <span className = "bolditalicguide">Cancel</span> in response to the text message. The inputs are the question text and the actions in response to the two possible responses. For example, it can be used to confirm if the user wants to continue with a transaction.</li>

				<li className = "guideListItem"><span className = "textlist"><b>Copy Text to Clipboard</b></span>: Allows users to copy a text with a click of the button. The input is the text that you want the user to copy. For example, you might want users to copy their referral link immediately instead of having to highlight the text and copying it themselves. </li>
				<li className = "guideListItem"><span className = "textlist"><b>Open Window with URL</b></span>: The link will open in a new tab. This is useful in cases where you want to direct users to your social media channels.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Scroll to a Specific Panel</b></span>: Our dApp builder is such that a page can have multiple panels. You might want to direct your users to a specific panel when they click a button.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Scroll to Top of Page</b></span>: This is usually when a user has reached the bottom of the page and you want to provide them an easy way to go back to the top.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Toggle Visibility of a Banner</b></span>: Click to make a banner visible or invisible.</li>

				<li className = "guideListItem"><span className = "textlist"><b>Delay Action</b></span>: Wait for a certain amount of time before executing an action. The inputs are the number of seconds you want to delay by and the action that follows after the delay.</li>
				<li className = "guideListItem"><span className = "textlist"><b>If Then Else</b></span>: Execute actions depending on a condition. One of the statements will execute when the specified condition is true, and the other one will execute when false. For example, if a user has already logged in, you might want to direct them to their dashboard, but if the user is not logged in, then you might prompt them to connect their wallet instead. </li>
				<li className = "guideListItem"><span className = "textlist"><b>Write Value to Database</b></span>: Collect information from users. For example, you might want to collect email addresses from users in order to notify them of upcoming events/news.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Update Variable</b></span>: This is for any information that you want to update and store before acting on it. The variable must be created in the <b>Variables</b> tab. For example, you might want to ask for a user's risk tolerance at the start, store that preference and change the recommended plan based on that.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Change Value of Input xxxx</b></span>: This will only appear if you have added inputs in your dApp. For example, you might have an input box for users to put their referrer address but you want to change the value of it from the start if they have typed in before.</li>
			</ul>



			<h3 className = "guideSmallerTitle">House Functions</h3>
			<p>These are usually intermediate functions to fulfil your main intention. </p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>Unsigned Integer to Decimal</b></span>: There are 3 inputs: the unsigned integer, the number of digits to cut and the number of decimal places. The number of digits to cut indicates where to cut the integer. For example, if you indicate 12345 as your integer, 3 as the number of digits to cut and 2 as the number of decimal places, then the output would be 12.34. Note that the decimal is truncated and not rounded to the nearest decimal place. </li>
			</ul>

			<h3 className = "guideSmallerTitle">Combo Functions</h3>
			<p>These are common multi-step functions that are combined into one function to make your job easier. The function tree will then be populated and you may change any of the variables or functions used. The combo functions you can access will depend on the type of smart contracts that you have loaded. </p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>Connect Wallet Text</b></span>: Changes text shown depending on whether the user has connected to their wallet and whether they are on the correct chain. If both hold true, it will show the user's shortened wallet address; else it will show "Connect Wallet" or "Connect to the [Correct] Chain"  </li>
				<li className = "guideListItem"><span className = "textlist"><b>Display Native Currency owned by Signed In User</b></span>: Shows the amount of native currency in the user's wallet.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Token IDs of NFTs owned by the current user in the [NFT Contract Name]</b></span>: (For Mint NFT Contracts) Shows the list of NFTs owned by the user. Can be used in a dropdown, which can be found under the <span className = "bolditalicguide">Input</span> component, so that users can filter for a particular NFT and see the details. Go to Style and click on <span className = "bolditalicguide">Use Formula Instead</span> under Options to use this combo function. </li>
				<li className = "guideListItem"><span className = "textlist"><b>Images of NFTs owned by the current user in the [NFT Contract Name]</b></span>: (For Mint NFT Contracts) Shows the list of images of the NFTs owned by the user. Recommended to be used in a <span className = "bolditalicguide">Gallery</span> component, so that users can see the NFTs they own. Go to Style and click on <span className = "bolditalicguide">Use Formula Instead</span> under Options to use this combo function. </li>
			</ul>



			<h3 className = "guideSmallerTitle">Smart Contract Actions (General for All Contracts)</h3>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>Amount of native currency owned by address</b></span>: Will show the amount of native currency for the wallet signed in. The user would have to be on the same blockchain network as the deployed smart contract. The output unit is the smallest unit of the currency. Hence, you may want to consider dividing your output with 10<sup>number of decimals of native currency</sup> in order to display the correct amount.</li>
				<li className = "guideListItem"><span className = "textlist"><b>Current User</b></span>: Wallet address of the current user. It is used as an input for functions like <span className = "bolditalicguide">Amount of native currency owned by address</span>.</li>
			</ul>





			<h3 className = "guideSmallerTitle">Smart Contract Actions (Staking Edition)</h3>
			<p>For contracts generated by us, there will be the word <span className = "bolditalicguide">Staking</span> in the functions name to indicate that it is a function from your staking contract. This helps you to differentiate between contracts.</p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>changeOwner</b></span>: Input an address that will take over the ownership rights of the contract. Only the owner of the contract is able to execute this function and no changes can be made once executed.</li>

				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_minStakePeriod0</b></span>: Input the new minimum stake period. The input should be the number of days multiplied by 86400 (number of seconds in a day). Only the owner of the contract is able to execute this function. This will apply for new and currently staked tokens. This function will only appear if you allow the contract owner to amend the minimum stake period when crafting the contract.</li>

				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_minStakeAmt0</b></span>: Input the new minimum stake amount. The input unit is the smallest unit of the crypto currency/token. For example, if you are staking MATIC and you want to change the minimum stake amount to 8, then the input should be 8*1000000000000000000 (18 zeros). Only the owner of the contract is able to execute this function. This function will only appear if you allow the contract owner to amend the minimum stake amount when crafting the contract.</li>

				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_maxStakeAmt0</b></span>: Input the new maximum stake amount. The input unit is the smallest unit of the crypto currency/token. For example, if you are staking MATIC and you want to change the maximum stake amount to 8, then the input should be 8*1000000000000000000 (18 zeros). Only the owner of the contract is able to execute this function. This function will only appear if you allow the contract owner to amend the maximum stake amount when crafting the contract.</li>

				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_principalTaxWhere10000IsOnePercent1</b></span>: Input the new principal tax percentage (on withdrawals). 1% corresponds to 10 000 in our contract. For example, if you want to change the principal tax percentage to 8%, please input 80000 into the function. Only the owner of the contract is able to execute this function. Changes will apply for new and currently staked tokens. This function will only appear if you allow the contract owner to amend the principal tax when crafting the contract.</li>

				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_interestTaxWhere10000IsOnePercent1</b></span>: Input the new interest tax percentage (on withdrawals). 1% corresponds to 10 000 in our contract. For example, if you want to change the interest tax percentage to 8%, please input 80000 into the function. Only the owner of the contract is able to execute this function. Changes will apply for new and currently staked tokens. This function will only appear if you allow the contract owner to amend the interest tax when crafting the contract.</li>

				<li className = "guideListItem"><span className = "textlist"><b>modifyDailyInterestRate0</b></span>: Input the new daily interest rate that will be used to calculate payouts. 1% corresponds to 10 000 in our contract. For example, if you want to change the daily interest rate to 8%, please input 80000 into the function. Only the owner of the contract is able to execute this function. The contract will calculate all the staked amounts with the old daily interest rate up to that point and then update the daily interest rate. This function will only appear if you allow the contract owner to amend the daily interest rate when crafting the contract.</li>

				<li className = "guideListItem"><span className = "textlist"><b>stake0</b></span>: This is where the staking happens. Input the crypto amount that the user want to stake. </li>

				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={action2} className="galleryImg row mx-auto" alt = "Cues DApp Auto Deployment Choose" />
					
				<li className = "guideListItem"><span className = "textlist"><b>unstake0</b></span>: Input the crypto amount that the user wants to unstake. As the input only takes in unsigned integers, pad your decimal input with the number of 0s as the decimal place of the cryptocurrency. An example would be the function in the image above as your input to the unstake function. The unstake function will check that the user has fulfilled all the conditions before the user can unstake that amount. When a user unstakes a token, the crypto earned is automatically transferred to their wallet.</li>


				<li className = "guideListItem"><span className = "textlist"><b>addReferralAddress</b></span>: Input the referrer address provided by the user. The function will check that the referrer address has made a deposit and that the user has not added other referrer addresses previously. It is possible to add a null address like 0x0000000000000000000000000000000000000000 if the user does not want to add any referrer address.</li>

				<li className = "guideListItem"><span className = "textlist"><b>withdrawReferral</b></span>: Input the crypto amount that the user wants to withdraw from their referral rewards. The function will check that the user has already earned a minimum of the crypto amount stated before releasing the rewards.</li>

				<li className = "guideListItem"><span className = "textlist"><b>withdrawInterestTax0</b></span>: There are no inputs as all the profits from charging an interest tax will go into the account. Only contract owners or specified wallet addresses can execute functions of this name. Each withdrawInterestTax function corresponds to a specific wallet address as profits would have been divided and stored before any claims are made.</li>
				<li className = "guideListItem"><span className = "textlist"><b>withdrawPrincipalTax0</b></span>: There are no inputs as all the profits from charging a principal tax will go into the account. Only contract owners or specified wallet addresses can execute functions of this name. Each withdrawPrincipalTax function corresponds to a specific wallet address as profits would have been divided and stored before any claims are made.</li>
				<li className = "guideListItem"><span className = "textlist"><b>withdrawToken</b></span>: Input the crypto amount that will be transferred to the owner. Only the owner of the contract is able to execute this function.</li>	
			</ul>

			<h3 className = "guideSmallerTitle">Smart Contract Actions (Mint Coin Edition)</h3>
			<p>For contracts generated by us, there will be the word <span className = "bolditalicguide">Coin_&lt;Name of Coin&gt;</span> in the functions name to indicate that it is a function from your minting contract. This helps you to differentiate between contracts.</p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>changeOwner</b></span>: Input an address that will take over the ownership rights of the contract. Only the owner of the contract is able to execute this function and no changes can be made once executed.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>approve</b></span>: There are two inputs: the spender address and the amount. This allows the spender to withdraw from the account of the person calling this function, multiple times, up to the input amount stated. Calling this function will overwrite the current amount with the new input amount. The function will then return a boolean to tell you if the change was successful. Do note that changing an allowance with this method brings the risk that someone may use both the old and the new allowance by unfortunate transaction ordering. One possible solution to mitigate this rare condition is to first reduce the spender's allowance to 0 and set the desired value afterwards. Alternatively, use the decreaseAllowance/increaseAllowance functions.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>decreaseAllowance</b></span>: There are two inputs: the spender address and the amount to reduce by. This is similiar to the approve function, except without the risk as mentioned in the approve function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>increaseAllowance</b></span>: There are two inputs: the spender address and the amount to increase by. This is similiar to the approve function, except without the risk as mentioned in the approve function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>burn</b></span>: Input amount to burn. </li>	
				<li className = "guideListItem"><span className = "textlist"><b>changeMintPhase</b></span>: Input the mint phase number that you want to change to. Check the specification of your contract for the mint phases. Only the owner of the contract is able to execute this function. </li>	
				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_merkleRoot1</b></span>: Merkle Tree is how we ensure the whitelist is secure. This is the root hash of the Merkle Tree. Input the new root hash to update your whitelist. This function appears only if a whitelist is used for the mint phase.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>mint1</b></span>: This is where the minting happens. The inputs would differ depending on the conditions set. </li>
				<li className = "guideListItem"><span className = "textlist"><b>ownerMint</b></span>: Input amount that owner wants to receive. Only the owner of the contract is able to execute this function.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>pause</b></span>: Pause sales of coin. Only the owner of the contract is able to execute this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>unpause</b></span>: Continue sales of coin. Only the owner of the contract is able to execute this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>transfer</b></span>: There are two inputs: the recipient address and the amount. This allows the transfer of tokens from the user who called this function to the recipient.</li>
				<li className = "guideListItem"><span className = "textlist"><b>transferFrom</b></span>: There are three inputs: the sender address, the recipient address and the amount. This allows the transfer of tokens from the sender to the recipient. This function allows a third-party (who had authorisation by using the approve function) to send tokens to the recipient address. </li>
				<li className = "guideListItem"><span className = "textlist"><b>withdrawNativeCurrency</b></span>: Input the crypto amount that will be transferred to the owner. Only the owner of the contract is able to execute this function.</li>
			</ul>

			<h3 className = "guideSmallerTitle">Smart Contract Actions (Mint NFT Edition)</h3>
			<p><b>For contracts generated by us, there will be the word <span className = "bolditalicguide">Nft_&lt;Name of NFT&gt;</span> in the functions name to indicate that it is a function from your minting contract. This helps you to differentiate between contracts.</b></p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>changeOwner</b></span>: Input an address that will take over the ownership rights of the contract. Only the owner of the contract is able to execute this function and no changes can be made once executed.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>approve</b></span>: There are two inputs: the approved address and the token ID. The owner of an NFT (with that token ID) can give approval to another account (approved address), so that the approved account can transfer the approved NFT from the owner's account. This is if you want another address to spend your NFTs, or if you want to spend NFTs belonging to another owner.
				</li>
				<li className = "guideListItem"><span className = "textlist"><b>setApprovalForAll</b></span>: Similiar to approve, except that it is granting an approved address (operator) permission to transfer all the NFTs from the owner's account.</li>
				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_reveal</b></span>: Changing this value affects whether the public can see the pre-reveal images or the actual images. </li>	
				<li className = "guideListItem"><span className = "textlist"><b>mint</b></span>: This is where the minting happens. The inputs would differ depending on the conditions set. The function will check that the inputs satisfy all the conditions required. The set of conditions can be found if you click the <span className = "bolditalicguide">Details</span> button after choosing this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>ownerMint</b></span>: Input amount that owner wants to receive. Only the owner of the contract is able to execute this function.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>pause</b></span>: Pause sales of NFT. Only the owner of the contract is able to execute this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>unpause</b></span>: Continue sales of NFT. Only the owner of the contract is able to execute this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>transferFrom</b></span>: Usage of this function is discouraged, use safeTransferFrom instead. There are three inputs: the sender address, the recipient address and the token ID. This allows the transfer of NFTs from the sender to the recipient. </li>
				<li className = "guideListItem"><span className = "textlist"><b>safeTransferFrom</b></span>: There are three inputs: the sender address, the recipient address and the token ID. This allows the transfer of NFTs from the sender to the recipient. The sender address must own the NFT with that token ID and be the one calling this function, or not own the NFT but have approval.</li>
				<li className = "guideListItem"><span className = "textlist"><b>withdrawNativeCurrency</b></span>: Input the crypto amount that will be transferred to the owner. Only the owner of the contract is able to execute this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>totalSupply</b></span>: Total number of NFTs already minted.</li>

			</ul>

			<h3 className = "guideSmallerTitle">Smart Contract Actions (Lottery Edition)</h3>
			<p><b>For contracts generated by us, there will be the word <span className = "bolditalicguide">Lottery</span> in the functions name to indicate that it is a function from your lottery contract. This helps you to differentiate between contracts.</b></p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>deposit</b></span>: Function to deposit the rewards. If you have multiple winners, ensure that the reward value is divisible by the lowest common denominator. For example, if you have 2 winners where the winnings are to be split 75-50, then you have to ensure that the deposited reward value is divisible by 5. This function appears only if you did not want to conduct lottery immediately after depositing rewards into contract.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>runLottery</b></span>: Function to choose the winners. If you chose to conduct lottery immediately after depositing rewards into contract, then the input will be the token amount you want to deposit. Otherwise, there is no input. If you have multiple winners, ensure that the reward value is divisible by the lowest common denominator. For example, if you have 2 winners where the winnings are to be split 75-50, then you have to ensure that the deposited reward value is divisible by 5. It will also send prizes directly to winners if you choose to send prizes directly to winners.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>claimWinnings</b></span>: Function for winners to claim their reward. This function appears only if you choose to not send prizes directly to winners.</li>
			</ul>

			<h3 className = "guideSmallerTitle">Smart Contract Actions (Jackpot Edition)</h3>
			<p><b>For contracts generated by us, there will be the word <span className = "bolditalicguide">Jackpot</span> in the functions name to indicate that it is a function from your jackpot contract. This helps you to differentiate between contracts.</b></p>
			<ul className="guideList">
				<li className = "guideListItem"><span className = "textlist"><b>changeOwner</b></span>: Input an address that will take over the ownership rights of the contract. Only the owner of the contract is able to execute this function and no changes can be made once executed.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_paused</b></span>: Input true or false. Will prevent users from participating in the jackpot contract if value is true.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_playerLimit</b></span>: Input number. The jackpot will pick a winner for the existing loot once the player limit is reached. This can only be changed if the current jackpot has 0 tokens (ie 0 players) in it to ensure fairness.</li>	
				<li className = "guideListItem"><span className = "textlist"><b>changeValueOf_taxRate</b></span>: Input the new tax percentage. 1% corresponds to 10 000 in our contract. For example, if you want to change the tax percentage to 8%, please input 80000 into the function. Only the owner of the contract is able to execute this function. This can only be changed if the current jackpot has 0 tokens (ie 0 players) in it to ensure fairness.</li>
				<li className = "guideListItem"><span className = "textlist"><b>jackpotCoinPrice0SourceTaxWithdrawAmt</b></span>: Withdraws the current tax profits from the contract to the person calling this function. Only the owner of the contract is able to execute this function.</li>
				<li className = "guideListItem"><span className = "textlist"><b>playJackpot</b></span>: This is how users enter the jackpot. There will be an input for the native token if you choose to play in native token. There is no input for non-native tokens as it will transfer the fee amount stated from the user's wallet. Remember to add the approval or increaseAllowance function of the token contract before adding this action as users need to give permission to the jackpot smart contract to access their tokens.
				</li>
			</ul>

			
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>

			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}

class Dappbuilderexamples extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };

	youtubeRefer = () =>{
		window.location.assign('https://www.youtube.com/watch?v=Id-xmhBcuMM');
	}
	
	youtubeStaking= () =>{
		window.location.assign('https://www.youtube.com/watch?v=-Oo83ND8XwQ&t=88s');
	}
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">DApp Builder: Examples of Actions</h2>
			<p>In this section, we will be going through some scenarios so that you understand how to insert a multi-step function in our dApp Builder.</p>
		

			<p><u>I want to change value of another input field B which should show the amount of COIN1 a user will get when they input the amount of ETH in input field A. </u></p>
			<ul className="guideList">
				<li className = "guideListItem">Ensure both input fields have <span className = "bolditalicguide">Decimal</span> as the input type.</li>
				<li className = "guideListItem">Go to input field A. Click On Change -> Change value of input B.</li>	
				<li className = "guideListItem">Select <span className = "bolditalicguide">Use Formula Instead</span>. Click on the value and a popup for formula will appear.</li>	
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example1} className="galleryImg row mx-auto" alt = "Cues DApp Example Exchange" />
				<li className = "guideListItem">Multiply the current input field A with exchange1To2rate or the equivalent in your contract as above. As the contract uses unsigned integers, you have to convert it to a decimal. </li>
				<li className = "guideListItem">Remember to set your desired number of decimal places.</li>
			</ul>

			<p><u>I want to display coin balance from user wallets when they are connected. </u></p>
			<ul className="guideList">
				<li className = "guideListItem">Click <span className = "bolditalicguide">Rich Dynamic Text</span> after creating a textbox.</li>
				<li className = "guideListItem">Under Label, click <span className = "bolditalicguide">Use Formula Instead</span>.</li>	
				<li className = "guideListItem">Click on Combos under Options and choose <span className = "bolditalicguide">Display Native Currency owned by Signed In User</span>.</li>	
				<li className = "guideListItem">Leave it as it is if you want to display the native currency that your coin is on.</li>
				<li className = "guideListItem">Otherwise, if you want to show your own coin, click on the line "Unsigned Integer: Amount of native currency owned by address". Go to Contract Functions and find [Coin Smart Contract Name].balanceOf.</li>
			</ul>

			<p><u>I want to exchange from my coin (ABCoin) to native currency. </u></p>
			<ul className="guideList">
				<li className = "guideListItem">Create an input field that has input type Decimal. This will be where users will input their desired amount of ABCoin to swap.</li>
				<li className = "guideListItem">Create another button that users will click to confirm their intent.</li>	
				<li className = "guideListItem"> Go to On Click and find [Coin Smart Contract Name].increaseAllowance. This allows the contract address to transfer native currency from the user address to the contract address.</li>	
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example2} className="galleryImg row mx-auto" alt = "Cues DApp Example addedValue" />
				<li className = "guideListItem">increaseAllowance has two inputs: Spender and addedValue. The spender will be the exchange contract address and the addedValue is the ABCoin amount the user keyed into the input field (see pic above).</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example3} className="galleryLargerImg row mx-auto" alt = "Cues DApp Example Trigger" />
				<li className = "guideListItem">Click If Succeed. Under Trigger, choose <span className = "bolditalicguide">Approval</span>.</li>
				<li className = "guideListItem">For owner, click <span className = "bolditalicguide">Use Formula Instead</span>. It will automatically change to <span className = "bolditalicguide">Current User</span>, which is what we want.</li>
				<li className = "guideListItem">For spender, input the exchange contract address.</li>
				<li className = "guideListItem">In the last dropdown box, find your exchange contract function. For smart contracts created using our platform, it will be [Exchange Smart Contract Name].exchange1to2 or [Exchange Smart Contract Name].exchange2to1.</li>
				<li className = "guideListItem">For its input, it will be the ABCoin amount the user keyed into the input field.</li>
				
			</ul>
			<p>The process will be the same for exchanging from native currency to ABCoin. Do note that the user's wallet will pop out twice to confirm the increase in allowance and to execute the exchange.</p>

			<p><u>I want to add referrals links. </u></p>
			<ul className="guideList">
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example4} className="galleryImg row mx-auto" alt = "Cues DApp Example Referral Text" />
				<li className = "guideListItem">Add rich text or a button to display the referral link. It should be in the format like above: (Page URL)&#123;choiceOfDappName&#125;/?&#123;urlParameterText&#125;=&#123;currentUserAddress&#125;. The output of Page URL will be in the format of 'https://www.cues.sg/dapps/'. The choiceOfDappName is the url you choose if you launch on our site. urlParameterText can be any character or text string. currentUserAddress is the user's wallet address so we know who to credit. </li>
				<li className = "guideListItem">Utilize the <span className = "bolditalicguide">Copy Text To Clipboard</span> function so that users can just click to copy the text.</li>	
				<li className = "guideListItem"> Add an input box. The input type should be text. This will be for users to key in the wallet address of the user who referred them. To make things easier for users, you may add Url Parameter(urlParameterText) to the default value of the input. This will auto fill the input box.</li>	
				<li className = "guideListItem">Create a button and find [Smart Contract Name].addReferralAddress with the input coming from the input box. Users will then click on the button to confirm the wallet address of the user who referred them.</li>
			</ul>
			<p>Refer to our youtube video <span className="cursorpoint iconfill bolditalicguide" onClick={this.youtubeRefer}>here</span> for a more in-depth explanation.</p>
			
			<p><u>I want to get the daily returns of each NFT in my staking contract.</u></p>
			<ul className="guideList">
				<li className = "guideListItem">Create a container or row depending on how you want to display the returns. Within the element, go to General Styles and choose <span className = "bolditalicguide">Dynamic</span> for Number of Instances. This helps to repeat the container or row for each of the NFT owned by current user.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example5} className="galleryLargerImg row mx-auto" alt = "Cues DApp Example NFT Owned By User" />
				<li className = "guideListItem">Follow the formula above to obtain the token IDs of the logged in user. If you require an in-depth explanation, watch <span className="cursorpoint iconfill bolditalicguide" onClick={this.youtubeStaking}>this section</span> of our video.</li>	
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example6} className="galleryLargerImg row mx-auto" alt = "Cues DApp Example NFT Owned By User" />
				<li className = "guideListItem"> Add a rich text within the element with the dynamic instance. Follow the formula above, where you use the [Staking Contract Name].consolidatedInterestRate with the value of the dynamic function as the input. This can be found under parental value as the container is the parent of the rich text. You will then get the number of coins per token, given as a reward for staking, per day. </li>	
			</ul>
			<p><u>I want to get the accumulated returns of each staked NFT.</u></p>
			<ul className="guideList">
				<li className = "guideListItem">Create a container or row depending on how you want to display the returns. Within the element, go to General Styles and choose <span className = "bolditalicguide">Dynamic</span> for Number of Instances. This helps to repeat the container or row for each of the NFT owned by current user.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example7} className="galleryImg row mx-auto" alt = "Cues DApp Example Staked NFTs" />
				<li className = "guideListItem">Follow the formula above to obtain the list of staked token IDs of the logged in user. </li>	
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example8} className="galleryLargerImg row mx-auto" alt = "Cues DApp Example NFT Owned By User" />
				<li className = "guideListItem"> Add a rich text within the element with the dynamic instance. Follow the formula above, where you use the [Staking Contract Name].interestEarnedUpToNowBeforeTaxesAndNotYetWithdrawn with the value of the dynamic function as the input. This can be found under parental value as the container is the parent of the rich text. You will then get the accumulated interest. Do note to cut by 18 so that it displays in the correct units. </li>	
			</ul>

			<p><u>I want a timer to show the remaining time until the reward stops for staking.</u></p>
			<ul className="guideList">
				<li className = "guideListItem">Create a container or row depending on how you want to display the returns. Within the element, go to General Styles and choose <span className = "bolditalicguide">Dynamic</span> for Number of Instances. This helps to repeat the container or row for each of the NFT owned by current user.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example7} className="galleryImg row mx-auto" alt = "Cues DApp Example Staked NFTs" />
				<li className = "guideListItem">Follow the formula above to obtain the list of staked token IDs of the logged in user. </li>	
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example9} className="galleryLargerImg row mx-auto" alt = "Cues DApp Example Timer Countdown of Staked NFT" />
				<li className = "guideListItem"> Add a timer within the element with the dynamic instance. Follow the formula above. The number of seconds here is the max period number of days multiplied by 24 (for days)*60(for hours)*60(for seconds). The stake time gives the date time when the token was staked. Hence, if you want the date time where the interest stops accumulating, you add the max period time to the date the user started staking from.</li>	
				<li className = "guideListItem">Since we are counting down, choose yes for the option for time to reference. We also do not want to show a negative time, hence, choose yes for the option, stop at 0 for the timer. </li>	
			</ul>

			<p><u>I want to be able to do multi-staking for NFTs.</u></p>
			<ul className="guideList">
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example10} className="galleryLargerImg row mx-auto" alt = "Cues Adding List to Definition" />
				<li className = "guideListItem">Create a list type called "stakinglist" that only takes in positive integers. This can be done in the Definitions tab.</li>
				<li className = "guideListItem">Create a container and set number of instances to <span className = "bolditalicguide">Dynamic</span>. For the formula, ensure that it returns the list of token ids of the NFTs the user owns. This will ensure that N containers will appear, where each container represents a specific token ID of the owner.</li>	
				<li className = "guideListItem"> Within the container, add a button that adds the token ID to the staking list. The logic is such that you check if the token id is already in the staking list. If it is, do not do any subsequent action. If it is not, then choose <span className = "bolditalicguide">Add an item onto a list</span> action where the list is <span className = "bolditalicguide">stakinglist</span> and value to push is <span className = "bolditalicguide">Governing value of Container</span>, which is the specific token ID of the container they click on. </li>	
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={example11} className="galleryLargerImg row mx-auto" alt = "Cues Function for Multi-Staking Button" />
				<li className = "guideListItem">Create an overall button to do the multi-staking. You would have to set approval for all so that users do not have to click on multiple approval messages to grant permission to send their NFTs to the staking contract. The operator is the staking contract as that is where the NFTs will be sent to. Once approval is granted, use the multipleStake function where the input is the staking list. </li>	
			</ul>


			
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>

			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}

class DeployDapp extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">
			<h2 className = "guideTitle">Deploying Your DApp</h2>
			<br/>
			<br/>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Deploying Your DApp' className="embed-responsive-item" src = {"https://www.youtube.com/embed/ktjx5NRN4-s"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<br/>
			<p><b><u>Using CUES Auto-Deployment Service</u></b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy1} className="galleryImg" alt = "Cues DApp Auto Deployment Choose" />
			<p><b>1. Click the <i>Launch</i> button.</b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy2} className="galleryImg" alt = "Cues DApp Auto Deployment Choose Plan" />
			<p><b>2. Choose Plan</b>: Choose the plan that best suits your needs. If you choose a plan that is 90 days and more, we offer the ability to customize your url link.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={dappoverview6} className="galleryLargerImg" alt = "Cues Dapp Builder Launch Website" />
			<p><b>3. Launched</b>: Your dApp is ready! Your URL link is randomized. Change your URL link (if your plan allows) and start advertising your dApp on your social media!</p>
				
			<p><b><u>Manual Deployment</u></b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy3} className="galleryImg" alt = "Cues DApp Manual Deployment Choose" />
			<p><b>1. Click the <i>Get Code</i> button.</b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy4} className="galleryLargerImg" alt = "Cues DApp Manual Deployment Instructions" />
			<p><b>2. Download or Copy Code</b>: Code is written in React. React is one of the most popular JavaScript libraries. Instructions can be seen here as well as in the downloaded zip file.</p>
			<p><b>3. Installation</b>: If you do not have Node.js, go to https://nodejs.org/en/ to download and install Node. This will also include Node Package Manager (NPM). </p>
			<p><b>4. Create dApp</b>: Create your dApp by typing "npx create-react-app [NameOfApp]". </p>
			<p><b>5. Replace Files</b>: Depending on the complexity of your dApp, our instructions include commands to install the packages your dApp needs. Replace the App.js and App.css files with the ones in the zip file and insert the lines in the file "index.html" into index.html.</p>
			<p><b>6. See dApp</b>: Type "npm start" into your Terminal to see your dApp live on localhost.</p>
			<p><b>7. Production Build</b>: Type "npm run build" to create a build directory with a production build of your dApp.</p>
			<p><b>8. Deployment</b>: There are many ways to deploy your dApp. One way is to transfer the files in the build directory into your HTTP server through a FTP application like FileZilla.</p>
			<p><b>9. Launched</b>: Your dApp is ready! Start advertising your dApp on your social media!</p>
			
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
			
			
		  </div>	  	
  );
}}

class Dappvideos extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	render(){
		
		
    return (
		  <div className="guideContainer">
			<h2 className = "guideTitle">Commonly Asked Questions (Videos)</h2>
			<br/>
			<p>We are frequently asked these questions, so we decided to create videos and explain the steps so that we can help even more users.</p>
			<br/>
			<p><b><u>How Do I Display My Crypto Balances?</u></b></p>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Display My Crypto Balances in Cues SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/9Ji7yB0t3So"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<p><b><u>How Do I Stake My Token?</u></b></p>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Stake My Token in Cues SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/8PiQxTbKm50"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<p><b><u>How Do I Add Referrals?</u></b></p>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Add Referrals in Cues SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/Id-xmhBcuMM"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<p><b><u>How Do I Stake NFTs?</u></b></p>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Stake NFTs in Cues SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/-Oo83ND8XwQ"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<p><b><u>How Do I Exchange?</u></b></p>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Exchange Tokens in Cues SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/XlH2VXt78E0"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<p><b><u>How To Do Multi-Staking (And Display Only 10 NFTs Per Page)?</u></b></p>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-11 col-md-8 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Updating Variables in Cues SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/1GU587DYsPw"}></iframe>
						</div>
					</div>
				</div>
			</div>
			
			<br/>
			<br/>
			
			
			
		  </div>	  	
  );
}}

    

export {DappProcess, Dappbuilderfunctions, Dappbuilderactions, Dappbuilderexamples, DeployDapp, Dappvideos};
