import React from 'react';

const Test5 = () : Node => {
	
	console.log(window.location.href)
	var url = window.location.href;
	//var prefix = "http://localhost:3000/paintest4";
	var prefix = "https://cues.sg/paintest4";
	  if (url.startsWith(prefix)) {
		  url = url.slice(prefix.length);
	  }
	console.log(url)

	return <div ><iframe style={{position:'fixed',  left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%', border:'none', margin:'0', padding:'0', overflow:'hidden', zIndex:'999999'}}
	src={"https://www.cues.sg/dapps/testtest1" + url}></iframe>'</div>

	}



export {Test5};