// App.js
import React, {Component} from 'react';
import { Icon } from '@iconify/react';
import './css/guide.css';
import {PhotoZoom} from './modal.js';

import column1 from './img/guide/components/00column1.png';

function clickDapp(){
	window.location.assign("https://www.cues.sg/dapps");
}

class Types extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Types</h2>
			<p>You will encounter types when you define a variable, require the use of a function or if you want users to input something. It is important to make sure that you choose the correct type or convert your values to the correct type so that our DApp Builder will provide you the relevant actions or functions.</p>
			<h3 className = "guideSmallerTitle">Basic Types</h3>
			<ul className="guideList">
				<li className = "guideListItem"><b>Blockchain Address</b>: Unique sequence of numbers and letters.</li>
				<li className = "guideListItem"><b>Boolean</b>: Only has two possible values: True or False.</li>
				<li className = "guideListItem"><b>Date Time</b>: Comprising of year, month, day and time. Date set here will be in local time. </li>
				<li className = "guideListItem"><b>Decimal</b>: Decimal includes both a whole and a fractional part (e.g. 3.14).</li>
				<li className = "guideListItem"><b>Integer</b>: Whole numbers that can either be positive or negative (e.g. -1 and 1).</li>
				<li className = "guideListItem"><b>List</b>: Sequence of multiple elements that is changeable.</li>
				<li className = "guideListItem"><b>Positive Integer</b>: Whole numbers that can only be positive, including 0.</li>
				<li className = "guideListItem"><b>Test/String</b>: Sequence of characters (e.g. AB#120) </li>
				<li className = "guideListItem"><b>Unsigned Integer</b>: Same as Positive Integer. You will encounter this quite often for smart contract variables.</li>
			</ul>
			<h3 className = "guideSmallerTitle">Tips</h3>
			<ul className="guideList">
				<li className = "guideListItem">Textboxes only take in text, so you would have to convert your variables by using functions like <span className = "bolditalicguide">Decimal to Text</span> or <span className = "bolditalicguide">Integer to Text</span>.</li>
				<li className = "guideListItem">Contract functions usually take in unsigned integers, so you would have to convert your inputs by using functions like <span className = "bolditalicguide">Text to Unsigned Integer</span> or <span className = "bolditalicguide">Decimal to Unsigned Integer</span>.</li>
			</ul>
			
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Create DApp </div>
			</div>

			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}

class Containers extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Containers</h2>
			<p>CUES uses the Bootstrap framework to create mobile-first dApps.</p>
			<p>Containers are the most basic layout element in Bootstrap. We have made it such that only containers can be directly added as the base to panels so that you can make full use of the Bootstrap grid system with its responsive breakpoints. We only allow <b>rows</b> to be added into containers.</p>
			<p>Under style, you will see there is an option for <span className = "bolditalicguide">Styled Container</span>.</p>
			<ul className="guideList">
				<li className = "guideListItem">Choosing <span className = "bolditalicguide">Yes</span> would set a max-width at each breakpoint. The breakpoints are the device screen sizes on the main tab.</li>
				<li className = "guideListItem">Choosing <span className = "bolditalicguide">No</span> would set the width as 100% at all breakpoints and the default styles for container will not be used. </li>
			</ul>
		
		
			<h2 className = "guideTitle">Rows</h2>
			<p>Rows are wrappers for columns. Each row should include a set of elements where the sum of their column widths add up to 12 or fewer. The column widths do not have to add up to exactly 12. If their column widths add up to more than 12, then those exceeding will wrap onto the next line.</p>
			<p>However, there are cases where this might not apply, especially for shorter rows. In a 12 column layout, one column will be 8.333% (100%/12) of the total width. The minimum width of a 12 column layout is then 360px due to default left and right padding of 15px per column. Remove this padding between your columns by choosing <span className = "bolditalicguide">None</span> for <span className = "bolditalicguide">Spaces between Columns</span> under Row Styles.</p>
			<p><b><u>Column Wrapping Example</u></b></p>
			<ul className="guideList">
				<li className = "guideListItem">You might want to have 6 textboxes with column widths of 5 for extra small devices, column widths of 3 for medium devices and column widths of 2 for extra large devices. </li>
				<li className = "guideListItem">For extra small devices, the overall sum of the column widths is 6*5=30 (&gt;12), so they cannot all be on one line. Since every 2 textboxes add up to 10 (&lt;12), but every 3 textboxes add up to 15(&gt;12), then every 2 textboxes will get wrapped onto a new line as one unit, resulting in 3 lines overall. </li>
				<li className = "guideListItem">For medium devices, the overall sum of the column widths is 6*3=18 (&gt;12), so they cannot all be on one line. Since every 4 textboxes add up to exactly 12, then every 4 textboxes will get wrapped onto a new line as one unit, resulting in 2 lines overall where the first line will have an overall column width of 12 and the second line will have 6.</li>
				<li className = "guideListItem">For extra large devices, the overall sum of the column widths is 6*2=12, so all the textboxes will be on one line.</li>
			</ul>
			<p>Under style, you will see there is an option for <span className = "bolditalicguide">Justify</span>, which affects the horizontal alignment of the elements within the row. </p>
			<ul className="guideList">
				<li className = "guideListItem">If you want a button to be in the center of the row, select <span className = "bolditalicguide">Center</span> under <span className = "bolditalicguide">Justify</span> and set the column width to your desired length.</li>
			</ul>
		
			<h2 className = "guideTitle">Columns</h2>
			<p>All content goes in columns. In our dApp builder, we automatically incorporate each element into a column when you add them into a row. This is why you do not see <span className = "bolditalicguide">column</span> as a component choice under the <b>Elements</b> tab when you are working on a row. </p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={column1} className="gallerySmallerImg row mx-auto" alt = "Cues DApp Builder Custom Values Screen Size" />
			<p>Under style, you will see that some options allow for custom values for each breakpoint if youa are working on a screen size that is larger than extra small devices. The custom value will take effect for screen widths greater than the screen size you chose. You may change the baseline values by selecting the <span className = "bolditalicguide">Extra Small Device</span> icon on the top bar.</p>
		
			
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Create DApp </div>
			</div>

			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}

class Navbar extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Nav Bars</h2>
			<p>This is the navigation header.</p>
			<p>Under style, you can customize the look of your Nav Bars. We will explain some of the options here.</p>
			<ul className="guideList">
				<li className = "guideListItem"><b>Collapse items for narrow screens</b>: Choosing Yes means that your elements will be hidden within a dropdown menu. The exception would be the leftmost element on the Nav Bar</li>
				<li className = "guideListItem"><b>Cluster items into left/center/right</b>: Choosing Yes provides you the ability to group your components and place them where you want them. The default would result in your components being spaced equally apart.</li>
				<li className = "guideListItem"><b>Stick Bar to the top of the screen</b>: Choosing Yes means that the Nav Bar will stay at the top of the page while scrolling.</li>
			</ul>
			
			<p>There are 5 types of components that you can add into the Nav Bars.</p>
			<ul className="guideList">
				<li className = "guideListItem"><b>Brand</b>: This will be your company/project logo. You may add an On Click function, which would typically be the <span className = "bolditalicguide">Change Page</span> action so that users can click on the logo to go to the home page.</li>
				<li className = "guideListItem"><b>Button</b>:  </li>
			</ul>
		
		
			<h2 className = "guideTitle">Rows</h2>
			<p>Rows are wrappers for columns. Each row should include a set of elements where the sum of their column widths add up to 12 or fewer. The column widths do not have to add up to exactly 12. If their column widths add up to more than 12, then those exceeding will wrap onto the next line.</p>
			<p>However, there are cases where this might not apply, especially for shorter rows. In a 12 column layout, one column will be 8.333% (100%/12) of the total width. The minimum width of a 12 column layout is then 360px due to default left and right padding of 15px per column. Remove this padding between your columns by choosing <span className = "bolditalicguide">None</span> for <span className = "bolditalicguide">Spaces between Columns</span> under Row Styles.</p>
			<p><b><u>Column Wrapping Example</u></b></p>
			<ul className="guideList">
				<li className = "guideListItem">You might want to have 6 textboxes with column widths of 5 for extra small devices, column widths of 3 for medium devices and column widths of 2 for extra large devices. </li>
				<li className = "guideListItem">For extra small devices, the overall sum of the column widths is 6*5=30 (&gt;12), so they cannot all be on one line. Since every 2 textboxes add up to 10 (&lt;12), but every 3 textboxes add up to 15(&gt;12), then every 2 textboxes will get wrapped onto a new line as one unit, resulting in 3 lines overall. </li>
				<li className = "guideListItem">For medium devices, the overall sum of the column widths is 6*3=18 (&gt;12), so they cannot all be on one line. Since every 4 textboxes add up to exactly 12, then every 4 textboxes will get wrapped onto a new line as one unit, resulting in 2 lines overall where the first line will have an overall column width of 12 and the second line will have 6.</li>
				<li className = "guideListItem">For extra large devices, the overall sum of the column widths is 6*2=12, so all the textboxes will be on one line.</li>
			</ul>
			<p>Under style, you will see there is an option for <span className = "bolditalicguide">Justify</span>, which affects the horizontal alignment of the elements within the row. </p>
			<ul className="guideList">
				<li className = "guideListItem">If you want a button to be in the center of the row, select <span className = "bolditalicguide">Center</span> under <span className = "bolditalicguide">Justify</span> and set the column width to your desired length.</li>
			</ul>
		
			<h2 className = "guideTitle">Columns</h2>
			<p>All content goes in columns. In our dApp builder, we automatically incorporate each element into a column when you add them into a row. This is why you do not see <span className = "bolditalicguide">column</span> as a component choice under the <b>Elements</b> tab when you are working on a row. </p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={column1} className="gallerySmallerImg row mx-auto" alt = "Cues DApp Builder Custom Values Screen Size" />
			<p>Under style, you will see that some options allow for custom values for each breakpoint if youa are working on a screen size that is larger than extra small devices. The custom value will take effect for screen widths greater than the screen size you chose. You may change the baseline values by selecting the <span className = "bolditalicguide">Extra Small Device</span> icon on the top bar.</p>
		
			
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width="30" icon="ant-design:rocket-outlined" /> Create DApp </div>
			</div>

			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}




export {Containers, Types, Navbar};
