import React from 'react';
import type {Node} from 'react';
import { readContract } from '@wagmi/core';
import { writeContract } from '@wagmi/core';
import { configureChains, createConfig, WagmiConfig, useAccount, useNetwork } from 'wagmi'; 
import { publicProvider } from 'wagmi/providers/public'; import { disconnect } from '@wagmi/core';
import { getDefaultWallets, RainbowKitProvider, useConnectModal, useChainModal } from '@rainbow-me/rainbowkit'; import '@rainbow-me/rainbowkit/styles.css';
import { Icon } from '@iconify/react';
import Staking from './Staking.json';

function getValueFromExistingSmartContract(account, address, jsonFile, functionName, inputTypeList, outputTypeList, chainInfo, setChainInfo, updateChecks, ...argsIn){
	
	var defaultSlate = {};

	function coverValueIfNecc(type, value){
		if (type.t === 'ListType'){
			return value.map((aVal, index)=>{
				return coverValueIfNecc(type.c, aVal);
			})
		}else if (type.t === 'Object'){
			var p = {};
			type.c.forEach((aC, index)=>{
				var cc = coverValueIfNecc(aC, value[aC.n]);
				p[aC.n] = cc;
			})
			return p;
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			if (!value.hex){
	  			return BigInt(value);
			}
		}else if (type.t === 'Text String'){
			return value.split('.infura').join('');
		}
		return value;
	}

	function flattenType(inputType, aI){
		if (inputType.t === 'ListType'){
			return aI.map((anInput, index)=>{
				return flattenType(inputType.c, anInput);
			}).join(', ');
		}else if (inputType.t === 'UInteger' || inputType.t === 'Integer'){
			return aI.toString();
		}else if (inputType.t === 'Boolean'){
			if (aI){
				return 'true'
			}else{
				return 'false'
			}
		}else if (inputType.t === 'Object'){
			var cc = {};
			inputType.c.forEach((anInput, index)=>{
				var p = flattenType(anInput, aI[anInput.n]);
				cc[anInput.n] = p;
			})
			return JSON.stringify(cc);
		}else if (inputType.t === 'Bytes'){
			return '_';
		}else if (inputType.t === 'Text String' || inputType.t === 'Address'){
			return aI;
		}else{
			console.warn(inputType);
			return aI;
		}
	}

	if (account){

		var args = argsIn.filter((aI, index)=>{
			return index < inputTypeList.length;
		})

		var flattenedInputs = args.map((aI, index)=>{
			var inputType = inputTypeList[+index];
			return flattenType(inputType, aI);
		})

		var point = [address, functionName].concat(flattenedInputs);
		var pOut = layoutFoundationForAnObject(point, chainInfo);
		if (pOut[0] !== undefined){
			return pOut;
		}else{

			function actuallyCheck(){
				var gotNotChecked = false;
				for (var i = 0; i < updateChecks.length; i++){
					if (!updateChecks[i]){
						gotNotChecked = true;
						break;
					}
				}
				if (gotNotChecked){
					setTimeout(function(e){ actuallyCheck(); }, 500);
				}else{

			        var stuff = {
			          address,
			          abi: jsonFile,
			          args,
			          functionName,
			        };

			        readContract(stuff).then((value)=>{
						var k = {checked:true}
						if (outputTypeList.length === 1){
							k[0] = coverValueIfNecc(outputTypeList[0] , value);
						}else{
							for (var i = 0; i < outputTypeList.length; i++){
								var aVal = coverValueIfNecc(outputTypeList[i], value[i]);
								k[i] = aVal;
							}
						}
						replacement(point, chainInfo, k);
						setChainInfo({...chainInfo});
			        }).catch((e)=>{
						console.log(e);
			        });

				}
			}

			actuallyCheck();
			return defaultSlate;
		}
	}else{
		return defaultSlate;
	}
}

function defaultValue(type, path){
	for (var i = 0; i < path.length; i++){
		if (path[i].t === 'l'){
			type = type.c;
		}else if (path[i].t === 'oP'){
			for (var j = 0; j < type.c.length; j++){
				if (type.c[j].n === path[i].v){
					type = type.c[j].t;
					break;
				}
			}
		}
	}

	function processDefault(type){
		if (type.t === 'ListType'){
			return [];
		}else if (type.t === 'Object'){
			var out = {};
			for (var i = 0; i < type.c.length; i++){
				out[type.c[i].n] = processDefault(type.c[i].t);
			}
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			return BigInt('0');
		}else if (type.t === 'Text String'){
			return '-';
		}else if (type.t === 'Address'){
			return '0x0000000000000000000000000000000000000000'
		}else if (type.t === 'Boolean'){
			return false;
		}
	}
	return processDefault(type);
}


function urlSearchParams(searchFor){
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get(searchFor);
}

const TextInputRecall = ({defaultValue, style, className, onChange, onKeyDown, idNos, inputValues, setInputValues, gVs}): Node => {

		var onChangeExt = onChange;
		var onKeyDownExt = onKeyDown;
		var idOut = [idNos].concat(gVs).join('_');
		
		var value = (inputValues[idOut]? inputValues[idOut] : '');
		
		function setValue(valueIn){
				inputValues[idOut] = valueIn;
				setInputValues({...inputValues});
		}

		React.useEffect(() => {
				setValue(defaultValue);
		}, [defaultValue]);

		function onChangeL(e){
				setValue(e.target.value);
				if (onChangeExt){
						onChangeExt(e.target.value);
				}
		}

		function onKeyDownL(e){
				if (onKeyDownExt){
						onKeyDownExt(e);
				}
		}
		return <input className={className} value={value} disabled={style.disabled} onKeyDown={onKeyDownL} onChange={onChangeL} placeholder={style.placeholder} style={style} />;  
}

const HoverButton = ({onClick, children, className, hoverColor, disabled, style, dataTooltip, dataTooltipLocation}): Node => {
		
	const [isHover, setIsHover] = React.useState(0);

	function onMouseOver(e){
		setIsHover(true);
	}

	function onMouseOut(e){
		setIsHover(false);
	}

	function onClickHere(e){
		if (onClick){
			onClick(e);
		}
	}

	var styleX = {...style};
	if (isHover){
		styleX.backgroundColor = hoverColor;
	}
	
	return <button onClick={onClickHere} disabled={disabled} style={styleX} className={className} onMouseOut={onMouseOut} onMouseOver={onMouseOver} data-tooltip={dataTooltip} data-tooltip-location={dataTooltipLocation} >{children}</button>

}

function shortenName(text){
				if (text.length < 9){
								return text;
				}    
				return text.substr(0, 2) + '...' + text.substr(text.length - 4);
}

function layoutFoundationForAnObject(list, chainInfo){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		var p1 = p[list[i]];
		if (!p1){
			p[list[i]] = {};
			p1 = p[list[i]];
		}
		p = p1;
	}
	return p;
}

function replacement(list, chainInfo, object){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		if (i === list.length - 1){
			p[list[i]] = object;
		}else{
			p = p[list[i]];
		}
	}
}


const App = (): Node => {

	/* global BigInt */
	const { openConnectModal } = useConnectModal();
	const { openChainModal } = useChainModal();
	const { address, isConnected } = useAccount();
	const { chain } = useNetwork();
	var chainId = BigInt(0);
	if (chain){
		chainId = BigInt(chain.id);
	}
	const [inputValues, setInputValues] = React.useState({})
	const [chainInfo, setChainInfo] = React.useState({});
	function clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c59_3f2f2661_i_c61_36bd5e20(e){
		if(isConnected
		) {
			writeContract({ address:'0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', abi: Staking.abi, args:[inputValues['fe 1 _s c31_86590548 k c47_e450cc0f i c0_0f2783ee i c49_ff34bfd9 i c59_3f2f2661 i c60_9d346b5c']], functionName: 'addReferralAddress'}).then((aResponse)=>{ console.log(aResponse);}).catch((e1)=>{
			window.alert(e1)})
		}else{
			if (isConnected){
			if (chainId === BigInt(80001)){
				disconnect();
			}else{
				openChainModal();
			}
		}else{
			openConnectModal();
		}
		getInfo();
		
		};
		e.stopPropagation();
	}
	function clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c0_0ec32e00_i_c1_df88bbb1(e){
		navigator.clipboard.writeText((window.location.href.split('?')[0] + '?refer=' + (address ? address : '')));
		e.stopPropagation();
	}
	function clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c1_73fcd311_i_c3_99e94bf4(e){
		if(isConnected
		) {
			function downPath_0(){
				if (function(outputTypeList, pathDownList){ return getValueFromExistingSmartContract(address, '0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', Staking.abi, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); }([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}], []).checked){
					writeContract({ address:'0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', abi: Staking.abi, args:[function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', Staking.abi, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[2];}else{return defaultValue(outputTypeList[2], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}], [])], functionName: 'withdrawReferral'}).then((aResponse)=>{ console.log(aResponse);}).catch((e1)=>{
					window.alert(e1)})
				}else{
					setTimeout(function(e){downPath_0()}, 500);
				}
			}
			downPath_0()
		}else{
			if (isConnected){
			if (chainId === BigInt(80001)){
				disconnect();
			}else{
				openChainModal();
			}
		}else{
			openConnectModal();
		}
		getInfo();
		
		};
		e.stopPropagation();
	}
	function clickActioncn_c2_d1339868_nv_i_c1_3a5a4a1e(e){
		if (isConnected){
			if (chainId === BigInt(80001)){
				disconnect();
			}else{
				openChainModal();
			}
		}else{
			openConnectModal();
		}
		getInfo();
		
		e.stopPropagation();
	}
	function getInfo(){
		setChainInfo({}); 
	}
	function nav_c2_d1339868(){
		return (
		<nav className='navbar  navbar-light' style={{backgroundColor:'rgb(125, 170, 255)'}}>
			<span className='navbar-brand'><img src={'https://cdn.pixabay.com/photo/2016/06/14/10/42/scarecrow-1456235_1280.png'} style={{marginRight:'10px', width:'2.86em'}} alt='logo' /><span  style={{fontFamily:'Zilla Slab', color:'rgb(255, 255, 255)', fontWeight:(1 ? 'bold' : 'normal')}}>{'NFTFORM'}</span></span>
			
<HoverButton style={{color:'rgb(255, 255, 255)', fontFamily:'Zilla Slab', fontWeight:(1 ? 'bold' : 'normal'), backgroundColor:'rgb(76, 138, 255)', cursor:'pointer'}} className='btn  btn-lg '  onClick={clickActioncn_c2_d1339868_nv_i_c1_3a5a4a1e}  hoverColor={'rgb(44, 117, 255)'}>{(isConnected ? ((chainId === BigInt(80001)) ? shortenName((address ? address : '')) : ('Connect to the ' + 'Mumbai' + ' chain')) : 'CONNECT')}</HoverButton>
		</nav>)
	}
	return <div style={{color:'rgb(255, 255, 255)', fontFamily:'Lato', backgroundColor:'rgb(125, 170, 255)'}}>{nav_c2_d1339868()}
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(255, 244, 159)'}}><b>Referrals </b></span></span>
						<div key={1}><span key={0} style={{fontSize:'30px'}}><span style={{color:'rgb(255, 244, 159)'}}><b>(for staking)</b></span></span>
						</div>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='   col-11'><div style={{borderColor:'rgb(0,0,0)', borderWidth:1, borderStyle:'none', borderRadius:'0.48em', backgroundColor:'rgb(86, 144, 255)'}} className='  mt-2  mb-2  ml-0  mr-0 '>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-6     text-left' style={{borderWidth:0, paddingLeft:25, paddingTop:5, paddingBottom:5}}><span key={0} style={{fontSize:'16px'}}><span style={{color:'rgb(255, 244, 159)'}}><b>Tier 1 Rewards</b></span></span>
								<div key={1}><span key={0} style={{fontSize:'16px'}}>5%</span>
								</div>
							</div>
							<div className='  col-6     text-left' style={{borderWidth:0, paddingLeft:25, paddingTop:5, paddingBottom:5}}><span key={0} style={{fontSize:'16px'}}><span style={{fontFamily:'PT Sans'}}><span style={{color:'rgb(255, 244, 159)'}}><b>Tier 2 Rewards</b></span></span></span>
								<div key={1}><span key={0} style={{fontSize:'16px'}}>3%</span>
								</div>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-12     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}>We have 2 levels of referral programs. What are you waiting for, refer your friends and earn CUESCOIN!</span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-12     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'25px'}}><span style={{color:'rgb(255, 244, 159)'}}><b>Referred By</b></span></span>
							</div>
						</div></div>
						<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', Staking.abi, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}], []) === '0x0000000000000000000000000000000000000000') ? true : false) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<div className='  col-10      text-left' style={{borderColor:'rgb(255, 244, 159)', borderWidth:1, borderStyle:'solid', borderRadius:'0.41em', padding:10}}>
								<span key={0}>{function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', Staking.abi, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}], [])}</span>
							</div>
						</div></div>
						<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(address, '0xF72f0ccD7617a11c985F27Fe85242282406BdCfc', Staking.abi, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], (address ? address : ''), []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}], []) === '0x0000000000000000000000000000000000000000') ? false : true) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<TextInputRecall defaultValue={urlSearchParams('refer')} className='form-control   col-7       text-left ' style={{backgroundColor:'rgb(86, 144, 255)', placeholder:'Add referral address', color:'rgb(255, 255, 255)', disabled:false, borderColor:'rgb(255, 244, 159)', borderWidth:1, borderStyle:'solid', borderRadius:'0.41em'}} gVs={[]} setInputValues={setInputValues} inputValues={inputValues} idNos={'fe 1 _s c31_86590548 k c47_e450cc0f i c0_0f2783ee i c49_ff34bfd9 i c59_3f2f2661 i c60_9d346b5c'} />
							<HoverButton style={{fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-3    '  onClick={clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c59_3f2f2661_i_c61_36bd5e20}  hoverColor={'rgb(255, 209, 126)'}>{'CONFIRM'}</HoverButton>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-12     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'25px'}}><span style={{color:'rgb(255, 244, 159)'}}><b>Referral Link</b></span></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-center  align-items-start'>
							<div className='  col-9     text-left' style={{borderColor:'rgb(255, 244, 159)', borderWidth:1, borderStyle:'solid', borderRadius:'0.45em', padding:10}}>
								<span key={0} style={{fontSize:'12px', fontStyle:'italic'}}>{(window.location.href.split('?')[0] + '?refer=' + (address ? address : ''))}</span>
							</div>
							<div className='  col-1    ml-2 '><Icon height={'40px'} icon={'icon-park-outline:copy-link'} onClick={clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c0_0ec32e00_i_c1_df88bbb1}  style={{color:'rgb(255, 244, 159)', cursor:'pointer'}} /> </div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1 mt-lg-3 mt-xl-4  mb-1 mb-lg-3 mb-xl-4  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<HoverButton style={{fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-6 col-md-4    '  onClick={clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c1_73fcd311_i_c3_99e94bf4}  hoverColor={'rgb(255, 209, 126)'}>{'CLAIM REWARDS'}</HoverButton>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-2 mt-lg-3 mt-xl-4  mb-2 mb-lg-3 mb-xl-4  '/>
						</div></div>
					</div></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
			</div></div>
		</div></div>
}

const Test2 = () : Node => {
	console.log(window.location)
	console.log(window.parent.location)

	var xx = {id:80001, name:'Polygon Mumbai Testnet', network:'maticmum', nativeCurrency:{decimals:18, name:'MATIC', symbol: 'MATIC'}, rpcUrls:{default:{http:['https://rpc-mumbai.maticvigil.com/']}, public: {http:['https://rpc-mumbai.maticvigil.com/']}}, blockExplorers:{etherscan:{name:'PolygonScan',url:'https://mumbai.polygonscan.com'}, default:{name:'PolygonScan', url:'https://mumbai.polygonscan.com'}}, contracts:{ multicall3:{address:'0xca11bde05977b3631167028862be2a173976ca11', blockCreated:25770160}}}

	const { chains, publicClient, webSocketPublicClient } = configureChains(
		[xx],
		[publicProvider()]
	);

	const { connectors } = getDefaultWallets({
		appName: 'test',
		projectId: 'd059bb6251f7e6400d4ab3e82ad8e8c3',
		chains
	});

	const wagmiConfig = createConfig({
		autoConnect: true,
		connectors,
		publicClient,
		webSocketPublicClient,
	});

	return <WagmiConfig config={wagmiConfig}><RainbowKitProvider chains={chains}><App /></RainbowKitProvider></WagmiConfig>

	}

	

export {Test2};