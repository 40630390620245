// App.js
import React, {Component} from 'react';
import {Link } from "react-router-dom";

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';

import jw1 from './img/template/2.png';
import l1 from './img/template/3.png';
import cs1 from './img/template/4.png';
import sr1 from './img/template/5.png';
import cs2 from './img/template/7.png';
import nft1 from './img/template/8.png';

import cs3 from './img/template/9.jpeg';
import nft2 from './img/template/10.jpeg';
import cs4 from './img/template/11.jpeg';
import sr2 from './img/template/12.jpeg';
import sr3 from './img/template/13.jpeg';
import cs5 from './img/template/14.jpeg';
import sr7 from './img/template/15.png';
import sr4 from './img/template/16.jpeg';
import sr5 from './img/template/17.png';
import defi1 from './img/template/18.png';
import sr6 from './img/template/19.png';
import m1 from './img/template/20.png';
import m2 from './img/template/21.png';
import m3 from './img/template/22.png';



class GalleryPics extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		
	  }
	render(){
		var imagelist = []
		for (var i = 0; i < this.props.images.length; i++) {
			//var pain = require({images[i]});
			imagelist.push(<div className="col-sm-6 col-lg-4 mb-3 gallerycolpadding">
						   	  <div className="gallerybrowsermenuBar">
									<div className="gallerybrowsermenuRow">
										<div className="gallerybrowserleft">
											<span className="gallerybrowsermenuBarDots menuBarDots1"></span>
											<span className="gallerybrowsermenuBarDots menuBarDots2"></span>
											<span className="gallerybrowsermenuBarDots menuBarDots3"></span>
										</div>
									</div>
									<div className="gallerycontent">
										<img loading = "lazy" src={this.props.images[i]} alt="DApp Template" className="img-thumbnail galleryimage"/>
									</div>
								</div>
							</div>)}
    return (
			<div className="gallerywrapper">
				<div className="row">
					 {imagelist}
				</div>
				<div className="text-center">
				
			</div>
			</div>
		
		  	
  );
}}
	
class NGallery  extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0
     
    };
  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		var sliderstyle = "sliderquarter slidertab0";
		var itemshown;
		if (this.state.showtab ===0){
			itemshown =  <GalleryPics images = {[cs1, cs2]}/>;		
		} else if (this.state.showtab ===1){
			sliderstyle = "sliderquarter slidertab1";
			itemshown =  <GalleryPics images = {[jw1, l1]}/>;		
		} else if (this.state.showtab ===2){
			sliderstyle = "sliderquarter slidertab2";
			itemshown =  <GalleryPics images = {[sr1]}/>;		
		} else {
			sliderstyle = "sliderquarter slidertab3";
			itemshown = <GalleryPics images = {[nft1]}/>;		
		}
		var tabmenu = [];
		var tabitems = ["Coming Soon", "Connect Wallet", "Staking Rewards", "NFTs"]
		for (var i = 0; i < tabitems.length; i++) {
			let style = ""
			if (i===this.state.showtab) {
				style = "pricingtabchosen"
			}
			tabmenu.push(<li id = {i} onClick={(e) => this.showThisTab(e)} className = {style}><label><span id = {i} onClick={(e) => this.showThisTab(e)}>{tabitems[i]}</span></label></li>
		)
			
		}
		
		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="col-md-12 text-center">
				<h2 className = "pricingtitlesmall pricingtitle">Choose your Template</h2>
				<h4 className = "pricingsub">Get access to customizable webpage designs alongside seamless integration with your Smart Contract.</h4>
			</div>
			<br/><br/>
			<div className="pricingtabs">
			  <ul>
				{tabmenu}
			  </ul>
  
			  <div className={sliderstyle}>
					<div className="indicator"></div>
			  </div>
			  </div>
			  <br/>
			  {itemshown}

			  <br/>
			  <br/> 
		</div>
		<Footer />
		</div>
		
		  	
  );
}}

class Gallery extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0
     
    };
  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
				//<Link to="/pain" className="dropdown-item"><span className="item-text">Smart Contract</span></Link>


		


		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="col-md-12 text-center">
				<h2 className = "pricingtitlesmall pricingtitle">Choose your Template</h2>
				<h4 className = "pricingsub">Get access to customizable webpage designs alongside seamless integration with your Smart Contract.</h4>
			</div>
			
			<br/><br/>
			  <div className="gallery gallerywrapper">
					<div className="gallery__column">
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={m1} alt="Mobile DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Mobile Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={m2} alt="Mobile DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Mobile Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={defi1} alt="DeFi DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">DeFi Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr5} alt="Staking Rewards DeFi DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={cs3} alt="Coming Soon DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Coming Soon Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={nft2} alt="NFT DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">NFT Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr4} alt="Staking Rewards DeFi DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={cs5} alt="Coming Soon DApp Template"  className="gallery__image"/>
								<figcaption className="gallery__caption">Coming Soon Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={nft1} alt="NFT DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">NFT Template</figcaption>
							</figure>
						</span>

						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={cs2} alt="Coming Soon DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Coming Soon Template</figcaption>
							</figure>
						</span>

						
					</div>
					<div className="gallery__column">
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={m3} alt="Mobile DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Mobile Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr6} alt="Staking Rewards DeFi DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={cs4} alt="Coming Soon DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Coming Soon Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr2} alt="Staking Rewards DeFi DApp Template"  className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr7} alt="Staking Rewards DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr3} alt="Staking Rewards DeFi DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={l1} alt="Lottery DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Lottery Template</figcaption>
							</figure>
						</span>

						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={sr1} alt="Staking Rewards DeFi DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Staking Rewards Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={cs1} alt="Coming Soon DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Coming Soon Template</figcaption>
							</figure>
						</span>
						<span target="_blank" className="gallery__link">
							<figure className="gallery__thumb">
								<img loading = "lazy" src={jw1} alt="Jackpot DApp Template" className="gallery__image"/>
								<figcaption className="gallery__caption">Jackpot Template</figcaption>
							</figure>
						</span>
					</div>
				</div>
				<div className="text-center">
				
			</div>
			  
		</div>
		
		
		<Footer />
		</div>
		
		  	
  );
}}


export {Gallery};
