// App.js
import React, {Component} from 'react';

import { Icon } from '@iconify/react';
import './css/bootstrap.css';
import './css/home.css';
import {Modal, Attribute} from './modal.js';


class Footer extends Component {
	constructor() {
    super();
    this.state = {
	  attribute: false,
	  show: false,
    };
	
  }
	
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  showAttribute = () => {
    this.setState({ attribute: true });
  };

  hideAttribute = () => {
    this.setState({ attribute: false });
  };

	clickTwitter = () =>{
		
//      e.preventDefault(); 
	window.location.assign('https://twitter.com/Cues_Sg');
}
	render(){ 
		//<span className="cursorpoint iconfill" onClick={this.showAttribute}>References</span>.
		
    return (
	 <div>
		<div>
		  <Modal show={this.state.show} handleClose={this.hideModal}>
			  <p>Modal</p>
			</Modal>
		</div>
		<div>
		  <Attribute show={this.state.attribute} handleClose={this.hideAttribute}>
			  <p>Attribute</p>
			</Attribute>
		</div>
		<footer id="sticky-footer" className="flex-shrink-0 py-4">
			<div className="container text-center">

			  <small className="footer0">Copyright &copy;CUES.SG . &nbsp;</small> <span className="cursorpoint iconfill" onClick={this.clickTwitter}><Icon width = "20" icon="akar-icons:twitter-fill" /> </span><small>&nbsp;</small> 
		
			</div>
		</footer>
		
    </div>
  );
}}

export default Footer;