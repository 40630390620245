// App.js
import React, {Component, useState, useEffect} from 'react';
import {Link } from "react-router-dom";
import {fetchSomething} from './ServerComms';

import { Icon } from '@iconify/react';
import './css/bootstrap.css';
import './css/home.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';
import {Event, Modal} from './modal.js';

import binance from './img/logo/binancefull.png';
import ethereum from './img/logo/ethereumfull.png';
import polygon from './img/logo/polygonfull.png';
import fantom from './img/logo/fantomfull.png';
import avalanche from './img/logo/avalanchefull.png';
import cronos from './img/logo/cronosfull.png';
import dogechain from './img/logo/dogechainfull.png';
import heco from './img/logo/hecofull.png';
import loop from './img/logo/loopfull.png';
import arbitrum from './img/logo/arbitrumfull.png';
import conflux from './img/logo/confluxfull.png';
import manymore from './img/logo/manymore.png';


import template1 from './img/templatescroll/1.jpeg';
import template2 from './img/templatescroll/2.jpeg';
import template3 from './img/templatescroll/3.jpeg';
import template4 from './img/templatescroll/4.jpeg';
import template5 from './img/templatescroll/5.jpeg';
import template6 from './img/templatescroll/6.jpeg';

import contract2 from './img/contractpreview/4.png';
import dapp1 from './img/contractpreview/6.png';


const Statistics = (): Node => {

  const [nosContractLaunches, setNosContractLaunches] = useState(1596);
  const [nosDappLaunches, setNosDappLaunches] = useState(1746);

  function getData(){

    function postGetLaunches(response){
      setNosContractLaunches(response.v[0].v);
      setNosDappLaunches(response.v[1].v);
    }

    fetchSomething({u:'getNosLaunches'}, postGetLaunches);

  }
  useEffect(()=>{
    getData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="flexThis">
	  		<div className="statsDiv">
				<div className="statsTitle"> 38 </div>
				<div> Free Templates </div>
			</div>
			<div className="statsDiv">
				<div className="statsTitle"> {nosContractLaunches} </div>
				<div> Contracts Launched </div>
			</div>
			<div className="statsDiv">
				<div className="statsTitle"> {nosDappLaunches} </div>
				<div> DApps Launched </div>
			</div>
		  </div>
}



class MainHome extends Component {
	constructor() {
    super();
    this.state = {
		dataForm: [{p_id: 1,p_name: "Cross-Chain Bridge Development", isChecked: false}, {p_id: 2, p_name: "Update Whitelisted Addresses", isChecked: false}],
		suggestion: ""
     
    };
  }
	startNow = () => {
    this.props.changePage(1);
  };
	showModal = () => {
		this.setState({ show: true });
	};
	hideModal = () => {
		this.setState({ show: false });
	};
	changeCheck = (id) => {
		let temp = [...this.state.dataForm];
		const index = temp.findIndex((x) => x.p_id === id);
		if (index === -1) return;
		temp[index].isChecked = !temp[index].isChecked;
		this.setState({ dataForm: temp });
	};

	changeSuggestion = (e) => {
		this.setState({ suggestion: e.target.value });
	}

	clickSurvey = (surveyID, voteID)=> {
		console.log('eh')
		function postGetLaunches(response){
				
		}
		fetchSomething({u:'submitVote', surveyID, voteID}, postGetLaunches);
	}
	handleSubmit = () => {
		
		if (this.state.suggestion.length !== 0) {
			this.clickSurvey(1,this.state.suggestion)
		}
		for (var i = 0; i < this.state.dataForm.length; i++) {
			if (this.state.dataForm[i].isChecked) {
				this.clickSurvey(1,this.state.dataForm[i].p_name)
			}
		}
		
		window.alert('Voted');
	};

	
	
	render(){ 
		
		function clickSmartContract(){
			window.location.assign("https://www.cues.sg/contracts");
		}
		function clickDapp(){
			window.location.assign("https://www.cues.sg/dapps");
		}
		
		
		function clickTwitter(){
			window.location.assign('https://twitter.com/Cues_Sg');
		}
		function clickTelegram(){
			window.location.assign('https://t.me/+yK9fP_1z27Q2NzVl');
		}
		
		
		var smartcontractlist = [["Mint your own Coin", "icomoon-free:coin-dollar"], ["Have your own NFT Collection", "mdi:alpha-n-circle-outline"], ["Interact with other Contracts","carbon:ibm-cloud-pak-manta-automated-data-lineage"], ["Create Swap Contracts","ic:baseline-currency-exchange"], ["Stake your Tokens","cryptocurrency:safe"], ["Airdrop/Reward Token Holders", "fluent:reward-16-regular"], ["Hold Tokens in Escrow", "carbon:piggy-bank"], ["Create Voting System for NFT Holders", "fluent:vote-20-regular"], ["Split Ownership of your NFT", "mdi:fraction-one-half"], ["Create Message Board", "tabler:messages"], ["Split Payments", "vaadin:money-exchange"], ["Lottery", "healthicons:money-bag-outline"], ["Jackpot", "game-icons:receive-money"]];
		var smartcontracttypes = [];
		for (var i = 0; i < smartcontractlist.length; i++) {
			smartcontracttypes.push(<div className="">
					<div onClick={clickSmartContract} className="smartcontractcard">
						<div className="text-center">
							<Icon height = "35" icon={smartcontractlist[i][1]} />
				  		</div>
						<div className="text-center">
							{smartcontractlist[i][0]}
				  		</div>
					</div>
				</div>
					  )
			
		}
		
		var templatelist = [[template1, "Coming Soon DApp Template"], [template2, "NFT DApp Template"], [template3, "Coming Soon DApp Template"], [template4, "Staking Rewards DeFi DApp Template"], [template5, "Staking Rewards DeFi DApp Template"], [template6, "Coming Soon DApp Template"]];
		var templatesshow = [];
		for (var j = 0; j < templatelist.length; j++) {
			templatesshow.push(<div className="marquee-item" onClick={clickDapp}>
									<img loading="lazy" src={templatelist[j][0]} className="floatingleft" alt = {templatelist[j][1]} />
								</div>
					  )
		}
		
		var logolist = [[ethereum, "Ethereum Logo"], [binance, "Binance Logo"], [polygon, "Polygon Logo"], [avalanche, "Avalanche Logo"], [fantom, "Fantom Logo"], [dogechain, "Dogechain Logo"], [heco, "Heco Logo"], [loop, "Loop Logo"],[cronos, "Cronos Logo"],[conflux, "Conflux Logo"], [arbitrum, "Arbitrum Logo"], [manymore, "Many More"]];
		
		var logoshow = [];
		for (var k = 0; k < logolist.length; k++) {
			logoshow.push(<div className="col-6 col-lg-4 py-3 text-center">
									<img loading="lazy" className="logo-imagebrand" src={logolist[k][0]} alt={logolist[k][1]}></img>
								</div>
					  )
		}
		
		//<div className="btn-solid-lgopposite" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Smart Contract </div> 
		//<div className="btn-solid-lg" onClick={clickDapp}><Icon width = "30" icon="ant-design:rocket-outlined" /> Launch dApp </div> 
		
		/*
		<div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">Poll</h2>
				<p className="smallestheader">Choose the service(s) that you would like us to add!</p>
			</div>
			<div className='row text-center justify-content-center'>
					<div className='col-10 col-md-6 col-lg-5'>
					<form>
						{this.state.dataForm.map((li, index) => (
						 	  <div key={index} className="pollDiv">
								<label className="checkbox bounce" id="check1">
									<input type="checkbox" checked={li.isChecked} onChange={() => {
									this.changeCheck(li.p_id);}}/>
									<svg viewBox="0 0 21 21">
										<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
									</svg>
									<span>{li.p_name}</span>
								</label>
							  </div>
							))}
						<div className="pollDiv">
							<input type="text" name="suggestion" className="form-control pollSuggestion" placeholder="Other Services *" value={this.state.suggestion} onChange={(e) => this.changeSuggestion(e)} />
						</div>
							                    

						<div className="text-center justify-content-center">
							<div className="justify-content-center d-flex align-items-center btn-solid-lg btn-removemargin" onClick={this.handleSubmit}> Submit</div>
						</div>
					</form>
					</div>
			</div>
		 </div> 
		 */
		
		/*
		
		
		<div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">DApp Templates</h2>
			</div>
			<div className="row">		
				<div className="marquee-wrapper">
					<div className="marquee-block">
						<div className="marquee-inner to-left">
							{templatesshow}
							{templatesshow}
						</div>
					</div>
				</div>
			</div>
		 </div> 
		*/
		
		
		
		
    return (
	 <div>
		<header className="header">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="text-container">
							<br/>
							<p className="headermodified">Make Technology Accessible<br/></p>
							<p className="smallerheader"> Smart contracts and DApps the easy way.<br/></p>
							<br/>
							<div className="btn-solid-lgopposite" onClick={this.startNow}><Icon width = "30" icon="ion:rocket-outline" /> Start Now </div>
							<br/>
							<br/>
							
						</div> 
					</div> 
				</div> 
				
			</div> 
		</header>
		
		<div className="statsContainer containerSpacing">
			<Statistics/>
		 </div> 
		
		
		 <div className="container containerSpacing">
			<div className="row justify-content-center">
			  <div className="col-lg-4 py-3 wow fadeInUp">
				<div className="d-flex flex-row">
				  <div>
					<h2 className = "titlecol">Easy</h2>
				  </div>
				  <div className="thumbnail">
					  <Icon height = "50" icon="tabler:hand-click" />
				  </div>
				  
				</div>
				<p className = "titledesc"><span className = "smartcontractdesc">Smart Contracts</span>: Create your customised smart contract by filling up a multiple-choice form. You can easily upload your smart contracts to the blockchain within Cues, and submit the code we provide you for verification, all with the press of one button.</p>
				<p className = "titledesc"><span className = "dappdesc">dApps</span>: Choose from a wide variety of templates to begin designing your dApp. Drag and drop to customize it further. Launch your dApp within Cues and let us take care of your hosting needs. If you wish, you can take the code with you!</p>
		
			  </div>

			  <div className=" col-lg-4 py-3 wow fadeInUp">
				<div className="d-flex flex-row">
				  <div>
					<h2 className = "titlecol">Dynamic</h2>
				  </div>
				  <div className="thumbnail">
					  <Icon height = "50" icon="fa6-solid:group-arrows-rotate" />
				  </div>
				  
				</div>
				<p className = "titledesc"><span className = "smartcontractdesc">Smart Contracts</span>: Our smart contract generator provides you unparalleled customisability for the specifications and design of your smart contract This allows you to bring to life any contract you have in mind, without poring over the nitty-gritties of coding it out. Remove the risk of making mistakes.</p>
				<p className = "titledesc"><span className = "dappdesc">dApps</span>: Make your dApps stand out with dynamic content and user engagement not easily available on other no-code platforms. This includes accessing values and interactions with your smart contracts, which are all natively available via the platform. All without code or external plug-ins.</p>
			  </div>

			  <div className=" col-lg-4 py-3 wow fadeInUp">
				<div className="d-flex flex-row">
				  <div>
					<h2 className = "titlecol">Accurate</h2>
				  </div>
				  <div className="thumbnail">
					  <Icon height = "50" icon="charm:circle-tick" />
				  </div>
				  
				</div>
				<p className = "titledesc">Our code for Smart contracts and dApps have been extensively tested before release. </p>
				<p className = "titledesc"><span className = "smartcontractdesc">Smart Contracts</span>: Our internal checks ensure that all contract code produced is compilable before we release them. The code is well-commented so that the functions in the code.</p>
				<p className = "titledesc"><span className = "dappdesc">dApps</span>: The code we generate for you is the same code we use when we host your dApp on Cues, so your code produces exactly the same dApp as what you see on Cues. </p>
			  </div>
			</div>
		 </div> 
		
		
		
		<div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">Our Solutions</h2>
			</div>
			<div className="flexThis">
				{smartcontracttypes}
			</div>
		
		 </div> 
		
		<div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">The Process</h2>
			</div>
			<div className="row smartcontractprocessdiv mx-auto">
				<div className="col-md-6 my-auto">
					<img loading="lazy" className="smartcontractprocessimg" src={contract2} alt="CUES Smart Contract Preview" />	
				</div>
				<div className="col-md-6 smartcontractprocessoverall pt-3 my-auto">
					
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-10 text-center">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe title = 'Mint Smart Contract with CUES SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/VCZ31X_HT3M"}></iframe>
								</div>
							</div>
						</div>
					</div>
					<br/><br/>
					<span className = "smartcontractprocesstitle">For Smart Contracts</span><br/>
					<span className = "smartcontractprocesscolor"> <Icon width = "25" icon="ph:number-circle-one-bold"/>&nbsp;<b>Choose Your Contract:</b></span><span> Find the smart contract solution that best suits your needs.</span><br/><br/>
					<span className = "smartcontractprocesscolor"> <Icon width = "25" icon="ph:number-circle-two-bold"/>&nbsp;<b>Answer Easy Questions:</b></span><span> Fill up the fields in our form and we will turn it to a customized smart contract.</span><br/><br/>
					<span className = "smartcontractprocesscolor"> <Icon width = "25" icon="ph:number-circle-three-bold"/>&nbsp;<b>Deploy Instantaneously:</b></span><span> Deploy your smart contract using our system, or download the code as shown here and deploy it yourself.</span>
				</div>
			</div>
			<div className="row smartcontractprocessdiv mx-auto">
				<div className="col-md-6 my-auto order-md-2">
					<img loading="lazy" className="smartcontractprocessimg" src={dapp1} alt="CUES DApp Builder Preview" />	
				</div>
				<div className="col-md-6 smartcontractprocessoverall pt-3 my-auto order-md-1">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-10 text-center">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe title = 'DApp Builder Series with CUES SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/videoseries?list=PLAc4K9R1vVPQxZIGDam6X3MLMlbr2sOCT"}></iframe>
								</div>
							</div>
						</div>
					</div>
					<br/><br/>
					<span className = "smartcontractprocesstitle">For DApps</span><br/>
					<span className = "smartcontractprocesscolor"> <Icon width = "25" icon="ph:number-circle-one-bold"/>&nbsp;<b>Choose Your Template:</b></span><span> Find a template that best suits your needs and customise with our no-code dApp builder to create your dream dApp.</span><br/><br/>
					<span className = "smartcontractprocesscolor"> <Icon width = "25" icon="ph:number-circle-two-bold"/>&nbsp;<b>Interact with Smart Contract:</b></span><span> Add your smart contract address and incorporate your smart contract functions easily into the dApp with instructions provided.</span><br/><br/>
					<span className = "smartcontractprocesscolor"> <Icon width = "25" icon="ph:number-circle-three-bold"/>&nbsp;<b>Deploy Instantaneously:</b></span><span> Go live using our hosting platform, or download the dApp template code and deploy it yourself. Instructions given.</span>
				</div>
				

			</div>
		 </div>
		
		
		
		
		
		<div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">Supported Blockchains</h2>
			</div>
			<div className="row">
				{logoshow}	
			</div>
		 </div> 
		
		<div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">Testimonials</h2>
			</div>
			<div className="row d-flex justify-content-center">	
				<div className="col-9 col-md-8 col-lg-7">
					<div className="quote">
					  <blockquote>
						<p>Since I started to use Cues.sg, I have had excellent experience from their team. They are responsive, supportive and absolutely professional. They worked with me tirelessly every day to develop 3 complex dApps linked to 4 contracts. I will definitely recommend them hands down. They are the best I have worked with.</p>
						<cite>Taqniyat LLC</cite>
					  </blockquote>
					</div>
				</div>
				<div className="col-9 col-md-8 col-lg-7">
					<div className="quote">
					  <blockquote>
						<p>I found Cues through google because I wanted to learn how to build dapps. It was a bit confusing at the beginning, after some training and thanks to the help of the cues team, who was always available for questions, I was able to create some nice dapps.
						For devs who are looking for an easy way to create dapps, Cues is an absolute recommendation, the price is absolutely fair, the selection of smart contracts and dapp templates is constantly being expanded. 5 star rating for Cues.</p>
						<cite>COOLSHIB|Robert</cite>
					  </blockquote>
					</div>
				</div>
				<div className="col-9 col-md-8 col-lg-7">
					<div className="quote">
					  <blockquote>
						<p>Cues.sg是我人生中的贵人，那时我还是一个小白对于区块链很感兴趣，也很想学习开发技术但是苦于找不到方法。有一次我在谷歌搜索中发现了这个网站，然后他们的管理员不厌其烦的教我操作，慢慢的我发现这真是一个令人惊叹的网站。它不仅可以部署合约，而且还可以制作DAPP和游戏等网页，大大解放了我对区块链的想象，我很感激这个网站。希望大家都能来到Cues.sg开发学习，我敢拍着胸脯说，你一定会来感谢我的。</p>
						<cite>SuperMan</cite>
					  </blockquote>
					</div>
				</div>
				
			</div>
			
		 </div> 
		
		 <div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">Keep In Touch</h2>
			</div>
			<div className="row text-center ">
				<div className="col-12 col-sm-6 col-md-4 py-3 text-center">
					<div className="btn-solid-lgopposite" onClick={clickTwitter}> Follow us on Twitter <Icon width = "30" icon="akar-icons:twitter-fill" /> </div>
				</div>
				<div className="col-12 col-sm-6 col-md-4 py-3 text-center">
					<div className="btn-solid-lgopposite" onClick={clickTelegram}> Join us on Telegram <Icon width = "30" icon="cib:telegram-plane" /> </div>
					
				</div>
				<div className="col-12 col-sm-6 col-md-4 py-3 text-center">
					<div className="btn-solid-lgopposite" onClick={this.showModal}> Send us a mail <Icon width = "30" icon="fluent:mail-16-filled" /></div>
				</div>
				
			</div>
		 </div> 
		
		 <div className="container containerSpacing">
			<div className="row">
				<h2 className = "titlecol black">FAQ</h2>
			</div>
			<div className='row justify-content-center'>
				<div className=' col-10 col-md-9 col-lg-8'>
					<details className='detailThree'>
						<summary className="summaryThree">How Do I Try Out Cues?</summary>
						<div className="detailDivThree">
							We provide log ins via Google. <br/>For smart contracts, choose the testnet of the blockchain you want to deply on and fill in the form fields. <br/> For dApps, choose a template and edit it according to your needs. There will be a tutorial at the start and placeholder actions to guide you. We only charge for payment at the last step, so try as many times as you like!
						</div>
					</details>
					<details className='detailThree'>
						<summary className="summaryThree">How Do I Deploy Contracts?</summary>
						<div className="detailDivThree">
							We instantly deploy your contracts onto the blockchain for you. The IPFS process is done during the deployment stage and the IPFS link will automatically be included in your smart contract. This will require you to connect via your wallet. Alternatively, we provide the code and you may download and deploy it yourself. 
							<br/>
							Learn more with our <Link to="/guide" className = "guideLink"> <b>guide</b></Link>.
						</div>
					</details>
					<details className='detailThree'>
						<summary className="summaryThree">How Do I Integrate My Smart Contracts Into Cues?</summary>
						<div className="detailDivThree">
							You can add any existing contract via inputting your smart contract address when creating a new dApp. Multiple contracts can be added as long as they are all on the same blockchain. The functions in your smart contracts will then be made available when you are building your dApp.
							<br/>
							Learn more with our <Link to="/guide" className = "guideLink"> <b>guide</b></Link>.
						</div>
					</details>
		
		
				</div>
			</div>
		 </div> 
		 
		
		
		
		 <div>
			  <Modal show={this.state.show} handleClose={this.hideModal}>
				  <p>Modal</p>
				</Modal>
   		</div>
    </div>
  );
}}


class SecondHome extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){ 
		function clickSmartContract(){
			//<div className="btn-solid-lgopposite text-center" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Smart Contract </div> 
			//<Link to="/smartcontractform"><div className="btn-solid-lgopposite"><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Smart Contract </div> </Link>
			window.location.assign("https://www.cues.sg/contracts");
		}
		function clickDapp(){
			window.location.assign("https://www.cues.sg/dapps");
			//<div className="btn-solid-lg text-center" onClick={clickDapp}><Icon width = "30" icon="ant-design:rocket-outlined" /> Launch dApp </div> 
			//<Link to="/dappform"><div className="btn-solid-lg"><Icon width = "30" icon="ant-design:rocket-outlined" /> Launch dApp </div> </Link>
		}
		
		
		
		
    return (
		<div className="container">
    	<div className="row cardmargin">
				<div className="col-md-6 col-sm-13">
					<div className="card3">
					  <div className="productstitle">I need a smart contract</div>
					  <br/><br/>
					  <div className="btn-solid-lgopposite text-center" onClick={clickSmartContract}><Icon width = "30" icon="ion:rocket-outline" /> Start Smart Contract </div> 
					  
					</div>

				</div>

				<div className="col-md-6 col-sm-13">
					<div className="card1">
					  <div className="productstitle">I need a dApp for my smart contracts</div>
					  <br/><br/>
					  <div className="btn-solid-lg text-center" onClick={clickDapp}><Icon width = "30" icon="ion:rocket-outline" /> Launch dApp </div> 
					</div>

				</div>


		</div> 
		</div> 
		
		  	
  );
}}


class Home extends Component {
	constructor() {
    super();
    this.state = {
		page : 0,
    };
  }

  showPage = (page) => {
    this.setState({ page: page });
  };
	
	
	//<div className="bannerContainer" onClick={this.showModal}><div> Join our Referral Event from 9 Aug to 9 Sept!</div></div>  <Event show={this.state.show} handleClose={this.hideModal}> <p>Event</p></Event>
	render(){
		var home = <MainHome changePage = {this.showPage}/>
		if (this.state.page ===1){
			home = <SecondHome changePage = {this.showPage}/>
			
		}
		
		
		
    return (
	 <div className="bodymain">
		
		 
		<MainNavbar/>
		 <div className="bannerContainer" onClick={this.showModal}><div> Interested about launching an NFT program on Solana? Reach out to us for assistance! </div></div>  <Event show={this.state.show} handleClose={this.hideModal}> <p>Event</p></Event>
		 {home}
		
		<Footer />
		<div>
		</div>
	
		
    </div>
  );
}}

export default Home;