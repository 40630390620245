// App.js
import React, {Component} from 'react';
import {Link } from "react-router-dom";
import logo from './title1.png';
import { Icon } from '@iconify/react';
import './css/bootstrap.css';
import './css/home.css';
import {Modal} from './modal.js';



class Navbar extends Component {
	
	
	render(){ 
		function clickHome(){
			window.location.assign("https://www.cues.sg/");
		}

    return (
	 <div >
	   
	 <nav className="navbar navbar-custom">
            <span className="logo-image" onClick={clickHome} ><img src={logo} alt="alternative"></img> </span> 
			<div >
				<button type="button" className="btn btn-solid-lg"> <Icon width = "20" icon="bx:wallet" />  Connect To Wallet  </button>
			</div>
    </nav> 
   	</div>
  );
}}

class MainNavbar extends Component {
	  constructor(props) {
		super(props);
		this.state = {
		  menu: false,
		  show: false,
		};
		
	  }

	  toggleMenu= () => {
		this.setState({ menu: !this.state.menu })
	  }
	
	  showModal = () => {
		this.setState({ show: true });
	  };

	  hideModal = () => {
		this.setState({ show: false });
	  };

	render(){ 
		function clickTelegram(){
			window.location.assign('https://t.me/+yK9fP_1z27Q2NzVl');
		}
		
		function clickHome(){
			window.location.assign("https://www.cues.sg/");
		}
		const show = (this.state.menu) ? "show" : "" ;

    return (
		
	 <div >
	 <nav className="navbar navbar-expand-lg navbar-custom">
            <span className="logo-image" onClick={clickHome}><img src={logo} alt="Cues Logo"></img></span>
		
			<button className="navbar-toggler" type="button" onClick={ this.toggleMenu }>
				<span className="navbar-toggler-icon"><Icon width = "30" icon="eva:menu-outline" /></span>
			</button>
		
            <div className={"collapse navbar-collapse " + show}>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle page-scroll" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Solutions</span>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<Link to="/product-smartcontract" className="dropdown-item"><span className="item-text">Smart Contract</span></Link>
                            <div className="dropdown-divider"></div>
							<Link to="/product-dapp" className="dropdown-item"><span className="item-text">dApp</span></Link>
                        </div>
                    </li>
					<li className="nav-item">
						<Link to="/pricing" className="nav-link page-scroll">Pricing</Link>
                    </li>
					<li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle page-scroll" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Gallery</span>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<Link to="/gallery" className="dropdown-item"><span className="item-text">Templates</span></Link>
                            <div className="dropdown-divider"></div>
							<Link to="/user-gallery" className="dropdown-item"><span className="item-text">User Works</span></Link>
                        </div>
                    </li>
					<li className="nav-item">
						<Link to="/guide" className="nav-link page-scroll">Guide</Link>
                    </li>
					<li className="nav-item">
						<span onClick={this.showModal} className="nav-link page-scroll">Feedback</span>
                    </li>
					<li className="nav-item">
						<span className="nav-link page-scroll" onClick={clickTelegram}><Icon width = "20" icon="cib:telegram-plane" /> </span>
						
                    </li>
		
					
                   
                </ul>
				
            </div>
			
			
    </nav> 
	<div>
		  <Modal show={this.state.show} handleClose={this.hideModal}>
			  <p>Modal</p>
			</Modal>
		</div>
   	</div>
  );
}}

export {Navbar};
export {MainNavbar};