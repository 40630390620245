import React from 'react';
import type {Node} from 'react';

import CoinTest6723 from './Coin_Test6723.json';
import '@rainbow-me/rainbowkit/styles.css';
import {getDefaultWallets, RainbowKitProvider,} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import {fantomTestnet, polygonMumbai, mainnet, polygon, optimism, arbitrum, zora,} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura'
import { useState, useEffect } from "react";
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { usePrepareContractWrite, useContractRead, useContractWrite, useWaitForTransaction, useContractReads, useAccount } from 'wagmi';

import { InjectedConnector } from 'wagmi/connectors/injected'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'


import { parseUnits, formatUnits, parseEther } from 'viem'

var numberConfirmed;

const delay = ms => new Promise(res => setTimeout(res, ms));

export const ConnectingButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button style={{fontFamily:'Righteous', borderWidth:1, borderStyle:'solid', backgroundColor:'rgb(255, 103, 103)', cursor:'pointer'}} className='btn col-2 ml-0 mr-1' onClick={openConnectModal} type="button">
                    Connect Wallet
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button style={{fontFamily:'Righteous', borderWidth:1, borderStyle:'solid', backgroundColor:'rgb(255, 103, 103)', cursor:'pointer'}} className='btn   col-2    ml-0  mr-1' onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                 

                  <button style={{fontFamily:'Righteous', borderWidth:1, borderStyle:'solid', backgroundColor:'rgb(255, 103, 103)', cursor:'pointer'}} className='btn   col-2    ml-0  mr-1' onClick={openAccountModal} type="button">
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    polygonMumbai
  ],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'Cues',
  projectId: '5117fc7203ae7d90e1941f473786e326',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});


const Main = (): Node => {
	const [listValue,setListValue] = useState(["0","0","0","0","0","0"])
    const {address,isConnecting,isDisconnected,isConnected} = useAccount()
    const  [successStatus,setsuccessStatus] = useState(false)
    
	const coinTest6723Contract= {
	  address: '0xdB829Be222872adf77FCfc3e09525Aa3E091a98B',
	  abi: CoinTest6723.abi,
	}
	const coinTest7723Contract= {
	  address: '0x01b1556601a9862dFAC900Ba7EcBd0AFD218E8AA',
	  abi: CoinTest6723.abi,
	}

	const { data:listValueTemp, refetch: refetchProject, isError, isLoading } = useContractReads({
		contracts: [
		  {
			...coinTest6723Contract,
			functionName: 'name',
		  },
		  {
			...coinTest6723Contract,
			functionName: 'symbol',
		  },
		  {
			...coinTest6723Contract,
			functionName: 'balanceOf',
			args: ['0xB3d4a3b65B8f68938De1b0fa536FFE0b4665d995'],
		  },
		  {
			...coinTest7723Contract,
			functionName: 'name',
		  },
		  {
			...coinTest7723Contract,
			functionName: 'symbol',
		  },
		  {
			...coinTest7723Contract,
			functionName: 'balanceOf',
			args: ['0xB3d4a3b65B8f68938De1b0fa536FFE0b4665d995'],
		  },
		],
		onSuccess(data) {
			  setListValue([data[0].result, data[1].result, parseFloat(formatUnits(data[2].result,18)).toFixed(2), data[3].result, data[4].result, parseFloat(formatUnits(data[5].result,18)).toFixed(2)])
			  setsuccessStatus(true);
		 },
	  })

	const { data:dataWrite1, isLoading:isLoadingWrite1, isSuccess:isSuccessWrite1, write: write1 } = useContractWrite({
    	address: '0xdB829Be222872adf77FCfc3e09525Aa3E091a98B',
    	abi: CoinTest6723.abi,
  		})

	const { data:dataWrite2, isLoading:isLoadingWrite2, isSuccess:isSuccessWrite2, write: write2 } = useContractWrite({
    	address: '0x01b1556601a9862dFAC900Ba7EcBd0AFD218E8AA',
    	abi: CoinTest6723.abi,
  		})

	

	useEffect(()=>{
		if((isSuccessWrite1 ||isSuccessWrite2) && successStatus === false) {
			setTimeout(function () {
				refetchProject?.();
			}, 30000);
			setsuccessStatus(true);
		}
		if (isConnecting) {
			refetchProject?.();
		}
	});
	console.log(polygonMumbai)

	function nav_c5_91667249(){
		return (
		<nav className='navbar  navbar-light' style={{backgroundColor:'rgb(255, 255, 255)'}}>
			<span className='navbar-brand'><img src={'https://www.cues.sg/client_pictures/108_jGMeTdU4.png'} style={{width:'100px'}} alt='logo' /><span ></span></span>
			
			<ConnectingButton />
		</nav>)
	}	
	
	
	

	return <div style={{backgroundColor:'rgb(255,255,255)'}}>{nav_c5_91667249()}
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12  mt-3  mb-3  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Token
					</div>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Name: 
						<span key={1}>{listValue[0]}</span>
					</div>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Symbol: 
						<span key={1}>{listValue[1]}</span>
					</div>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Balance Of: 
						<span key={1}>{listValue[2]}</span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<button style={{cursor:'pointer'}} className='btn btn-primary col-4' onClick={() =>
					  { write1({
						functionName: 'ownerMint',
						args: [parseUnits('20',18)],
					  });
						setsuccessStatus(false);}
					  } >OwnerMint</button>

					<div className='  col-2    '/>
					<button style={{cursor:'pointer'}} className='btn btn-primary    col-4  mt-2   '  onClick={() =>
						{write1({
							functionName: 'mint',
							args: [parseUnits('1',18)],
							value: parseEther('0.2'),
						  });
						  setsuccessStatus(false);}
						} >{'Mint'}</button>
					
				</div></div>
			</div></div>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12  mt-3  mb-3  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Different Coin with same name
					</div>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Name: 
						<span key={1}>{listValue[3]}</span>
					</div>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Symbol: 
						<span key={1}>{listValue[4]}</span>
					</div>
					<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Balance Of: 
						<span key={1}>{listValue[5]}</span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<button style={{cursor:'pointer'}} className='btn btn-primary col-4' onClick={() =>
					  { write2({
						functionName: 'ownerMint',
						args: [parseUnits('20',18)],
					  });
					
						setsuccessStatus(false);}
					  }>OwnerMint</button>
					<div className='  col-2    '/>
					<button style={{cursor:'pointer'}} className='btn btn-primary    col-4  mt-2   '  onClick={() =>
					  { write2({
						functionName: 'mint',
						args: [parseUnits('1',18)],
						value: parseEther('0.2'),
					  });
						setsuccessStatus(false);}
					  } >{'Mint'}</button>
					
				</div></div>
			</div></div>
		</div></div>
}


const Test = () => {
  return (
	<React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
		<Main/>
		<div>
		By default, RainbowKit will connect to the first chain. They will have to get a walletconnect id to replace the project id that is currently there (sign up at https://cloud.walletconnect.com/sign-in). This code also works on gooogle chrome mobile and the read data refreshes for each transaction. Only issue is that this link https://github.com/hirosystems/stacks.js/issues/1096#issuecomment-946350299 is needed for build version to work. 
		I am hoping publicprovider is now sufficient because I limited the amount of time usecontractreads is called so hopefully this works. For now, we can try to do without asking people to sign up for alchemyprovider and infuraprovider. 
		Ignore the ownermint button, it is so that I can keep testing without wasting too much test tokens. Can change the account for the balances to yours, I only fixed it to mine to see if it was working from time to time. 
		Can use useContractReads to read functions from all the different contracts and then manipulate the results upon success. Unfortunately the writing part does not have an equivalent which is why there will be as many useContractWrite for as many contracts, though can call the individual functions within the onclick. Everytime a user does something like connect wallet or writing function etc, the data should refresh via the useEffect part.
		
		</div>
      </RainbowKitProvider>
    </WagmiConfig>
	</React.StrictMode>
  );
};

export {Test};