// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js'

class DAppPricing extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		//<span className="pricing-price">25 Credits</span>
		//<Icon width = "80" icon="emojione-monotone:kick-scooter" hFlip={true} />
		//<Icon width = "80" icon="emojione-monotone:bicycle" hFlip={true} />
		//<Icon width = "80" icon="emojione-monotone:racing-car" />
		//<Icon width = "80" icon="emojione-monotone:small-airplane" hFlip={true} />
		
    return (
		  <div className="container">		   
			<div className="panel pricing-table">

			  <div className="pricing-plan">
				<div className="pricing-img">
					<div className="pricing-mainheader">10</div>
				</div>
				<h3 className="pricing-header">USD</h3>
				<div className="pricing-features">
				  <div className="pricing-features-item">30 Days</div>
				</div>
				
			  </div>
			  <div className="pricing-plan">
				<div className="pricing-img">
					<div className="pricing-mainheader">20</div>
				</div>
				<h3 className="pricing-header">USD</h3>
				<div className="pricing-features">
				  <div className="pricing-features-item">60 Days</div>
				  <div className="pricing-features-item">Dedicated Support</div>
				</div>
				
			  </div>

			  <div className="pricing-plan">
				<div className="pricing-img">
					<div className="pricing-mainheader">28</div>
				</div>
				<h3 className="pricing-header">USD</h3>
				<div className="pricing-features">
				  <div className="pricing-features-item">90 Days</div>
				  <div className="pricing-features-item">Dedicated Support</div>
				  <div className="pricing-features-item">Custom Page Name</div>
				</div>
				
			  </div>
		

			  <div className="pricing-plan">
				<div className="pricing-img">
					<div className="pricing-mainheader">50</div>
				</div>
				<h3 className="pricing-header">USD</h3>
				<div className="pricing-features">
				  <div className="pricing-features-item">180 Days</div>
				  <div className="pricing-features-item">Dedicated Support</div>
				  <div className="pricing-features-item">Custom Page Name</div>
				</div>
				
			  </div>

			</div>
			<br/>
			<div className="pricingtext text-center">We charge an additional US$20 for each page of the dApp if users want to download the dApp code. No other costs involved.</div>
		  </div>
		
		  	
  );
}}

class SmartContractPricing extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		//<span className="pricing-price">25 Credits</span>
		
    return (
		  <div className="container">	
			<br/>
			<div className="pricingtext">Flat rate of US$20, instant deployment to blockchain of choice. Excludes gas costs.</div>
			<br/>
			<br/>
			<br/>
			<br/>
		  </div>
		
		  	
  );
}}


class Pricing extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0
     
    };
  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		var sliderstyle = "slider slidertab0";
		var itemshown;
		if (this.state.showtab ===0){
			itemshown =  <SmartContractPricing/>;		
		} else {
			sliderstyle = "slider slidertab1";
			itemshown = <DAppPricing/>;
		}
		var tabmenu = [];
		var tabitems = ["Smart Contracts", "DApps"]
		for (var i = 0; i < tabitems.length; i++) {
			let style = ""
			if (i===this.state.showtab) {
				style = "pricingtabchosen"
			}
			tabmenu.push(<li id = {i} onClick={(e) => this.showThisTab(e)} className = {style}><label><span id = {i} onClick={(e) => this.showThisTab(e)}>{tabitems[i]}</span></label></li>
		)
			
		}
		
		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="col-md-12 text-center">
				<h2 className = "pricingtitlesmall">The only <br/> <span className = "pricingtitle">Smart Contract and DApp builder</span><br/> that is powerful and easy to use</h2>
				<h4 className = "pricingsub">Our platform offers differentiated plans for different needs, and we are designed to grow with you.</h4>
			</div>
			<br/><br/>
			<div className="pricingtabs">
			  <ul>
				{tabmenu}
			  </ul>
  
			  <div className={sliderstyle}>
					<div className="indicator"></div>
			  </div>
			  </div>
			  <br/>
			  {itemshown}
			  <br/>
			  <br/>
		
			  
		</div>
		<Footer />
		</div>
		
		  	
  );
}}


export {Pricing};
