import React, {useState} from 'react';
import './css/modal.css';
import * as ServerComms from './ServerComms';
import './css/home.css';
import { Icon } from '@iconify/react';


const Event = ({ handleClose, show, children }) => {
  const showHideclassNameName = show ? "modal display-block" : "modal display-none";

  const [elaboration, setEleboration] = useState('');
  const [email, setEmail] = useState("");

    function onElaborate(e){
      setEleboration(e.target.value);
    }
    function onEmail(e){
      setEmail(e.target.value);
    }
	
	function clickLink(){
      window.location.assign('https://t.me/+yK9fP_1z27Q2NzVl');
    }
	
    function clickSubmit(e){
      var postFunction = function(responseIn){
        setEleboration("");
        setEmail("");
      }

      var failFunction = function(){
        console.log("Fail");
      }

      var email1 = email.trim();
      var elaboration1 = elaboration.trim();

      if (email1.length === 0 || elaboration1.length === 0){
        window.alert("Empty fields not allowed");
        return;
      }

      var passObject = {
          u:'submitElab',
          elaboration:elaboration1,
          email:email1,
          selectedTab:-1,
        };
        
     ServerComms.fetchSomething(passObject, postFunction, failFunction, [], 1);             
   }

//
  return (
    <div className={showHideclassNameName}>
      <div className="container contact-form">
            <div className="contact-image">
	  			<div>
	  			<Icon height = "80" icon="material-symbols:celebration-rounded" />
	  
	  			</div>
	  
            </div>
	  		<div className="buttonclose" onClick={handleClose}>
	  			<Icon height = "30" icon="eva:close-fill" />
	  
	  		</div>
	  
	  		<div className="content">
	  			<br/>
                <h3>Referral Event to celebrate National Day for Singapore</h3>
               <div className="row">
                    <div className="col-md-12">
                        <b>Competition Prizes</b>:<br/>
							1st Place: 200 Credits<br/>
							2nd Place: 100 Credits<br/>
							3rd Place: 50 Credits<br/><br/>

							<b>How to Participate</b>:<br/>
							1. Share your referral link with friends.<br/>
							2. The top 3 users with the most referred sign-ups who create and deploy at least one Smart Contract or DApp will win the prizes!<br/>
							3. The competition runs from 9 August 00:00 (GMT+8) to 9 September 00:00 (GMT+8).
                            <br/><br/>
                    </div>
                </div>
	  		</div>
</div>
    </div>
  );
};


const Modal = ({ handleClose, show, children }) => {
  const showHideclassNameName = show ? "modal display-block" : "modal display-none";

  const [elaboration, setEleboration] = useState('');
  const [email, setEmail] = useState("");

    function onElaborate(e){
      setEleboration(e.target.value);
    }
    function onEmail(e){
      setEmail(e.target.value);
    }
	
	function clickLink(){
      window.location.assign('https://t.me/+yK9fP_1z27Q2NzVl');
    }
	
    function clickSubmit(e){
      var postFunction = function(responseIn){
        setEleboration("");
        setEmail("");
      }

      var failFunction = function(){
        console.log("Fail");
      }

      var email1 = email.trim();
      var elaboration1 = elaboration.trim();

      if (email1.length === 0 || elaboration1.length === 0){
        window.alert("Empty fields not allowed");
        return;
      }

      var passObject = {
          u:'submitElab',
          elaboration:elaboration1,
          email:email1,
          selectedTab:-1,
        };
        
     ServerComms.fetchSomething(passObject, postFunction, failFunction, [], 1);             
   }

//
  return (
    <div className={showHideclassNameName}>
      <div className="container contact-form">
            <div className="contact-image">
	  			<div>
	  			<Icon height = "80" icon="ant-design:message-outlined" />
	  
	  			</div>
	  
            </div>
	  		<div className="buttonclose" onClick={handleClose}>
	  			<Icon height = "30" icon="eva:close-fill" />
	  
	  		</div>
	  
	  		<div className="content">
            <form method="post">
                <h3>Feedback/Suggestions</h3>
	  			<h5>Please contact us via Telegram @cuessg and we will get back to you as soon as possible.

Alternatively, you can use the following form or ask in our community chat by clicking <span style={{textDecoration: "underline"}} onClick={clickLink}>here</span>.</h5>
               <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" name="txtEmail" className="form-control" placeholder="Your Email *" value={email} onChange={onEmail} />
                        </div>
                        <div className="form-group">
	  						<div className="form-group">
                            <textarea name="txtMsg" className="form-control" placeholder="All suggestions on additional features, bugs detected etc. are most welcomed!" style={{width: '100%', height: '5rem'}} value={elaboration} onChange={onElaborate}></textarea>
                        </div>
                            
                        </div>
                    </div>
                    <div className="col-md-6">
	  					 <div className="btn-solid-lg btnContact" onClick={clickSubmit}><Icon width = "30" icon="carbon:send-alt" /> Send Message </div> 
	  
                    </div>
	  
                </div>
            </form>
	  		</div>
</div>
    </div>
  );
};

const Attribute = ({ handleClose, show, children }) => {
  const showHideclassNameName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideclassNameName}>
      <div className="container contact-form">
            <div className="contact-image">
	  			<div>
	  			<Icon height = "80" icon="ooui:references-rtl" />
	  
	  			</div>
	  
            </div>
	  		<div className="buttonclose" onClick={handleClose}>
	  			<Icon height = "30" icon="eva:close-fill" />
	  
	  		</div>
	  
	  		<div className="content">
            <form method="post">
                <h3>Attributes</h3>
	  			<span>Upload Icon made from Icon Fonts is licensed by CC BY 3.0.</span>
	  			
            </form>
	  		</div>
</div>
    </div>
  );
};

const WarningWallet = ({ handleClose, show, changeTab, children }) => {
  const showHideclassNameName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideclassNameName}>
      <div className="container warning-form">
	  
	  			
	  			<div className="text-center blue">
	  				<Icon height = "180" icon="ri:alarm-warning-fill" />
	  			</div>
	  			<br/>
	  			<div className="row">
					<div className="col-md-12 text-center">
						You will only be able to save your work, and launch a Dapp, when you connect to your Wallet. Click Proceed if you would like to continue for a preview without signing in.
					</div>
	  			</div>
	  			<br/>
				<div className="col-md-12 text-center">
					<button type="button" className="btn btn-solid-lg btn-removemargin" onClick = {changeTab}>  Proceed  </button>
				</div>
				<div className="col-md-12 text-center">
					<span className="cursorpoint iconfill" style = {{textDecoration:"underline"}} onClick={handleClose}>Cancel</span>
				</div>
	  			
	  		</div>
    </div>
  );
};


const PhotoZoom = ({ handleClose, show, children }) => {
  const showHideclassNameName = show ? "modal2 display-block" : "modal2 display-none";

  return (
     <div className={showHideclassNameName}>
			<div className="container imageForm text-center">
					<img alt = "" className="imgContainer" src = {children}  />
	  		</div>
	  		<Icon onClick={handleClose} className="closeButton"  color="white"  height = "30" icon="eva:close-fill" />
	  </div>
  );
};


export {Modal};
export {Attribute};
export {WarningWallet};
export {PhotoZoom};
export {Event};
