import React from 'react';
import type {Node} from 'react';

import Coin_BNBTestingCoin from './Coin_BNBTestingCoin.json';
import Staking from './Staking.json';
import Exchange from './Exchange.json';
import '@rainbow-me/rainbowkit/styles.css';
import {getDefaultWallets, RainbowKitProvider,} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import {bsc, bscTestnet} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura'
import { useState, useEffect } from "react";
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { usePrepareContractWrite, useContractRead, useContractWrite, useWaitForTransaction, useContractReads, useAccount , useContractEvent} from 'wagmi';
import { getAccount, watchContractEvent, readContract } from '@wagmi/core'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { parseUnits, formatUnits, parseEther } from 'viem'
/* global BigInt */
var numberConfirmed;

const delay = ms => new Promise(res => setTimeout(res, ms));

export const ConnectingButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button style={{fontFamily:'Righteous', borderWidth:1, borderStyle:'solid', backgroundColor:'rgb(255, 103, 103)', cursor:'pointer'}} className='btn col-2 ml-0 mr-1' onClick={openConnectModal} type="button">
                    Connect Wallet
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button style={{fontFamily:'Righteous', borderWidth:1, borderStyle:'solid', backgroundColor:'rgb(255, 103, 103)', cursor:'pointer'}} className='btn   col-2    ml-0  mr-1' onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                 

                  <button style={{fontFamily:'Righteous', borderWidth:1, borderStyle:'solid', backgroundColor:'rgb(255, 103, 103)', cursor:'pointer'}} className='btn   col-2    ml-0  mr-1' onClick={openAccountModal} type="button">
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    bsc
  ],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'Cues',
  projectId: '5117fc7203ae7d90e1941f473786e326',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});


const Main = (): Node => {
	const [listValue,setListValue] = useState(["0","0","0"])
    const {address,isConnecting,isDisconnected,isConnected} = useAccount()
    const  [successStatus,setsuccessStatus] = useState(false)
	const  [currentAction,setcurrentAction] = useState("")
    
	const exchangeContract= {
	  address: '0x68043947E879c448492fb531d7Bf9Db517D1E698',
	  abi: Exchange.abi,
	}
	
	
	const { data:listValueTemp, refetch: refetchProject, isError, isLoading } = useContractReads({
		contracts: [
		  {
			...exchangeContract,
			functionName: 'tax1To2rate',
		  },
		  {
			...exchangeContract,
			functionName: 'resultantAmtFromExchanging1To2',
			args: [parseUnits('10',18)],
		  },
		  {
			...exchangeContract,
			functionName: 'baselineExchangeRate1To2',
			args: [parseUnits('10',18)],
		  },
		],
		onSuccess(data) {
			  
			  console.log(data)
			  const result = data.map(({ result }) => result)
			  if (!result.includes(undefined)) {
				console.log(formatUnits(data[0].result,0))
				var testing = (1000000-formatUnits(data[0].result,0))/1000000;
				console.log(parseUnits(((1000000-10000)/1000000).toString(),0))
				var testing2 = parseUnits(testing.toString(),0)
				var testing3 = testing2
				console.log(testing3)
				setListValue([parseFloat(formatUnits(data[0].result,4)).toFixed(2), parseFloat(formatUnits(data[1].result,18)).toFixed(2), parseFloat(formatUnits(data[2].result,18)).toFixed(2)])
				setsuccessStatus(true);
			  }
		 },
	  })

	function nav_c5_91667249(){
		return (
		<nav className='navbar  navbar-light' style={{backgroundColor:'rgb(255, 255, 255)'}}>
			<span className='navbar-brand'><img src={'https://www.cues.sg/client_pictures/108_jGMeTdU4.png'} style={{width:'100px'}} alt='logo' /><span ></span></span>
			
			<ConnectingButton />
		</nav>)
	}	
	
	
	

	return <div style={{backgroundColor:'rgb(255,255,255)'}}>{nav_c5_91667249()}
				<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
					<div className=' '>
						<div style={{backgroundColor:''}} className='      container-fluid'>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
								<div className='  col-12  mt-3  mb-3  '/>
							</div>
						</div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Token
							</div>
							<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Name: 
								<span key={1}>{listValue[0]}</span>
							</div>
							<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Symbol: 
								<span key={1}>{listValue[1]}</span>
							</div>
							<div className='  col-7      text-left' style={{overflowWrap:false, borderWidth:0, padding:10}}>Balance Of: 
								<span key={1}>{listValue[2]}</span>
							</div>
						</div>
					</div>
				</div>
			</div></div></div>
}


const Test3 = () => {
  return (
	<React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
		<Main/>
		<div>
		Binance works here. Contract 1 is the token contract and 2 is the staking contract. Ignore the 'Transfer' button. But if you want to test, it is easiest to duplicate and deploy those contracts. Else, you can mint, then try to stake also can but Binance faucet is super stingy lol.<br/>
		For each button where we need to detect an event, we have to setcurrentAction first so that we can determine where it comes from after event is detected before doing another write (e.g. staking or transfer). The unwatch is so that we stop listening to event. Or else, if we do a transfer, then a stake where both needs approval, the stake on-click will cause the transfer to pop up again somehow. Once done, the currentAction has to be set to an empty string so that it does not trigger again. I think this is the most optimal way for combining contract writes and events but will see if can further improve. 
		</div>
      </RainbowKitProvider>
    </WagmiConfig>
	</React.StrictMode>
  );
};

export {Test3};