// App.js
import React from 'react';
import { Routes, Route } from "react-router-dom";
import TagManager from 'react-gtm-module'

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './js/home.js';

//import Templateone from './js/templateone.js';
//import Templatetwo from './js/templatetwo.js';
//import Templatethree from './js/templatethree.js';
//import Dappform from './js/dappform.js';
//import Smartcontractform from './js/smartcontractform.js';
import {DappProduct, SmartContractProduct} from './js/product.js';
import {Pricing} from './js/pricing.js';
import {Guide} from './js/guide.js';
import {Gallery} from './js/gallery.js';
import {UserGallery} from './js/usergallery.js';
import {Test} from  './js/test.js';
import {Test2} from  './js/test2.js';
import {Test3} from  './js/test3.js';
import {Test4} from  './js/test4.js';
import {Test5} from  './js/test5.js';


//<Route path="pain" element={<Test />} />
const tagManagerArgs = {
  gtmId: 'GTM-NZ6JH66'
}
TagManager.initialize(tagManagerArgs)

function App() {
	var guideList = ["About Us","Adding Credits","What is Blockchain", "What is a Smart Contract", "What is a DApp", "Why have a Minting Contract","Why have a Staking Contract", "Why have an Exchange Contract", "Why have a Lottery/Jackpot Contract", "Why have an Airdrop Contract", "Why have a Voting Contract", "Basic Form Notes", "How to Deploy Contract","How to Mint a Coin", "How to Mint a NFT", "How to Create a Swap Contract", "How to Create a Staking Contract", "How to Reward NFT Holders", "How to Create an Escrow Contract", "How to Create a Voting Contract", "How to Split Ownership of NFT", "DApp Process", "DApp Builder-Functions Overview", "DApp Builder-How to Use Actions", "DApp Builder-Examples of Actions", "How to Deploy DApp", "Commonly Asked Questions (Videos)","Types", "Containers and Rows"];
	var guideroutelist = [];
	for (var i = 0; i < guideList.length; i++) {
		var linkname = 'guide/'+guideList[i].replace(/\s+/g, '-').toLowerCase();
		guideroutelist.push(<Route path={linkname} element={<Guide tabnum = {i}/>} />
		)
	}
  return (
	  <div>
	  <Routes>
	  <Route path="/" element={<Home />} />
	  <Route path="product-dapp" element={<DappProduct />} />
	  <Route path="product-smartcontract" element={<SmartContractProduct />} />
	  <Route path="pricing" element={<Pricing />} />
	  <Route path="guide" element={<Guide />} />
	  <Route path="gallery" element={<Gallery />} />
	  <Route path="user-gallery" element={<UserGallery />} />
	  <Route path="pain" element={<Test />} />
	  <Route path="paintest" element={<Test2 />} />
	  <Route path="paintest2" element={<Test3 />} />
	   <Route path="paintest3" element={<Test4 />} />
	  <Route path="paintest4" element={<Test5 />} />
	  {guideroutelist}
	  
	  
	  
	  
	  </Routes>
	  </div>
	  
	  
  );
}
//<Home />
//    <div>
//      <Routes>
//        <Route path="/" element={<Home />} />
//        
//	    <Route path="dappform" element={<Dappform />} />
//	    <Route path="smartcontractform" element={<Smartcontractform />} />
//      </Routes>
//    </div>

export default App;