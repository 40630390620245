// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js'
import step1 from './img/demo/dapp/1.png';
import step2 from './img/demo/dapp/2.png';
import step3 from './img/demo/dapp/3.png';
import step4 from './img/demo/dapp/4.png';
import step5 from './img/demo/dapp/5.png';
import step6 from './img/demo/dapp/6.png';

import smartstep1 from './img/demo/smartcontract/1.png';
import smartstep2 from './img/demo/smartcontract/2.png';
import smartstep3 from './img/demo/smartcontract/3.jpeg';

class DappProduct extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		//var stepslist = [["1", step1, "Choose the blockchain your smart contract resides on."], ["2", step2, "Fill in your smart contract address(es) to incorporate your smart contract."], ["3a", step3a, "Use our available templates OR start with a blank screen."],["3b", step3, "Customize your dApp with our dApp builder."], ["4", step4, "Preview your dApp."], ["5", step5, "Click to launch your dApp."]];
		
		
		var stepslist = [["1", step1, "Choose from our available templates OR start with a blank screen."], ["2", step2, "Customize your dApp with our dApp builder. Add elements based on your needs."], ["3", step3, "Choose the blockchain your smart contract resides on. Add actions based on your smart contract (e.g. Mint, Add to Whitelist). The smart contract does not need to be generated from our website, but it has to already be deployed."], ["4", step4, "Preview your dApp on different screen sizes."], ["5", step5, "Choose the appropriate plan to launch your dApp."], ["6", step6, "Your dApp is ready! See https://www.cues.sg/dapps/example as an example of a launched dApp."]];
		var stepsitems = [];
		for (var i = 0; i < stepslist.length; i++) {
			stepsitems.push(
						<li>
						<span></span>
						<div className="text-center white">
							<img className="productstepimage" src={stepslist[i][1]} alt="alternative"></img>
						</div>

						<div className="info">{stepslist[i][2]}</div>
						<div className="time">
							<span>Step {stepslist[i][0]}</span>
						</div>
					</li>
							  );
		}
		
		
    return (
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="col-md-12 text-center">
				<h2 className = "titlecol">Steps to Create your dApp</h2>
			</div>
			<br/>
			<br/>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-10 col-md-6 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = "Create dApp" className="embed-responsive-item" src = {"https://www.youtube.com/embed/jKJ6xszobVo"}></iframe>
						</div>
					</div>
				</div>
			</div>
		
			<div className="box">
				<ul>
					{stepsitems}
				</ul>


			</div>
			<br/>
			<br/>
			<Footer />
</div>
		
		  	
  );
}}

class SmartContractProduct extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		var stepslist = [[smartstep1, "Choose the contract type you need."], [smartstep2, "Customize your options. Hover about the question mark for more information."], [smartstep3, "Click 'Deploy' to launch your smart contract."]];
		var stepsitems = [];
		for (var i = 0; i < stepslist.length; i++) {
			stepsitems.push(<li>
						<span></span>
						<div className="text-center white">
							<img className="productstepimage" src={stepslist[i][0]} alt="alternative"></img>
						</div>

						<div className="info">{stepslist[i][1]}</div>
						<div className="time">
							<span>Step {i+1}</span>
						</div>
					</li>
							  );
		}
		
		
    return (
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="col-md-12 text-center">
				<h2 className = "titlecol">Steps to Create your Smart Contract</h2>
			</div>
			<br/>
			<br/>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-10 col-md-6 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = "Create smart contract" className="embed-responsive-item" src = {"https://www.youtube.com/embed/VCZ31X_HT3M"}></iframe>
						</div>
					</div>
				</div>
			</div>
		    			
		
			<div className="box">
				<ul>
					{stepsitems}
				</ul>
			</div>
			<br/>
			<br/>
			<Footer />
</div>
		
		  	
  );
}}



export {DappProduct};
export {SmartContractProduct};