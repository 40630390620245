// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';
import {PhotoZoom} from './modal.js';


import user1 from './img/userdapps/user1.png';
import user2 from './img/userdapps/user2.png';
import user3 from './img/userdapps/user3.png';
import user4 from './img/userdapps/user4.png';
import user5 from './img/userdapps/user5.png';



class UserGallery extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0,
		show: false,
		img: false
     
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };

	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		
		var detailist = [[user1, "Staking DApp"], [user2, "Mobile Game DApp"], [user3, "NFT & Staking DApp"],
						[user4, "Staking DApp"], [user5, "Minting & Staking DApp"]];
		var usergallery = [];
		for (var i = 0; i < detailist.length; i++) {
			usergallery.push(<div className="col-12 col-md-6">
									<div className="usercard">
							 			<div className="usercardimageholder">
							 				<img loading="lazy" className="usergallerypic" src={detailist[i][0]} onClick={(e) => this.showModal(e)} alt={detailist[i][1]}></img>
										</div>
										<div className="usercardbody">
										  <h4> {detailist[i][1]} </h4>
										</div>
									 </div>
						 
								</div>
					  )
		}
		
		


		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="col-md-12 text-center">
				<h2 className = "pricingtitlesmall pricingtitle">User Galllery</h2>
				<h4 className = "pricingsub">Here are a few impressive dApps that have been developed by our users. Please feel free to click on them to see the full image.</h4>
			</div>
			<br/><br/>
			<div className="container">
				<div className="row"> {usergallery}</div>
			</div> 
			  
		</div>
		<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   		</div>
		<Footer />
		</div>
		
		  	
  );
}}


export {UserGallery};
