import 'whatwg-fetch';

export function fetchSomething(passObject, postFunction, failFunction, filesSetArray){
      try{
        passObject.fn = 'introPage';
        var data = new FormData();
        data.append('passObject',  JSON.stringify(passObject));
        if (!filesSetArray){
          filesSetArray = [];
        }
        for (var i in filesSetArray){
          data.append('files'+filesSetArray[i].name, filesSetArray[i]);
        }
        var loc = '/uploadFiles.yaws';
        fetch(loc, { 
          method: 'POST',
          body: data,
          contentType: 'multipart/form-data',
          // cache: false,
          // processData: false, 
        })
        .then(function(response) {
          if (!response.ok) {
            return 'fail';
          }else{
            return response.text();
          }
        })
      .then(function(data) {
          if (data === 'fail'){
            if (failFunction){
              failFunction();
            }
          }else{       
            var converted = erlangDecode(data);
            postFunction(converted);
          }
        }).catch(error => {
            if (failFunction){
              failFunction(error);
            }
        })

      ;
        return null;
      }catch(err) {
        if (failFunction){
          failFunction(err);
        }
        return [];
      }  
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function utf8_to_str(a) {
    for(var i=0, s=''; i<a.length; i++) {
        var h = a[i].toString(16)
        if(h.length < 2) h = '0' + h
        s += '%' + h
    }
    return decodeURIComponent(s)
}
// console.log(utf8_to_str([232,175,183,230,177,130,229,184,174,229,191,153]));

export function erlangDecode(objectString){ 
  if (objectString){
    var stack = [];
    var pieces = objectString.split('');
    var currentMode = '';
    var lastPiece = '';
    var isEscaped = false;
    for (var i = 0; i < pieces.length; i++){
      var aPiece = pieces[i];
      if (currentMode === 'string'){
        if (isEscaped){
          lastPiece += aPiece;
          isEscaped = false;
        }else{
          if (aPiece === '"'){
            stack.push({'t':'string', 'v':lastPiece});
            lastPiece = '';
            currentMode = '';
          }else if (aPiece === '\\'){
            if (pieces.length > +i + 1){
              if (pieces[+i+1] === 'n'){
                lastPiece += '\n';
                i++;
              }else if (pieces[+i+1] === 'r'){
                lastPiece += '\r';
                i++;
              }else if (pieces[+i+1] === 'e'){
                lastPiece += 'e';
                i++;
              }else if (pieces[+i+1] === 't'){
                lastPiece += '\t';
                i++;
              }else if (pieces[+i+1] === 'f'){
                lastPiece += '\f';
                i++;
              }else{
                isEscaped = true;
              }
            }else{
              isEscaped = true;
            }
          }else{
            lastPiece += aPiece;
          }
        }
      }else if (currentMode === 'binary'){
        if (isEscaped){
          lastPiece += aPiece;
          isEscaped = false;
        }else{
          if (aPiece === '"'){
            if (pieces.length > +i+2){
              if (pieces[+i+1] === '>' && pieces[+i+2] === '>'){
                stack.push({'t':'binary', 'v':lastPiece});
                lastPiece = '';
                currentMode = '';
                i = +i+2;
              }
            }
          }else if (aPiece === '\\'){
            if (pieces.length > +i + 1){
              if (pieces[+i+1] === 'n'){
                lastPiece += '\n';
                i++;
              }else if (pieces[+i+1] === 'r'){
                lastPiece += '\r';
                i++;
              }else if (pieces[+i+1] === 't'){
                lastPiece += '\t';
                i++;
              }else{
                isEscaped = true;
              }
            }else{
              isEscaped = true;
            }
          }else{
            lastPiece += aPiece;
          }
        }
      }else if (currentMode === 'enclosedAtom'){
        if (isEscaped){
          lastPiece += aPiece;
          isEscaped = false;
        }else{
          if (aPiece === '"'){
            stack.push({'t':'atom', 'v':lastPiece});
            lastPiece = '';
            currentMode = '';
          }else if (aPiece === '\\'){
            isEscaped = true;
          }else{
            lastPiece += aPiece;
          }
        }
      }else if (currentMode === 'nakedAtomOrNumber'){
        if (aPiece === ','){
          if (isNumber(lastPiece)){
            stack.push({'t':'number', 'v':+lastPiece});
          }else{
            stack.push({'t':'atom', 'v':lastPiece});
          }
          lastPiece = '';
          currentMode = '';
        }else if (aPiece === '}'){
          if (isNumber(lastPiece)){
            stack.push({'t':'number', 'v':+lastPiece});
          }else{
            stack.push({'t':'atom', 'v':lastPiece});
          }
          lastPiece = '';
          currentMode = '';
          var length3 = stack.length;
          for (var j3 = length3 - 1; j3 > -1; j3--){
            if (stack[j3] === 'tupleStart'){
              var p3 = stack.splice(+j3, length3-j3);
              p3.splice(0,1);
              stack.push({'t':'tuple', 'v':p3});
              break;
            }
          }
        }else if (aPiece === ']'){
          if (isNumber(lastPiece)){
            stack.push({'t':'number', 'v':+lastPiece});
          }else{
            stack.push({'t':'atom', 'v':lastPiece});
          }
          lastPiece = '';
          currentMode = '';
          var length4 = stack.length;
          for (var j4 = length4 - 1; j4 > -1; j4--){
            if (stack[j4] === 'listStart'){
              var p4 = stack.splice(+j4, length4-j4);
              p4.splice(0,1);
              stack.push({'t':'list', 'v':p4});
              break;
            }
          }
        }else{
          lastPiece += aPiece;
        }
      }else{
        if (aPiece === '{'){
          stack.push('tupleStart');
        }else if (aPiece === '}'){
          var length = stack.length;
          for (var j = length - 1; j > -1; j--){
            if (stack[j] === 'tupleStart'){
              var p = stack.splice(+j, length-j);
              p.splice(0,1);
              stack.push({'t':'tuple', 'v':p});
              break;
            }
          }
        }else if (aPiece === '['){
          stack.push('listStart');
        }else if (aPiece === ']'){
          var length2 = stack.length;
          for (var j2 = length2 - 1; j2 > -1; j2--){
            if (stack[j2] === 'listStart'){
              var p2 = stack.splice(+j2, length2-j2);
              p2.splice(0,1);
              stack.push({'t':'list', 'v':p2});
              break;
            }
          }
        }else if (aPiece === '"'){
          currentMode = 'string';
          lastPiece = '';
        }else if (aPiece === '"'){
          currentMode = 'enclosedAtom';
          lastPiece = '';
        }else if (aPiece === '<'){
          if (pieces.length > +i+4){
            if (pieces[+i+1] === '<' && pieces[+i+2] === '"'){
              currentMode = 'binary';
              lastPiece = '';
              i = +i+2;
            }else if (pieces[+i+1] === '<' && pieces[+i+2] === '>' && pieces[+i+3] === '>'){
              stack.push({'t':'binary', v:''});
              lastPiece = '';
              i = +i+3;             
            }else if (pieces[+i+1] === '<'){
              var lastInt = '';
              var stringSet = '';
              for (var k = +i+2; k < pieces.length; k++){
                if (pieces[k] === '>'){
                  i = +k+1;
                  var p1 = lastInt.split(',').map((aP, index)=>{
                    return +aP;
                  })
                  stringSet = utf8_to_str(p1);
                  k = pieces.length;
                  break;
                }else{
                  lastInt += pieces[k];
                }
              }
              stack.push({'t':'binary', v:stringSet});
              currentMode = '';
              lastPiece = '';
            }
          }
        }else if (aPiece === ' '){
        }else if (aPiece === '\r'){
        }else if (aPiece === '\n'){
        }else if (aPiece === ','){
        }else{
          currentMode = 'nakedAtomOrNumber';
          lastPiece = aPiece;
        }

      }
    }
      if (lastPiece === ''){
      }else if (currentMode === 'nakedAtomOrNumber'){
        if (isNumber(lastPiece)){
          stack.push({'t':'number', 'v':+lastPiece});
        }else{
          stack.push({'t':'atom', 'v':lastPiece});
        }
      }
    
    return stack[0];    
  }else{
    return {'t':'list', 'v':[]};
  }

}

