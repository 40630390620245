// App.js
import React, {Component} from 'react';
import { Link } from "react-router-dom";

import { Icon } from '@iconify/react';
import './css/guide.css';

function clickSmartContract(){
			window.location.assign("https://www.cues.sg/contracts");
}
function clickDapp(){
	window.location.assign("https://www.cues.sg/dapps");
}


class Main extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Cues</h2>
			<p>We are a No-Code Smart Contracts Generator and DApp Builder.</p>
            <p>Launch Smart Contracts and deploy DApps with our No-Code Smart Contracts Generator and DApp Builder. <br/>Create and instantly deploy your own tokens and NFTs on Ethereum, Binance, Polygon, Avalanche, Fantom, Dogechain, Heco and many more.</p>
			<br/><br/>
			<p><b>Chains Offered:</b> Arbitrum One, Aurora, Avalanche, Base, BeanEco SmartChain, Binance Smart Chain, Conflux, Core, Cronos, Doegechain, Ethereum, Fantom, Huobi ECO, Linea, Loop, OP, Polygon, Polygon zkEVM, PulseChain, Shibarium, zKSync, Zora</p>
		  </div>
		
		  	
  );
}}

class Payment extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
    };
  }
	
	
	render(){		
		
    return (
		  <div className="guideContainer">
			<h2 className = "guideTitle">Adding Credits</h2>
			
			<p>We offer Stripe as a secure and reliable payment service on our website. It's important to note that Stripe only accepts payments in USD currency. <br/> <br/>
			We also accept crypto payments. Simply click on the Stable Coins option and connect your wallet. <br/><br/> 
			Thank you for choosing CUES, and we look forward to serving you!</p>
			
		  </div>	  	
  );
}}


class BlockchainOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Blockchain Overview</h2>
			<p>
				You’ve heard all about Blockchain and how it’s going to revolutionize the way we work, play, and stay connected. But what is blockchain, really?
				<br/><br/>
				To simplify things, you only need to remember 3 words to understand blockchain. A blockchain is an <b>immutable, distributed ledger</b>. At its core, blockchain is simply a form of record keeping, by documenting the history of a lot of transactions.
				<br/><br/>
				<div className="learnblue"><b>Immutable</b>: Once a piece of code is published on the blockchain network, it is <b>unalterable</b>. Records <b>cannot be edited, deleted, or destroyed</b>. It’s pretty hard to change what’s been written, and digital information that resides on the blockchain stays there forever.</div>

				<div className="learnblue"><b>Distributed</b>: No one computer owns or stores the information on the blockchain. Instead, blockchain is a <b>decentralized</b> platform that can be <b>accessed by anyone with an internet connection</b>, and is <b>simultaneously run on millions of nodes</b> across the world, consisting of a system of computers that verify and contribute to the network.</div>

				<div className="learnblue"><b>Ledger</b>: Much like an excel sheet you use to monitor or track your expenditure, or your monthly statements stating the inflows and outflows of your bank account, blockchain serves as a <b>peer-to-peer distributed digital clipboard</b> of a list of time-stamped transactions.</div>
				<br/><br/>
				The underlying premise of blockchain is <b>cryptography</b>, a form of  technology involving a lot of maths. A blockchain aggregates information together in blocks, and every time a new transaction occurs, a record of that transaction is added to the block. When the capacity of a block is reached, it is completed and linked to a previous block. This process then reiterates.
				<br/><br/>
				Bockchain has been heralded as the next frontier for Web 3.0. Web 3.0 promotes decentralization where information is built, operated, and owned by users instead of providers. This is opposed to our current version of Web 2.0, which is based on the internet and monopolized by large tech companies like Google, Amazon, and Facebook, among others. The ethos of blockchain-powered cryptocurrencies is to direct power away from central banks, government authorities, and private corporations, making technology the driving force behind democracy and a decentralized world.
				<br/><br/>
				Since blockchain was first envisioned as a research project by two researchers in 1991, and then popularized and monetised as Bitcoin by Satoshi Nakamoto in 2008, blockchain has found widespread application in the 21st century with cryptocurrencies, <Link to="/learn-smartcontracts" className="learnlink">smart contracts</Link>, and <Link to="/learn-dapps" className="learnlink">dApps</Link>.
			</p>
		  </div>
		
		  	
  );
}}
		
class SmartContractOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Smart Contract Overview</h2>
			<p>
				Contrary to what its name might suggest, smart contracts are neither very intelligent (in the sense that you probably won’t be able to immediately understand them) nor are they legally binding like the contracts you’re familiar with (think about endless TnC clauses, or that doomed marriage certificate). Turns out smart contracts are not the most user-friendly or intuitive things in the world either, and that’s where we’re here to help.
				<br/><br/>
				Smart contracts are pieces of code, or computer programmes, powered by blockchain platforms, like Ethereum, Polygon, and Binance, among others. At their core, they facilitate three key functions: 

				<br/><br/>
				<span className="iconlist"> <Icon width = "25" icon="ph:number-circle-one-bold"/> </span><span className="textlist"> &nbsp;Store rules</span><br/>
				<span className="iconlist"> <Icon width = "25" icon="ph:number-circle-two-bold"/> </span><span className="textlist"> &nbsp;Verify rules</span><br/>
				<span className="iconlist"> <Icon width = "25" icon="ph:number-circle-three-bold"/> </span><span className="textlist"> &nbsp;Self-execute rules</span>

				<br/><br/>
				Smart contracts work by following simple “if/when…then…” statements written into code on a blockchain. They function as digital agreements that are executed when predetermined terms and conditions are met and verified. These actions could include releasing funds to a bank account, sending out a notification, or uploading files to a database. The blockchain is constantly updated in real time for every transaction that has been completed.
				<br/><br/>
				Think of smart contracts as very secure vending machines: every vending machine operates by a set of predefined rules and produces a definitive outcome - to buy a packet of M&Ms, you need to insert coins into the machine. The machine automatically releases the packet of M&Ms (fixed outcome) from its holder once it verifies the correct amount is paid (predetermined condition), and returns you some coins if there’s excess (extra functions you can specify and add into the smart contract to customize it).
				<br/><br/>
				Smart contracts are trustless transactions where all participants can be immediately certain of the outcome. Because smart contracts exist on a blockchain, there are no intermediaries: no dishonest brokers, no bureaucratic and inefficient corporations, no authoritarian governments and not even a lawyer.
				<br/><br/>
				Smart contracts simply self-execute when predetermined conditions are met. Workflows are automated and the next actions for a contract are triggered instantaneously. This results in savings in both cost and time, boosting efficiency. 
				<br/><br/>
				In addition, since they are stored on a decentralized platform, they are extremely secure, and incredibly difficult to alter or hack.
				<br/><br/>
				Smart contracts have the potential to solve problems our traditional technologies have historically struggled with, and can be applied in fields as diverse as finance, supply chains, healthcare, and gaming. And we are only just getting started.
				<br/><br/>
				Back in the old days, smart contracts used to be the sole purview of the developers and coders. But at Cues.sg, they can be leveraged on and harnessed by anyone, including you, with our online platform to simplify the structuring of smart contracts.
				<br/><br/>
				Create your smart contract with us today to unleash the power of blockchain.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		 </div>	  	
  );
}}

class DAppOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">DApp Overview</h2>
			<p>
				DApps stand for decentralized applications, which are applications fueled by the technology of smart contracts.
				<br/><br/>
				At the core of dApps are smart contracts, which function as their backend code, running on a decentralized blockchain network such as Ethereum, Polygon, and Binance, among others. The blockchain is used for data storage, while the smart contracts feed into the application’s logic. The other aspect of dApps is the front-end code, which gives rise to the user interface consumers engage with, much like any other application you download from the Apple Store or Google Play. The front-end code makes calls to the backend code to execute its functions when consumers interact with the dApp.
				<br/><br/>
				Since most smart contracts are accessible once published, similar to open APIs, your dApp could include a combination of your own smart contracts, or even a smart contract someone else has written.
				<br/><br/>
				It is important to get smart contracts and dApps right the first time round, since once smart contracts or dApps are deployed on the blockchain network, you can’t change them anymore.
				<br/><br/>
				We want to make things simple for you. With our online platform streamlining the creation process and removing the need for coding, we’ll have your smart contracts and dApps ready in minutes.
				<br/><br/>
				Don’t believe us? Try it for yourself.
				<br/><br/>
				Create your dApp with us today.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickDapp}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
				</p>
		  </div>
		
		  	
  );
}}

class MintingOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Why have a Minting Contract</h2>
			<p>
				Having a minting contract offers numerous benefits that make it an attractive option for individuals and businesses alike.
				<br/><br/>
				<b>Why Have a Minting Contract?</b>
				<br/><br/>
				<b>1. Tokenization of Assets</b>: Minting contracts enable the tokenization of various assets, including physical assets or rights, representing them as digital tokens. This tokenization opens up opportunities for fractional ownership, liquidity, and efficient transfer of value.
				<br/>
				<b>2. Monetization Opportunities</b>: Minting contracts open up new avenues for monetization. In the case of NFTs, creators can sell their minted NFTs directly to collectors, fans, or investors, eliminating the need for intermediaries. This direct peer-to-peer engagement allows creators to monetize their work more efficiently and retain a larger share of the revenue generated.
				<br/>
				<b>3. Diversification of Investment Portfolio</b>: Minting contracts allow creators and investors to tokenize assets and offer them as investment opportunities. This enables individuals to diversify their investment portfolio by including unique digital assets, providing exposure to alternative asset classes.
				<br/>
				<b>4. Enhanced Engagement and Community Building</b>: Minting contracts foster direct engagement between creators and their audience. NFT holders become part of a community that shares a common interest in the creator's work. This creates opportunities for interaction, feedback, and collaboration, enhancing the overall engagement and relationship-building with fans and supporters.
				<br/><br/>
				<b>How can CUES help in this process?</b>
				<br/><br/>
				Our platform empowers users without programming knowledge to create no-code minting contracts effortlessly. With our intuitive form fields, users can define essential parameters such as token details, supply allocation, mint phases, access restrictions, fees, and referral bonuses. This no-code approach streamlines the contract creation process, making it accessible to a broader audience.
				<br/><br/>
				Flexible Allocation and Minting Phases:<br/>
				Users have complete control over the pre-mint allocation of tokens or NFTs. They can determine the quantity of tokens or NFTs available for minting, enabling fair and customizable distribution. Moreover, our platform supports multiple minting phases, allowing users to limit access based on whitelists or blacklists. This flexibility ensures a controlled and tailored approach to token or NFT distribution.
				<br/><br/>
				Dynamic Fee Structures and Conditions:<br/>
				Our platform enables users to incorporate various fee structures into their minting contracts. They can set various payment tokens and their respective fees. Furthermore, users can define conditions for minting access, such as ownership of a previous token or NFT, ensuring eligibility criteria for participation. We also allow users to incentivize referrals through referral bonus programs, encouraging community engagement and growth.
				<br/><br/>
				Supply Caps and Per-Account Limits:<br/>
				To manage the overall supply and prevent token or NFT inflation, our platform supports the setting of supply caps. Users can establish the maximum quantity of tokens or NFTs that can be minted, ensuring scarcity and value preservation. Additionally, per-account limits can be set, allowing users to regulate the number of tokens or NFTs that can be minted by individual participants.
				<br/><br/>
				Enhanced Multimedia Integration for NFTs:<br/>
				For NFT creators, our platform seamlessly integrates multimedia content. Users can easily upload images, videos, or other media, with our platform handling the integration with IPFS for secure and decentralized storage.
				<br/><br/>
				Create your minting contract with us today to unleash the power of blockchain.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		  </div>
		
		  	
  );
}}

class StakingOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Why have a Staking Contract</h2>
			<p>
				In the dynamic world of cryptocurrencies, staking contracts have emerged as a compelling option for individuals to actively participate in blockchain networks and earn rewards. By understanding the purpose and benefits of staking contracts, investors can make informed decisions about their participation in the staking ecosystem. 
				<br/><br/>
				<b>Why Have a Staking Contract?</b>
				<br/><br/>
				<b>1. Passive Income Generation</b>: Staking contracts provide a unique opportunity for individuals to earn passive income. By staking their tokens, users actively contribute to the security and consensus of the blockchain network, and in return, they receive rewards in the form of additional tokens or transaction fees. This passive income stream can supplement traditional investment strategies and potentially provide long-term financial benefits.
				<br/>
				<b>2. Participation in Network Governance</b>: Staking contracts often grant participants the ability to participate in network governance decisions. Depending on the blockchain network, stakers may have voting rights, allowing them to influence the direction of the project. This active involvement empowers individuals to have a say in the development and evolution of the network.
				<br/>
				<b>3. Support for Network Security</b>: By staking their tokens, participants play a crucial role in securing the blockchain network. Staking helps create a strong and decentralized consensus mechanism, making it more resistant to attacks and ensuring the integrity of the system. Supporting network security through staking contracts contributes to the overall trust and reliability of the blockchain ecosystem.
				<br/>
				<b>4. Token Price Appreciation</b>: Staking contracts often involve locking up tokens for a specific period. This reduction in circulating supply can create scarcity, potentially leading to increased demand and price appreciation for the staked tokens. Therefore, in addition to earning rewards, participants may also benefit from the potential growth of the token's value over time.
				<br/><br/>
				<b>How can CUES help in this process?</b>
				<br/><br/>
				To simplify the process of creating staking contracts, we have developed a user-friendly platform that allows users to build smart contracts without the need for coding expertise. Our platform provides a streamlined approach, empowering users to customize their staking contracts according to their preferences and requirements.
				<br/><br/>
				Our platform offers flexibility by allowing users to decide the token they want to reward participants with. We provide various interest mechanisms, enabling users to tailor the staking contract to their specific needs. With our platform, users can add multiple staking schemes, each with the ability to set minimum and maximum stake periods, establish limits on the period in which interest is earned, introduce different tax structures, and the ability to allow users to withdraw interest without unstaking their currency.
				<br/><br/>
				Additionally, we offer the capability to set up referral bonuses, incentivizing users to refer others to the staking contract.
				<br/><br/>
				By leveraging our platform, users can streamline the creation of their staking contracts, eliminating the need for complex coding or development skills. This user-friendly approach allows individuals to actively participate in staking and unlock the benefits of blockchain networks with ease.
				<br/><br/>
				Create your staking contract with us today to unleash the power of blockchain.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		  </div>
		
		  	
  );
}}

class ExchangeOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Why have an Exchange/Swap Contract</h2>
			<p>
				Exchange contracts are essential in DeFi to automate and secure digital asset transactions, enabling efficient swaps, control over taxes, customization of exchange rates, and allocation of taxes, which are pivotal for a seamless and customizable decentralized financial ecosystem.
				<br/><br/>
				<b>Why Have an Exchange/Swap Contract?</b>
				<br/><br/>
				<b>1. Access to More Tokens</b>: Swap smart contracts expand access to a wider range of digital assets, especially smaller tokens that are challenging to find on mainstream exchanges.
				<br/>
				<b>2. Lower Transaction Fees</b>: Swapping crypto directly cuts transaction costs significantly by eliminating intermediary steps. While not fee-free, the single fee applied by crypto swapping platforms is usually more economical for users (do check the contract specs though!).
				<br/>
				<b>3. Enhanced Trading Flexibility</b>: Swap platforms and apps that support swaps offer a broader selection of token pairs, giving users more options for buying and selling digital assets.
				<br/>
				<b>4. Enhanced Liquidity</b>: One of the most significant benefits of swap contracts is their role in enhancing liquidity in the crypto market. By facilitating the direct exchange of assets, these contracts contribute to a more liquid and vibrant market, making it easier for users to enter and exit positions without the need to go through multiple layers of intermediaries.
				<br/><br/>
				<b>How can CUES help in this process?</b>
				<br/><br/>
				Our platform empowers users without programming knowledge to create no-code exchange/swap contracts effortlessly. You can simply fill in the required form fields, eliminating the need to understand complex programming languages.
				<br/><br/>
				Flexible Rate Mechanisms:<br/>
				Users have the flexibility to choose how they want their exchange rates to function. Whether you prefer exchange rates to be determined by a liquidity pool or fixed between two tokens and/or NFTs, our platform accommodates your choice. This customization empowers you to create contracts that align with your specific project or use case.
				<br/><br/>
				Taxation Control:<br/>
				The ability to set and customize the percentage of taxes on transactions is a powerful feature we offer. Whether you need to implement tax allocation, revenue-sharing, or other financial governance mechanisms, our platform simplifies the process. You have control over your financial structure, making it easy to adapt to the evolving needs of your project.
				<br/><br/>
				Minimum Transfer Limits:<br/>
				Setting minimum transfer limits is essential for efficient transaction management. With our platform, you can specify these limits, ensuring that your transactions meet your criteria. This feature is particularly valuable for projects aiming to optimize their operations.
				<br/><br/>
				Allocation of Taxes:<br/>
				Our platform simplifies the allocation of taxes to different projects or pools. This streamlined approach ensures that your project's financial governance is well-organized and transparent.
				<br/><br/>
				Create your exchange/swap contract with us today to unleash the power of blockchain.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		  </div>
		
		  	
  );
}}

class LotteryOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Why have a Lottery/Jackpot Contract</h2>
			<p>
				In the realm of blockchain games and decentralized finance, lottery and jackpot contracts have emerged as captivating concepts that offer unique opportunities for users. These contracts leverage the transparency and fairness of blockchain technology to create thrilling experiences.
				<br/><br/>
				<b>Why Have a Lottery/Jackpot Contract?</b>
				<br/><br/>
				<b>1. Exciting and Engaging Gameplay</b>: Lottery and jackpot contracts introduce an element of excitement and anticipation. Users are drawn to the chance of winning valuable prizes or even the entire jackpot, creating a sense of thrill and engagement. These contracts add a layer of gamification to blockchain applications, making them more immersive and appealing to a wide range of users.
				<br/>
				<b>2. Fairness and Transparency</b>: Blockchain technology ensures transparency in lottery and jackpot contracts. The rules of participation, prize distribution, and winner selection are predefined and enforced by smart contracts, leaving no room for manipulation or bias. Every transaction and outcome is recorded on the blockchain, providing participants with confidence in the fairness of the process.
				<br/>
				<b>3. Opportunities for Token Holders</b>: Lottery contracts based on ownership of ERC721 tokens provide an innovative way to reward token holders. By randomly allocating prizes based on token ownership, holders have the chance to win valuable rewards, creating additional value for their token holdings. This incentivizes token acquisition and long-term ownership, fostering a loyal community around the project.
				<br/>
				<b>4. Contribution to Liquidity and Token Adoption</b>: Jackpot contracts that require users to deposit tokens contribute to liquidity and token adoption. Participants deposit their tokens into the contract, increasing the overall liquidity pool. This, in turn, enhances the token's value and utility. Furthermore, jackpot contracts can serve as a catalyst for token adoption, attracting users who seek the opportunity to win significant rewards.
				<br/><br/>
				<b>How can CUES help in this process?</b>
				<br/><br/>
				With our platform, users have the freedom to choose from a wide range of reward options for their lottery contracts. Whether it's native tokens, unique ERC721 NFTs, or other digital assets, users can easily define the prizes to be distributed among the winners. This level of customization enables creative and engaging reward systems tailored to the unique requirements of their project or platform.
				<br/><br/>
				We provide users with the flexibility to conduct lotteries immediately after loading the prizes or schedule them for a future date and time. This feature empowers users to strategically plan their lotteries, creating anticipation and excitement among participants. By controlling the timing, users can generate a buzz around their platform and maximize user engagement.
				<br/><br/>
				For jackpot contracts, we offer extensive customization options. Users can choose the preferred payment type for participants, be it native tokens or other digital assets. Additionally, users can select the triggering mechanism for the jackpot, either based on the number of participants or at their discretion. This level of customization ensures the jackpot aligns with their platform's unique requirements, providing a captivating and rewarding experience for users. Contract owners also have the ability to tax participants in order to generate additional revenue streams or contribute to community funds. By implementing a taxation system, users can foster the growth and sustainability of their platform while maintaining transparency in the prize distribution process.
				<br/><br/>
				By utilizing our no-code platform, the creation of lottery and jackpot contracts is made simple and accessible to all. With customizable rewards and prizes, options for immediate or scheduled lotteries, direct prize allocation, personalized jackpot settings, and seamless taxation integration, users can create captivating and rewarding experiences for their community. Unlock the full potential of lottery and jackpot contracts by embracing our innovative platform, attracting new users, and driving the success of your blockchain gaming or decentralized finance project.
				<br/><br/>
				Start building your lottery and jackpot contracts today, with ease and customization at your fingertips.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		  </div>
		
		  	
  );
}}

class VotingOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Why have a Voting Contract</h2>
			<p>
				A voting contract ensures transparent, tamper-proof, and decentralized decision-making, empowering users to participate directly in the process while eliminating voter fraud and promoting trust among stakeholders.
				<br/><br/>
				<b>Why Have a Voting Contract?</b>
				<br/><br/>
				<b>1. Transparency and Security</b>: Voting smart contracts ensure that every vote cast is permanently recorded on the blockchain, making it tamper-proof and transparent. This fosters trust among users, as the integrity of the voting process is assured.
				<br/>
				<b>2. Eliminating Voter Fraud</b>: With unique cryptographic identities assigned to each voter, our platform eliminates the risk of voter fraud, ensuring a fair and genuine voting process.
				<br/>
				<b>3. Decentralized Decision-Making</b>: Users can directly participate in decision-making without intermediaries or central authorities, empowering them and promoting accountability within the community.
				<br/>
				<b>4. Global Accessibility</b>: Users from across the globe can participate in the decision-making process, promoting diversity of thought, inclusivity and increasing sense of identity within the community.
				<br/><br/>
				<b>How can CUES help in this process?</b>
				<br/><br/>
				Our user-friendly interface allows users to easily input their proposal titles and options through simple form fields, making the submission process quick and intuitive.
				<br/><br/>
				Users can customize voting rules, deciding whether it's one vote per wallet address or one vote per NFT. Additionally, they can set a time limit, ensuring timely decision-making.
				<br/><br/>
				Start building your voting contracts today, with ease and customization at your fingertips.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		  </div>
		
		  	
  );
}}


class AirdropOverview extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Why Have An Airdrop Contract</h2>
			<p>
				Airdrop contracts have emerged as a powerful tool in the cryptocurrency space, offering an innovative way to distribute tokens or NFTs to a targeted audience. 
				<br/><br/>
				<b>Why Have An Airdrop Contract?</b>
				<br/><br/>
				<b>1. Inclusive Token Distribution</b>: Airdrop contracts enable a fair and inclusive distribution of tokens or NFTs to a predefined audience. By leveraging blockchain technology, projects can ensure that their community members, early adopters, or specific stakeholders are rewarded without the need for complex and centralized processes.
				<br/>
				<b>2. User Engagement and Loyalty</b>: Airdrops are an excellent way to engage users and foster loyalty within a community. Offering tokens or NFTs as rewards creates a sense of belonging and appreciation among users, encouraging them to actively participate and support the project.
				<br/>
				<b>3. Customizable Allocation</b>: Airdrop contracts allow for customizable token allocation strategies. With smart contracts, you can define criteria for distribution, such as holding a certain amount of tokens, participating in specific activities, or even being part of a particular community.
				<br/>
				<b>4. Exclusion and Bonus Options</b>: One of the standout features of airdrop contracts is the ability to exclude certain addresses or provide bonuses to specific users. This level of customization ensures that tokens are distributed strategically, targeting the intended audience and promoting project goals.
				<br/><br/>
				<b>How can CUES help in this process?</b>
				<br/><br/>
				Our platform stands out with its intuitive form fields that allow users to customize their airdrop contracts with ease. Whether distributing coins, NFTs, or a combination, users have the flexibility to define their distribution strategies, deciding who to exclude, allocate bonuses to, or grant additional tokens.
				<br/><br/>
				Furthermore, our platform empowers users to set time limits for their airdrop campaigns. By specifying a duration, users ensure timely decision-making, creating a sense of urgency and excitement within their community.
				<br/><br/>
				Start building your airdrop contracts today, with ease and customization at your fingertips.
				<br/><br/>
				<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width = "30" icon="ant-design:rocket-outlined" /> Start Now </div></div>
			</p>
		  </div>
		
		  	
  );
}}

class Unfinished extends Component {
	constructor() {
    super();
    this.state = {
		
     
    };
  }

	
	render(){
    return (
		  <div className="container">		   
			<h2 className = "guideTitle">To Be Written</h2>
			
		  </div>
		
		  	
  );
}}
  
    

export {Main, Payment, BlockchainOverview, SmartContractOverview, DAppOverview, MintingOverview, ExchangeOverview, StakingOverview, LotteryOverview, VotingOverview, AirdropOverview, Unfinished};
