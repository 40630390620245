// App.js
import React, {Component} from 'react';

import { Icon } from '@iconify/react';
import './css/guide.css';
import {PhotoZoom} from './modal.js';

import basic1 from './img/guide/smartcontract/00basic1.png';
import basic2 from './img/guide/smartcontract/00basic2.png';
import basic3 from './img/guide/smartcontract/00basic3.png';
import basic4 from './img/guide/smartcontract/00basic4.png';

import deploy1 from './img/guide/smartcontract/01deploy1.png';
import deploy2 from './img/guide/smartcontract/01deploy2.png';
import deploy3 from './img/guide/smartcontract/01deploy3.png';
import deploy4 from './img/guide/smartcontract/01deploy4.png';
import deploy5 from './img/guide/smartcontract/01deploy5.png';

import token1 from './img/guide/smartcontract/02minttoken1.png';
import token2 from './img/guide/smartcontract/02minttoken2.png';
import token3 from './img/guide/smartcontract/02minttoken3.png';
import token4 from './img/guide/smartcontract/02minttoken4.png';
import token5 from './img/guide/smartcontract/02minttoken5.png';
import token6 from './img/guide/smartcontract/02minttoken6.png';
import token7 from './img/guide/smartcontract/02minttoken7.png';
import token8 from './img/guide/smartcontract/02minttoken8.png';
import token9 from './img/guide/smartcontract/02minttoken9.png';

import nft1 from './img/guide/smartcontract/03mintnft1.png';
import nft2 from './img/guide/smartcontract/03mintnft2.png';
import nft3 from './img/guide/smartcontract/03mintnft3.png';
import nft4 from './img/guide/smartcontract/03mintnft4.png';
import nft4a from './img/guide/smartcontract/03mintnft4a.png';
import nft4b from './img/guide/smartcontract/03mintnft4b.png';
import nft4c from './img/guide/smartcontract/03mintnft4c.png';
import nft4d from './img/guide/smartcontract/03mintnft4d.png';
import nft5 from './img/guide/smartcontract/03mintnft5.png';
import nft6 from './img/guide/smartcontract/03mintnft6.png';
import nft7 from './img/guide/smartcontract/03mintnft7.png';
import nft8 from './img/guide/smartcontract/03mintnft8.png';
import nft9 from './img/guide/smartcontract/03mintnft9.png';
import nft10 from './img/guide/smartcontract/03mintnft10.png';
import nft11 from './img/guide/smartcontract/03mintnft11.png';
import nft12 from './img/guide/smartcontract/03mintnft12.png';
import nft13 from './img/guide/smartcontract/03mintnft13.png';
import nft14 from './img/guide/smartcontract/03mintnft14.png';

import exchange1 from './img/guide/smartcontract/04exchange1.png';
import exchange2 from './img/guide/smartcontract/04exchange2.png';
import exchange3 from './img/guide/smartcontract/04exchange3.png';

import staking1 from './img/guide/smartcontract/05staking1.png';
import staking2 from './img/guide/smartcontract/05staking2.png';
import staking3 from './img/guide/smartcontract/05staking3.png';
import staking4 from './img/guide/smartcontract/05staking4.png';
import staking5 from './img/guide/smartcontract/05staking5.png';

import reward1 from './img/guide/smartcontract/06reward1.png';
import reward2 from './img/guide/smartcontract/06reward2.png';
import reward3 from './img/guide/smartcontract/06reward3.png';
import reward4 from './img/guide/smartcontract/06reward4.png';

import escrow1 from './img/guide/smartcontract/07escrow1.png';

import voting1 from './img/guide/smartcontract/08voting1.png';
import voting2 from './img/guide/smartcontract/08voting2.png';
import voting3 from './img/guide/smartcontract/08voting3.png';
import voting4 from './img/guide/smartcontract/08voting4.png';

import ownership1 from './img/guide/smartcontract/09ownership1.png';
import ownership2 from './img/guide/smartcontract/09ownership2.png';

function clickSmartContract(){
			window.location.assign("https://www.cues.sg/contracts");
}
class Basics extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Common Functions</h2>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basic1} className="galleryImg" alt = "Cues Smart Contract Header" />
			<p><b>1. Header Functions</b></p>
			<ul className="guideList">
				<li className="">< Icon width="20" className = "guideListIcon" icon="bi:pencil" />: Edit project name. This is not the token name.</li>
				<li className="">< Icon width="20" className = "guideListIcon" icon="ph:coin" />: Top up credits. This will be used only if you launch a smart contract or dApp with us. Trying is free.</li>
				<li className="">< Icon width="20" className = "guideListIcon" icon="ant-design:share-alt-outlined" />: Share project with other collaborators. They will be able to edit the project with you.</li>
				<li className="">< Icon width="20" className = "guideListIcon" icon="bx:rocket" />: Launch project after filling in the form fields.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basic2} className="galleryImg" alt = "Cues Form Field" />
			<p><b>2. Form Fields</b>: While you do not need to complete the sections in the current order, we recommend you choose the blockchain you want to deploy on first as it may affect future questions. You can jump to any section by clicking on the corresponding circle. After completing the form, ensure that all parts have a green tick. If there are red crosses, this indicates that a mistake has been made.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basic3} className="galleryImg" alt = "Cues Start Page for Smart Contracts" />
			<p><b>3a. Accessing previous contracts</b>: Once logged in, you can click <i>See Previous Contracts</i> to see previous work. If you are in the midst of creating a smart contract, you can click the <i>Home</i> button to see previous smart contracts. 
			<br/>
			Everything is auto-saved, including unfinished contracts, so don't worry about losing your progress!</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={basic4} className="galleryImg" alt = "Cues Previous Smart Contracts" />
			<p><b>3b. Accessing previous contracts</b>: Here, you can view the details of smart contracts, whether unfinished or launched. You can also duplicate previous smart contracts and edit them to launch on a different blockchain, or delete unfinished smart contracts that you do not wish to continue.</p>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>	  	
  );
}}

class DeployContract extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">	   
			<h2 className = "guideTitle">Deploying Your Contract</h2>
			<p><b><u>Using CUES Auto-Deployment Service</u></b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy1} className="galleryImg" alt = "Cues Auto Deployment" />
			<p><b>1. Connect Wallet</b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy2} className="galleryImg" alt = "Cues Choose Wallet" />
			<p><b>2. Choose Wallet</b>: Choose the crypto wallet and link to the account of the contract owner. The following steps will be done based on Metamask.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy3} className="galleryImg" alt = "Cues Deploy Smart Contract Instructions" />
			<p><b>3a. Connect to Network</b>: If you see this message, this means that the current network your wallet is in is not the blockchain you indicated on your contract. In this case, the blockchain chosen was Ethereum Mainnet.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy4} className="galleryImg" alt = "Cues Connect to Network" />
			<p><b>3b. Connect to Network</b>: You will see this image if you are in the correct network. Click the <i>Deploy</i> button to add your contract to the blockchain. The only thing you would need to worry about is having enough crypto to cover your gas fees. :D</p>
			<p>The IPFS process is embedded in the website, meaning that the IPFS links will automatically be included in your smart contract. </p>
			<p><b>4. After deployment</b>: Head over to our no-code dApp builder at <b><a className = "guideLink" href= "https://www.cues.sg/dapps">https://www.cues.sg/dapps</a></b> to create your own dApp for your newly minted smart contract.</p>
			
			<p><b><u>Manual Deployment</u></b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={deploy5} className="galleryImg" alt = "Cues Manual Deployment" />
			<p><b>1. Download or Copy Code</b>: Code is written in Solidity.</p>
			<p><b>2. Deploy using an IDE</b>: The most common IDE used is REMIX.</p>
			<p><b>3. Connect Wallet</b>: Remember to go to your wallet and ensure that the mainnet or testnet you want to deploy on is the active one. </p>
			<p><b>4. Get Dummy Tokens (Optional)</b>: Obtain testnet tokens for free by entering your address on Testnet faucets. Do note that this is for testing and development purposes. </p>
			<p><b>5. After deployment</b>: Head over to our no-code dApp builder at <b><a className = "guideLink" href= "https://www.cues.sg/dapps">https://www.cues.sg/dapps</a></b> to create your own dApp for your newly minted smart contract.</p>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
			
			
		  </div>	  	
  );
}}

class MintToken extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Creating Your Own ERC-20 Token</h2>
			<br/>
			<br/>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-10 col-md-6 text-center">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe title = 'Mint Smart Contract with CUES SG' className="embed-responsive-item" src = {"https://www.youtube.com/embed/VCZ31X_HT3M"}></iframe>
						</div>
					</div>
				</div>
			</div>
			<br/>
			<br/>
			<p><b>Contracts here are for creating your own tokens.</b></p>
		
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Mint Coin".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token1} className="galleryImg" alt = "Cues Mint Token Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on. </li>
				<li className="guideListItem">Enter coin name. An example would be Ether.</li>
				<li className="guideListItem">Input coin symbol. This is usually an abbreviation, like ETH. Check that your symbol is unique by clicking on the link. </li>
				<li className="guideListItem"> Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">Specify the number of decimal places after the decimal point. The default value will always be the common practice for the blockchain selected, so there is no need to change.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token2} className="galleryImg" alt = "Cues Mint Token Pre Mint Allocation" />
			<p><b>4a. Pre-Mint Allocation (Optional)</b>:</p>
			<p>This refers to tokens being minted and distributed before the official launch date of the token. It is usually done to reward investors, developers or contributors who took part in the creation of the token. You can also indicate if the owner of the contract is able to mint for free at any time. While this part is optional, ensure that you have added at least one phase to either Pre-Mint or Mint. 
			<br/>Click the button if you want to add pre-mint allocation.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token3} className="galleryImg" alt = "Cues Mint Token  Pre Mint Allocation" />
			<p><b>4b. Pre-Mint Allocation (Optional)</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Input the number of coins you would like to give to the wallet address.</li>
				<li className="guideListItem">Add as many addresses as you want by clicking the button <i>+Pre-Mint Allocation.</i></li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token4} className="galleryImg" alt = "Cues Mint Token Mint Allocation" />
			<p><b>5a. Mint Allocation (Optional</b>:</p>
			<p>This section allows you to mint tokens in phases (and/or with conditions). An example would be stating the numbers to mint for presales (available for whitelist members only) vs public sales (available for all). The owner of the contract will be able to toggle the phases. While this section is optional, ensure that you have added at least one phase to either Pre-Mint or Mint. 
			<br/>Click the button if you want to add another phase.</p>
			<p><b>5b. Mint Allocation</b>:</p>
			<p>Specify the number of tokens. This could be a fixed or variable number of tokens. The process is the same for both, except that you would have to state how many tokens will be minted each time for the fixed one.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token5} className="galleryLargerImg" alt = "Cues Mint Token Mint Allocation" />
			<p><u>Allocation</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Specify the number of tokens.</li>
				<li className="guideListItem">Decide if the wallet address calling the function will be the one receiving the token or they can send it to another address.</li>
				<li className="guideListItem">Choose whether the tokens can only be minted by those listed in the whitelist. If so, upload a txt file with the addresses.</li>
				<li className="guideListItem">To prevent whales, each user can be limited to a certain number of mints.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token6} className="galleryLargerImg" alt = "Cues Mint Token Mint Allocation Fees" />
			<p><u>Fees</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Decide the payment type. This can be done in native coins, other coins or NFTs.</li>
				<li className="guideListItem">Decide the number of cryptocurrency required to pay for your coin. For coins, you can choose if you would like the flexibility to adjust the price. This could be useful when there are huge price fluctuations.</li>
				<li className="guideListItem">For coin/NFT payments, list the address of the coin/NFT payment accepted.</li>
				<li className="guideListItem">Burning here means that the crypto payment will be removed from circulation instead of going to the wallet specified. Do note that you may want to add another fee payment so that you receive payment in exchange for the coin.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft11} className="galleryImg" alt = "Cues Mint Token Mint Allocation Condition" />
			<p><u>Mint Condition</u>:</p>
			<p>Over here, you can add mint conditions for the phases.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft12} className="galleryLargerImg" alt = "Cues Mint Token Mint Allocation Condition" />
			<p>Here is an example of how you might want to limit your presales to the whole month of June.</p>
		
		
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token7} className="galleryImg" alt = "Cues Mint Token Burning" />
			<p><b>6. Burning</b>:</p>
			<p>Burning means that the network would remove a specified number of tokens from circulation. Burning a small portion can help the token uphold its value. Tokens will be sent to the 0 address, where nobody can access it.</p>
			<ul className="guideList">
				<li className="guideListItem">Option to burn a percentage of the transacted tokens. Here, you can decide the % of tokens being burned.</li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token8} className="galleryImg" alt = "Cues Mint Token Pause" />
			<p><b>7. Pause</b>:</p>
			<p>Pausing can be useful when you might want to pause the ability of token holders to mint/trade them on an exchange while the initial sale is still underway. One reason could be to stop speculative trading as buyers who purchase your token with the intent of immediately selling it can hurt your token’s value. On the flip side, this can reduce confidence as buyers will not like any pauses which limits their ability to trade or purchase the token.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={token9} className="galleryImg" alt = "Cues Mint Token Constraints" />
				
			<p><b>8. Constraints</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Option to add limit to number of coins minted. Setting a steady issuance rate along with a supply cap is good for controlling the inflation rate of your token and potentially result in long-term appreciation.</li>
				<li className="guideListItem">Option to add limit to number of tokens that can be held by an account. This can help prevent whales who can use their token stash to manipulate the price of your cryptocurrency. Heavy volume selling might create panic and lead to huge fluctuations in the price of your token.</li>
			</ul>
		
			<p><b>9. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>10. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}>< Icon width="30"  icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class MintNFT extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Creating Your Own (ERC-721) NFT</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Mint NFT".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft1} className="galleryLargerImg" alt = "Cues Mint NFT Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on.</li>
				<li className="guideListItem">Enter NFT name.</li>
				<li className="guideListItem">Input NFT symbol. This is usually an abbreviation. Check that your symbol is unique by clicking on the link. </li>
				<li className="guideListItem"> Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">Choose token type. ERC721 is the standard, while ERC721A is an extension and optimization of ERC721. ERC721A can help save gas as it enables the minting of multiple NFTs for almost the same cost as minting one. However, if your users do not mint in bulk, the gas costs might be higher as transfer costs for an ERC721A token are higher than am ERC721 token.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft2} className="galleryImg" alt = "Cues Mint NFT Multimedia" />
			<p><b>4. Multimedia</b>:</p>
			<p>Here is where you add all the assets like images/videos/audio that your NFT token is attached to. 
			<br/>
			If you just require unique tokens and not have a video/image/audio attached to it, leave the <i>"Any multimedia"</i> option as No. You can then indicate the maximum number of unique tokens if you wish to cap the supply. </p>
				
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft3} className="galleryLargerImg" alt = "Cues Mint NFT Multimedia" />
			<p><u>If you have multimedia</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Choose the type of multimedia.</li>
				<li className="guideListItem">Click the <i>"+ Add Image"</i> button. You can either upload NFTs one by one, or do a bulk upload by going to the NFT folder, select all and click Open.</li>
		
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft4} className="galleryImg" alt = "Cues Mint NFT Uploaded Multimedia" />
				<li className="guideListItem">Once uploaded, the assets will be sorted in ascending alphabetical/numerical order. Sort in the order you like using the arrows within the hovered assets. </li>
				<li className="guideListItem"> Add any names, descriptions and attributes to your multimedia. There are 2 ways to do so. </li>
				<li className="guideListItem">Click the <i>"Set Attributes"</i> button.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft4a} className="galleryImg" alt = "Cues Mint NFT Uploaded Multimedia" />
				<li className="guideListItem"> The first way to add multimedia details is to download the sample CSV file and fill up the missing fields. The sample CSV file has already been populated with the asset urls that you have uploaded and the column names.</li>
				<li className="guideListItem">After filling up the file, upload the edited CSV file by clicking the <i>"Upload"</i> button.</li>
				<li className="guideListItem">If you would like to add attributes, click the <i>"Specify Attributes"</i> button.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft4b} className="galleryImg" alt = "Cues Mint NFT Uploaded Multimedia" />	
				<li className="guideListItem">Click the <i>"+ Add Attribute"</i> button and enter the attribute name in the popup box. Keep doing so for the rest of the attributes. Once done, click the <i>"+ Exit Specify Attributes"</i> button.</li>
				<li className="guideListItem">Download the sample CSV file, you can see that the column names are populated with the attributes you have just entered.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft4c} className="galleryImg" alt = "Cues Mint NFT Uploaded Multimedia" />	
				<li className="guideListItem">The second way to input your multimedia details is to hover on the asset and click on the text you want to change. For example, if you want to change the name, click on the <i>"Missing Name"</i> text and a popup box would appear for you to key in the name. To key in the attribute value, click the <i>"Not Set"</i> text of the attribute you want to change and the popup box will appear for you to key in the attribute value.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft4d} className="galleryImg" alt = "Cues Mint NFT Uploaded Multimedia" />	
				<li className="guideListItem">Once our system detects that there are no missing details for your multimedia, the <i>"Upload to IPFS"</i> button will appear.</li>
				<li className="guideListItem">Click the button to upload to IPFS and create the json file. This part may take a few seconds. The json IPFS links will show up once completed. The IPFS process is done automatically and embedded in the smart contract, so you don't need to worry about it.</li>
				<li className="guideListItem">Decide if you would want to enable your items to be listed and tradeable on OpenSea. This is only available for Polygon networks. Contact us if you would like to have OpenSea access on other networks.</li>
				<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft5} className="galleryImg" alt = "Cues Mint NFT Pre-Reveal Multimedia" />
				<li className="guideListItem">You might want to have a pre-reveal placeholder so that your NFTs and metadata are hidden upon initial mint and hence, protected from sniping. Pre-reveal only affects the image shown, it does not affect ownership. For this to happen, you may not want to submit your contract for verification as the url of the actual mint is inside the contract. However, potential buyers might not have trust in an unverified contract. Having a good reveal plan can affect the success of the NFT. The pre-reveal process is the same as the uploading of NFT process. </li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft6} className="galleryImg" alt = "Cues Mint NFT Pre Mint Allocation" />
			<p><b>5a. Pre-Mint Allocation (Optional)</b>:</p>
			<p>This refers to tokens being mined and distributed before the official launch date of the token. It is usually done to reward investors, developers or contributors who took part in the creation of the token. You can also indicate if the owner of the contract is able to mint for free at any time.
			<br/>Click the button if you want to add pre-mint allocation.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft7} className="galleryImg" alt = "Cues Mint NFT Pre Mint Allocation" />
			<p><b>5b. Pre-Mint Allocation (Optional)</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Input the number of NFTs you would like to give to the wallet address.</li>
				<li className="guideListItem">Add as many addresses as you want by clicking the button <i>+Pre-Mint Allocation.</i></li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft8} className="galleryImg" alt = "Cues Mint NFT Mint Allocation" />
			<p><b>6a. Mint Allocation</b>:</p>
			<p>This section allows you to mint NFTs in phases (and/or with conditions). An example would be stating the numbers to mint for presales (available for whitelist members only) vs public sales (available for all). The owner of the contract will be able to toggle the phases. 
			<br/>Click the button if you want to add another phase.</p>
			<p><b>6b. Mint Allocation</b>:</p>
			<p>Specify the number of tokens. This could be a fixed or variable number of tokens. The process is the same for both, except that you would have to state how many tokens will be minted each time for the fixed one.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft9} className="galleryLargerImg" alt = "Cues Mint NFT Mint Allocation" />
			<p><u>Allocation</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Specify the number of tokens.</li>
				<li className="guideListItem">Decide if the wallet address calling the function will be the one receiving the token or they can send it to another address.</li>
				<li className="guideListItem">Choose whether the tokens can only be opened to those in the whitelist. If so, upload a txt file with the addresses.</li>
				<li className="guideListItem">To prevent whales, each user can be limited to a certain number of mints.</li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft10} className="galleryLargerImg" alt = "Cues Mint NFT Mint Allocation Fees" />
			<p><u>Fees</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Decide the payment type. This can be done in native coins, other coins or NFTs.</li>
				<li className="guideListItem">Decide the number of cryptocurrency required to pay for your NFT. For coins, you can choose if you would like the flexibility to adjust the price. This could be useful when there are huge price fluctuations.</li>
				<li className="guideListItem">For coin/NFT payments, list the address of the coin/NFT payment accepted.</li>
				<li className="guideListItem">Burning here means that the crypto payment will be removed from circulation instead of going to the wallet specified. Do note that you may want to add another fee payment so that you receive payment in exchange for the NFT.</li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft11} className="galleryImg" alt = "Cues Mint NFT Mint Allocation Condition" />
			<p><u>Mint Condition</u>:</p>
			<p>Over here, you can add mint conditions for the phases.</p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft12} className="galleryLargerImg" alt = "Cues Mint NFT Mint Allocation Condition" />
			<p>Here is an example of how you might want to limit your presales for the whole month of June.</p>
			
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft13} className="galleryImg" alt = "Cues Mint NFT Pause" />
			<p><b>7. Pause</b>:</p>
			<p>Pausing can be useful when you might want to pause the ability of token holders to trade them on an exchange while the initial sale is still underway. One reason could be to stop speculative trading as buyers who purchase your token with the intent of immediately selling it can hurt your token’s value. On the flip side, this can reduce confidence as buyers will not like the pause to continue indefinitely or happen repeatedly without prior notice.</p>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={nft14} className="galleryImg" alt = "Cues Mint NFT Constraints" />
			<p><b>8. Constraints</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Option to add limit to number of NFTs that can be held by an account. If the ownership of your NFT comes with an exclusive option to buy limited merchandise, limiting NFTs per account would be useful to help level the playing field and make a larger number of your NFT holders happy.</li>
			</ul>
			<p><b>9. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>10. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}>< Icon width="30"  icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
		
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class ExchangeCoin extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Creating Your Own Swap Contract</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Exchange Coins".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={exchange1} className="galleryLargerImg" alt = "Cues Exchange Coins Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on.</li>
				<li className="guideListItem"> Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">Choose the two crypto currencies that you would like to exchange. The native token displayed is dependant on the blockchain chosen. Enter the coin address and the number of decimals of the coin chosen. If you only need a one-sided exchange contract, ensure that the 1st currency is the one you want to swap from and the 2nd currency the one you want to swap to.</li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={exchange2} className="galleryImg" alt = "Cues Exchange Coins 1st to 2nd Conversion" />
			<p><b>4. 1st to 2nd Conversion</b>:</p>
			<p>The swap contract here will be based on a fixed exchange rate set by the owner. </p>
			<ul className="guideList">
				<li className="guideListItem">State the number of 2nd currency tokens you get for every 1 token of the 1st currency chosen.</li>
				<li className="guideListItem">Decide if the smart contract owner should have the ability to change the swap rate in the future. </li>
				<li className="guideListItem">Set the minimum transfer amount.</li>
				<li className="guideListItem">Decide if the smart contract owner should have the ability to change the minimum transfer amount in the future.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={exchange3} className="galleryImg" alt = "Cues Exchange Coins 2nd to 1st Conversion" />
			<p><b>5. 2nd to 1st Conversion</b>:</p>
			<p>Choose <i>"Yes"</i> if you would like be able to swap the 2nd currency for the 1st currency (reverse exchange). If so, the process is the same as the 1st to 2nd Conversion.</p>

			<p><b>6. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>7. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}>< Icon width="30"  icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
		
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class Staking extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Creating Your Own Staking Contract</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Stake Coins/NFTs".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={staking1} className="galleryImg" alt = "Cues Staking Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on.</li>
				<li className="guideListItem">Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">Choose the type of crypto that you want to stake. Enter coin or NFT address if needed.</li>
				<li className="guideListItem">Choose the type of crypto that staker will be rewarded with.</li>
				<li className="guideListItem">Decide which reward mechanism you prefer. For drip over time, stakers will periodically receive a reward based on an interest rate. For distribute an injection proportionately, stakers will share tokens transferred by the owner depending on their stake proportion.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={staking2} className="galleryImg" alt = "Cues Staking Basic Details" />
			<p><u>Distribute an Injection Proportionately</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Decide the number of days the period of consideration will be. If the period of consideration is 3 days, someone who has staked for 3 days will receive 3 times as much as someone who has staked for 1 day; while someone who has staked for 4 days will receive as much as someone who has staked for 3 days, as only the last 3 days are counted.</li>
				<li className="guideListItem">Choose if you want to set a cool down period where the distributed token rewards can only be withdrawn after a certain number of days</li>
			</ul>
		
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={staking3} className="galleryLargerImg" alt = "Cues Staking Schemes" />
			<p><b>4. Schemes</b>:</p>
			<p>This section states the conditions of the staking contract. Add more schemes if you want to have different staking plans that depend on interest rate/durations/staking amount. For example, you might want to provide a higher interest rate for schemes where the minimum staking period is higher or where the minimum staking amount is higher. 
			<br/>
			The questions shown here will depend on the choice of interest mechanism chosen in the previous page. All the choices for the questions can be amended by the contract owner in the future if you toggle that option for each question, although doing so may affect the credibility of the staking contract compared to a contract where these values are fixed. It is possible to set an option as 0 and allow amendments in the future. 
			<br/>Click the <span className = "bolditalicguide">+ Scheme</span> button if you want to add more staking schemes.</p>
			<ul className="guideList">
				<li className="guideListItem">Choose type of interest rate. Simple interest is only based on the principal amount that stakers put in, while compound interest is based on the principal amount and the accumulated interest. (Drip over time). If you choose to stake NFTs, you can also have differential interest rates depending on token ID. Simply click the <span className = "bolditalicguide">+ Modification</span> button to add modifications to the baseline interest rate.
				</li>
				<li className="guideListItem">Enter the daily interest rate. (Drip over time)</li>
				<li className="guideListItem">Set minimum stake period if needed.</li>
				<li className="guideListItem">Enter tax percentage on withdrawal of interest if needed.</li>
				<li className="guideListItem">Enter tax percentage on withdrawal of principal if needed.</li>
				<li className="guideListItem">Set minimum stake amount if needed.</li>
				<li className="guideListItem">Set maximum stake amount if needed. Ensure that this number is bigger than the minimum stake amount. </li>
			</ul>

		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={staking4} className="galleryImg" alt = "Cues Staking Profit Allocation" />
			<p><b>5. Profit Allocation</b>:</p>
			<p>This section allows you to decide how you would want to distribute profits. If you want the contract owner to collect all the profits, select "No" and carry on.</p>
			<p><u>If distributing to addresses</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Add wallet address of the receiver.</li>
				<li className="guideListItem">Add how much the receiver will get in proportion to the total profit amount.</li>
				<li className="guideListItem">If you would like to add more shareholders, click the <i>"+ Assignment" button</i>.</li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={staking5} className="galleryImg" alt = "Cues Staking Profit Allocation" />
			<p><b>6. Referrals</b>:</p>
			<p>This section allows you to decide if you would like to reward stakers who referred other users to stake. If you do not want to, select "No" and carry on.</p>
			<p><u>If rewarding referral fees</u>:</p>
			<ul className="guideList">
				<li className="guideListItem">Add how much the receiver will get in interest bearing coin for each currency staked.</li>
				<li className="guideListItem">If you would like to add more referral levels, click the <i>"+ Level" button</i>. Use the arrow signs to order your referral levels from the earliest to the latest.</li>
			</ul>
	
			<p><b>7. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>8. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}>< Icon width="30"  icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class RewardNFT extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Reward Your NFT Holders</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Stake Coins/NFTs".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={reward1} className="galleryLargerImg" alt = "Cues Reward NFT Holders Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on. This must be the same as your NFT contract.</li>
				<li className="guideListItem"> Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">Enter the address of the NFT. Click the <i>Verify</i> button and our system will check that it is a published smart contract on the blockchain that you chose.</li>
				<li className="guideListItem">Choose the type of crypto that NFT holders will be rewarded with. Add the coin address if required. </li>
				<li className="guideListItem">Enter the smallest and largest collection ID of the NFT collection whose owners you would like to reward.</li>
			</ul>
			
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={reward2} className="galleryImg" alt = "Cues Reward NFT Holders Exclusions" />
			<p><b>4. Exclusions (Optional)</b>:</p>
			<p>You may want to reward only a fraction of the NFT holders. For example, you may wish to exclude token ids between 50 and 100.</p>
			<ul className="guideList">
				<li className="guideListItem">Choose the condition most suitable and input the value.</li>
				<li className="guideListItem">If you would like to add more conditions, click the <i>"+ Condition" button</i>.</li>
			</ul>
			
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={reward3} className="galleryImg" alt = "Cues Reward NFT Holders Weights" />
			<p><b>5. Additional Weights (Optional)</b>:</p>
			<p>Given a baseline of 1, set varying reward amounts to different groups of NFT holders by using weights.</p>
			<ul className="guideList">
				<li className="guideListItem">Enter the additional weight of your reward.</li>
				<li className="guideListItem">Add conditions if it does not apply to every NFT holder. The process to add is the same as the previous tab.</li>
			</ul>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={reward4} className="galleryImg" alt = "Cues Reward NFT Holders Additional Details" />
			<p><b>6. Additional Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Decide if you want to allow contract owner to drain unclaimed tokens. Choosing <i>"Yes"</i> prevents wastage. </li>
				<li className="guideListItem">Decide if the NFT owner needs to call the Unlock function in order to be allocated their reward. This would depend on the way you distribute your reward to your holders.</li>
			</ul>
	
			<p><b>7. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>8. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}>< Icon width="30"  icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class Escrow extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Creating Your Own Escrow Contract</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Escrow".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={escrow1} className="galleryLargerImg" alt = "Cues Escrow Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on.</li>
				<li className="guideListItem">Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">State who would be the buyer in this contract. Add buyer's wallet address if it is not the person deploying the contract.</li>
				<li className="guideListItem">State who would be the seller in this contract. Add sellers's wallet address if it is not the person deploying the contract.</li>
				<li className="guideListItem">Choose the type of crypto that staker will be rewarded with at the end of the staking period.</li>
				<li className="guideListItem">Decide if escrow amount is fixed or variable. If fixed, specify the amount of crypto coins.</li>
			</ul>
		
			<p><b>4. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>5. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class Voting extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Creating Your Own Voting Contract</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Voting".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={voting1} className="galleryImg" alt = "Cues Voting Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on. This must be the same as your NFT contract.</li>
				<li className="guideListItem">Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract.</li>
				<li className="guideListItem">Enter the address of the NFT. Click the <i>Verify</i> button and our system will check that it is a published smart contract on the blockchain that you chose.</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={voting2} className="galleryImg" alt = "Cues Voting Proposal" />
			<p><b>4. Proposal</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Enter the proposal name that you want your NFT holders to vote on.</li>
				<li className="guideListItem">Add options by clicking the <i>"+ Option" </i>button and entering the option names. You have to add at least two options so that your NFT holders have a choice. For example, the options could be "Yes" and "No".</li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={voting3} className="galleryImg" alt = "Cues Voting Power" />
			<p><b>5. Voting Power</b>:</p>
			<p>A wallet address might hold multiple NFTs. Decide if those with multiple NFTs should have more voting power or have the same voting power as the rest of your NFT holders. One NFT one vote may be more democratic, but in this case, whales (those with multiple NFTs) may have <i>too much</i> voting power, and hence can rig votes.</p>
		
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={voting4} className="galleryImg" alt = "Cues Voting Limits" />
			<p><b>6. Limits</b>:</p>
			<p>Decide if you would want to set a time limit (in days) before voting closes. It will start from the initiation of the contract.</p>
				
		
			<p><b>7. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>8. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>
		
		  	
  );
}}

class Ownership extends Component {
	constructor() {
    super();
    this.state = {
		show: false,
		img: false
    };
  }
	showModal = (element) => {
		this.setState({ img: element.target.src });
		this.setState({ show: true });
	  };

	hideModal = () => {
		this.setState({ show: false });
	  };
	
	render(){
		
		
    return (
		  <div className="guideContainer">		   
			<h2 className = "guideTitle">Split Ownership of an NFT</h2>
			<p><b>1. Go to <a className = "guideLink" href="https://www.cues.sg/contracts">https://www.cues.sg/contracts</a> and select <i>"Fractionalize NFT".</i></b></p>
			<p><b>2. Default values and tooltips (question mark symbols) are there to guide you. </b></p>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={ownership1} className="galleryImg" alt = "Cues Split NFT Ownership Basic Details" />
			<p><b>3. Basic Details</b>:</p>
			<ul className="guideList">
				<li className="guideListItem">Select which blockchain your smart contract will be on. This must be the same as your NFT contract.</li>
				<li className="guideListItem">Enter contract owner's address if you want the ownership of the contract to be linked to another wallet address and not yours. This affects who has admin rights over the contract. The owner must be the owner of the NFT.</li>
				<li className="guideListItem">Input token symbol. This is usually an abbreviation. Check that your symbol is unique by clicking on the link. This is because the contract will create the token for you when you transfer the NFT to the contract.</li>
				<li className="guideListItem">Enter the address of the NFT collection your NFT is in. The token id of your NFT will be called in the mint function so that you can specify which token you are fractionalizing. Click the <i>Verify</i> button and our system will check that it is a published smart contract on the blockchain that you chose.</li>
				<li className="guideListItem">Input the number of coins per NFT. When a NFT is fractionalized, the original NFT is locked up in a vault, and a supply of fungible tokens (whose symbol you just came up with) that represent ownership over that NFT is issued. </li>
			</ul>
			<img loading="lazy" onClick={(e) => this.showModal(e)}  src={ownership2} className="galleryImg" alt = "Cues Split NFT Ownership Math" />
			<p><b>4. Math</b>:</p>
			<p>Decide the minimum percentage needed to reclaim the NFT. </p>
		
			<p><b>5. Review your answers in the <i>Summary</i> tab. After checking that you have filled in all the required fields in the form, click the <i>Launch</i> button. </b></p>
			<p><b>6. Congrats! You now have a smart contract without coding! 
			<br/> 
			Click on <i>How to Deploy Contract</i> if you have any questions about the deployment of your contract.</b></p>
			<div className="text-center">
				<div className="btn-solid-lgopposite text-center btn-removemargin" onClick={clickSmartContract}><Icon width="30" icon="ant-design:rocket-outlined" /> Start Now </div>
			</div>
			<div>
			  <PhotoZoom show={this.state.show} handleClose={this.hideModal} children = {this.state.img}>
			  </PhotoZoom>
   			</div>
		  </div>  	
  );
}}


export {Basics, DeployContract, MintToken, MintNFT, ExchangeCoin, Staking, RewardNFT, Escrow, Voting, Ownership};
